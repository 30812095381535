import { createSlice } from "@reduxjs/toolkit";

import {conversationService, messageService, reservationService} from "../../services";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    conversations: [],
    activeConvoId: null,
    messagesByConversation: {},
  },
  reducers: {
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },
    updateConversation: (state, action) => {
      const newConvo = action.payload;
      const convos = state.conversations
        .map((c) => (c._id === newConvo._id ? newConvo : c))
        .sort((c1, c2) => getDate(c2) - getDate(c1));
      state.conversations = convos;
    },
    setActiveConvoId: (state, action) => {
      state.activeConvoId = action.payload;
    },
    addMessagesToConversation: (state, action) => {
      const { messages = [], convoId, pushToEnd = false } = action.payload;
      const cur = state.messagesByConversation[convoId] || [];
      state.messagesByConversation = {
        ...state.messagesByConversation,
        [convoId]: pushToEnd ? [...cur, ...messages] : messages,
      };
    },
    addNewConversation: (state, action) => {
      state.conversations = [...state.conversations, action.payload];
    },
  },
});

export const {
  addMessagesToConversation,
  addNewConversation,
  setActiveConvoId,
  setConversations,
  updateConversation,
} = chatSlice.actions;

export const fetchConversations = (conversationIds) => async (dispatch) => {
  if (!conversationIds) return;
  try {
    const res = await conversationService.find({
      query: {
        _id: {
          $in: conversationIds,
        },
        $sort: {
          // sorting by createdAt is buggy for some reason
          updatedAt: -1,
        },
      },
    });

    const sorted = res.data.sort((c1, c2) => getDate(c2) - getDate(c1));
    dispatch(setConversations(sorted));
  } catch (err) {
    console.log(err);
  }
};

export const fetchMessages = (conversationId) => async (dispatch) => {
  if (!conversationId) return;
  const messages = await messageService.find({
    query: {
      conversationId,
      $sort: {
        // sorting by createdAt is buggy for some reason
        _id: 1,
      },
      $limit: 250,
    },
  });
  dispatch(
    addMessagesToConversation({
      messages: messages.data,
      convoId: conversationId,
    })
  );
};

export const sendMessage =
  (message, conversation, product) => async (dispatch) => {
    if (conversation._id === "new") {
      return dispatch(
        createConversationWithInitialMsg(conversation, message, product)
      );
    }

    message.conversationId = conversation._id; // for brand new convos, 'new' otherwise
    dispatch(
      addMessagesToConversation({
        messages: [message],
        convoId: message.conversationId,
        pushToEnd: true,
      })
    );
    const c = {
      ...conversation,
      updatedAt: new Date().getTime(),
      lastMessageId: {
        ...conversation.lastMessageId,
        body: message.body,
        sentBy: message.sentBy,
        updatedAt: new Date().getTime(),
        createdAt: new Date().getTime(),
      },
    };
    dispatch(updateLastSeenAt(c, message.sentBy));
    const msgCreated = await messageService.create(message);
    return msgCreated;
  };

export const updateLastSeenAt = (conversation, uid, updateDB) => async (dispatch) => {
  const newC = { ...conversation };
  newC.participantData = conversation.participantData.map((p) =>
    p._id._id !== uid ? p : { ...p, lastSeenAt: new Date().getTime() }
  );
  dispatch(updateConversation(newC));
  if(updateDB)
    conversationService.patch(conversation._id, newC)
};

export const createConversationWithInitialMsg =
  (conversation, message, product) => async (dispatch) => {
    const convo = await conversationService.create({
      productId: conversation.productId,
      accountId: product.accountId,
    });
    dispatch(addNewConversation(convo));
    return dispatch(sendMessage(message, convo, product));
  };

export const selectConversations = (state) => state.chat.conversations;
export const selectActiveConvo = (state) =>
  state.chat.conversations.find(({ _id }) => _id === state.chat.activeConvoId);
export const selectMessages = (state) =>
  state.chat.messagesByConversation[state.chat.activeConvoId] || [];

export default chatSlice.reducer;

const getDate = (c) =>
  c.lastMessageId ? new Date(c.lastMessageId.createdAt) : new Date(c.createdAt);
