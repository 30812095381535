import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD3kO61Ka8g7e4HlAWTapKOVbmYrPqLmO4",
  authDomain: "slabxchange-326112.firebaseapp.com",
  projectId: "slabxchange-326112",
  storageBucket: "slabxchange-326112.appspot.com",
  messagingSenderId: "950435562537",
  appId: "1:950435562537:web:ef1e82cfcb21e44a6fc838",
  measurementId: "G-NMJGJDB1XB"
};

getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const analytics = getAnalytics();

export const customEvent = (name, data = {}) => {
  console.log(name)
  logEvent(analytics, name, data);
}

export const setUserProps = (id, properties) => {
  setUserId(analytics, id);
  setUserProperties(analytics, properties)
}
