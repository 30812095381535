import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Icon } from "..";
import { selectUser } from "../../redux/reducers/userReducer";
import { presignedUrlService } from "../../services";
import { uploadImageToS3 } from "../../utils/AwsUtils";
import UnitSelector from "./UnitSelector";
import Switch from 'react-switch'

const Input = ({ label, left, right, className, error, ...props }) =>
  props.type === "image" ? (
    <ImageUpload {...props} />
  ) : props.type === "units" ? (
    <UnitSelector
      units={props.value}
      setUnits={(u) => props.onChange({ target: { value: u } })}
    />
  ) : props.type === "switch" ? (
    <div className="pt-4 flex justify-between">
      {label && (
        <p className="mr-4 text-lg font-semibold">{label}:</p>
      )}
      <Switch onChange={props.onChange} checked={props.value} />
    </div>
  ) : (
    <div className="my-2">
      {label && (
        <label
          htmlFor={label}
          className="block text-sm font-medium text-gray-700"
        >
          {unCamelCase(label)}
        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        <div
          className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none`}
        >
          {left && <span className="text-gray-500 sm:text-sm">{left}</span>}
        </div>
        {props.type === "text" ? (
          <textarea
            {...props}
            type="text"
            value={props.value || ""}
            name="name"
            className={`focus:ring-orange-300 focus:border-orange-300 block w-full pr-12 
            sm:text-sm border-gray-300 rounded-md ${left ? "pl-7" : ""}`}
            rows={6}
          />
        ) : (
          <input
            type="text"
            {...props}
            value={(props.value === 0 || props.value) ? props.value : ""}
            name="name"
            className={`focus:ring-orange-300 focus:border-orange-300 block w-full ${
              left ? "pl-7" : ""
            } ${right ? " pr-14" : ""}
            ${error ? `border-red-500` : ""}
          sm:text-sm border-gray-300 rounded-md ${className}`}
          />
        )}
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {right && <span className="text-gray-500 sm:text-sm">{right}</span>}
        </div>
      </div>
      {error && <p className="h-2 text-orange text-sm mt-1 ml-1">{error}</p>}
    </div>
  );

export default Input;

const unCamelCase = (str) => {
  const res = str.replace(/([A-Z])/g, " $1");
  return res.charAt(0).toUpperCase() + res.slice(1);
};

const ImageUpload = ({ onChange, value = "" }) => {
  const [image, setImage] = useState({ src: value });
  useEffect(() => setImage({ src: value }), [value]);
  const user = useSelector(selectUser);

  return (
    <>
      <label
        htmlFor="file-upload"
        className={`w-48 h-48 rounded-lg border-gray-400 mt-4 cursor-pointer
      ${
        image.src
          ? ""
          : "border-2 text-gray-400 flex flex-col justify-center items-center"
      }`}
        onClick={() => {}}
      >
        {image.src ? (
          <img src={image.src} alt="avatar" className="w-48 h-48 rounded-lg" />
        ) : (
          <>
            <Icon
              name="photograph"
              color="#9ca3af"
              size="xl"
              className="mb-2"
            />
            Add photo
          </>
        )}
      </label>
      <input
        onChange={async (e) => {
          const file = e.target.files[0];
          if (!file) return;
          const displayableImg = URL.createObjectURL(file);
          setImage({
            src: displayableImg,
            filename: file.name,
            file,
            shouldUpload: true,
          });
          const presignedUrls = await presignedUrlService.create({
            accountId: user.accountId,
            filenames: [file.name],
          });
          const awsRes = await Promise.all(
            presignedUrls.map((url, i) => uploadImageToS3(file, url))
          );
          onChange({ target: { value: awsRes[0] } });
        }}
        id="file-upload"
        className="invisible"
        type="file"
      />
    </>
  );
};
