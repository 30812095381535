import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {Formik} from 'formik'
import * as yup from 'yup'

import { Button, Icon, Input, SearchInput, Select } from "../../components";
import UnitSelector from "../../components/Input/UnitSelector";
import SlabTypeSelector from "../../components/Input/SlabTypeSelector";
import { selectUser } from "../../redux/reducers/userReducer";
import {accountService, brandService, presignedUrlService} from "../../services";
import { partitionArray, arrayMove} from "../../utils/ArrayUtils";
import { capitalize } from "../../utils/StringUtils";
import { uploadImageToS3 } from "../../utils/AwsUtils";
import { cmToInches, inchesToCm } from "../../utils/UnitUtils";
import imageRequest from "../../utils/ImageUtils";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {selectMaxSlabId, selectAllSlabIds} from "../../redux/reducers/productsReducer";

const EMPTY_LISTING = {
  slabType: "Remnant",
  slabId: null,
  locationId: 0,
  images: [],
  price: 0,
  tone: "Neutral",
  finish: "Natural",
  type: "Granite",
  manufacturer: null,
  customManufacturer: null,
  brand: null,
  modelId: null,
  units: 'inches',
  width: 0,
  height: 0,
  thickness: 2,
  description: "",
  isPublished: true,
};

const listingSchema = yup.object().shape({
  slabType: yup.string().label('Slab Type'),
  locationId: yup.number().label('Location Id').required(),
  slabId: yup.number().label('Slab Id').required(),
  images: yup.array().label('Images').required().min(1, "At least one image is required"),
  price: yup.number().label('Price').required().min(1, "Price must be greater than 0"),
  tone: yup.string().label('Tone'),
  finish: yup.string().label('Finish'),
  type: yup.string().label('Type'),
  manufacturer: yup.string().label('Manufacturer').when('type', {
    is: (val) => ['quartz'].indexOf(val.toLowerCase()) > -1,
    then: yup.string().required().nullable()
  }).nullable(),
  customManufacturer: yup.string().label('Custom Manufacturer').when('manufacturer', {
    is: (val) => val === "Other",
    then: yup.string().required().nullable()
  }).nullable(),
  brand: yup.string().label('Brand').when('manufacturer', {
    is: (val) => val !== null,
    then: yup.string().required('Model is a required field').nullable()
  }).nullable(),
  modelId: yup.string().label('Model ID').nullable(),
  units: yup.string().label('units'),
  width: yup.number().label('Width').required().min(1, "Width must be greater than 0"),
  height: yup.number().label('Height').required().min(1, "Height must be greater than 0"),
  thickness: yup.number().label('Thickness'),
  description: yup.string().label('Description'),
  isPublished: yup.boolean().label('Publish')
})

let imageClasses = "2xl:w-48 2xl:h-48 xl:w-36 xl:h-36 lg:h-24 lg:w-24 h-28 w-28"

const SortableItem = SortableElement(({img, removeItem, featured}) => (
  <div className={imageClasses+" relative"}>
    <img
      src={(img.url).substring(0, 4) != 'blob' ? imageRequest(img.url, 800) : img.url}
      alt="slab"
      className={imageClasses+" rounded-lg object-cover"}
    />
    { featured && (
      <div className="absolute top-0 left-0 mt-2 ml-2">
        <div className="tooltip">
          <Icon
            name="star"
            className="m-1"
            color="yellow"
          />
          <span className="tooltiptext right">Featured Image</span>
        </div>
      </div>
    )}
    <div className="absolute top-0 right-0 mt-2 mr-2 rounded-md bg-gray-700 hover:bg-gray-900 hover:bg-opacity-20 bg-opacity-20 transition-colors duration-100">
      <Icon
        name="x"
        className="m-1"
        onClick={removeItem}
        color="white"
      />
    </div>
  </div>
));

const SortableList = SortableContainer(({images = [], removeItem, onChange, touched, errors}) => {
  let cachedImages = [...images]
  if(cachedImages.length < 6)
    cachedImages.push("addButton")

  return (
    <div className="grid grid-flow-row lg:grid-cols-3 grid-cols-2 w-full gap-4">
      {cachedImages.map((img, index) => (
        <>
          {img === "addButton" ? (
            <>
              <label
                htmlFor="file-upload"
                className={imageClasses+` rounded-lg border-gray-400 border-2 text-gray-400
                   mt- flex flex-col justify-center items-center cursor-pointer hover:bg-gray-200 transition-colors duration-100 ${touched.images && errors.images ? `border-red-500` : ""}`}
              >
                <Icon name="photograph" color="#9ca3af" size="xl" className="mb-2" />
                Add photo(s)
              </label>
              <input
                id="file-upload"
                className="invisible w-0 h-0"
                type="file"
                multiple
                onChange={async (e) => onChange(e)}
              />
            </>
          ) : (
            <SortableItem disabled={img === "addButton"} featured={index === 0} key={`item-${img.url}`} index={index} img={img} removeItem={() => removeItem(index)}/>
          )}
        </>
      ))}
    </div>
  );
});

const SortableComponent = ({images, setFieldValue, onChange, touched, errors}) => {

  const removeItem = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setFieldValue('images', newImages)
  }

  const onDragEnd = ({oldIndex, newIndex}) => {
    let cachedImages = [...images]
    let newImages = arrayMove(cachedImages, oldIndex, newIndex);
    setFieldValue('images', newImages);
  }

  return <SortableList distance={1} axis="xy" images={images} onSortEnd={onDragEnd} removeItem={removeItem} onChange={onChange} touched={touched} errors={errors} />;
}

const EditListing = ({ listing: l, onSave }) => {
  const maxSlabId = useSelector(selectMaxSlabId) || 0;
  const allSlabIds = useSelector(selectAllSlabIds) || 0;
  const user = useSelector(selectUser) || {};
  const isNewListing = !l;
  const [previewImages, setPreviewImages] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [account, setAccount] = useState({});
  const [error] = useState({});
  const existingMan =
    l && l.manufacturer
      ? MANUFACTURERS[l.type.toLowerCase()].includes(l.manufacturer)
        ? l.manufacturer
        : "Other"
      : null;
  const [listing, setListing] = useState(
    isNewListing
      ? { ...EMPTY_LISTING, slabId: maxSlabId > 0 ? maxSlabId + 1 : 1, units: user.preferredUnits || "inches" }
      : {
          ...l,
          locationId: l.locationId || 0,
          slabType: l.slabType || "Remnant",
          manufacturer: existingMan,
          customManufacturer: existingMan === "Other" ? l.manufacturer : null,
          units: user.preferredUnits || "inches",
          width: user.preferredUnits === "inches" ? cmToInches(l.width) : l.width,
          height: user.preferredUnits === "inches" ? cmToInches(l.height) : l.height,
        }
  );

  useEffect(() => {
    const fetchAccount = async (accountId) => {
      const account = await accountService.get(accountId);
      setAccount(account);
    };
    user.accountId && fetchAccount(user.accountId);
  }, [user]);

  const validateForm = (values) => {
    //Check if slabId is taken


    return true;
  };

  const submitForm = async (values) => {
    if (validateForm(values)) {
      setSubmitting(true);
      let listingToSave = { ...values };
      ["price", "width", "height", "thickness"].forEach((x) => {
        listingToSave[x] = values[x] || 0;
      });
      listingToSave.description =
        values.description || "";
      // console.log("images b4", listingToSave.images);
      const [images, uploadImages] = partitionArray(
        listingToSave.images,
        (img) => !img.shouldUpload
      );
      // console.log("images after split:", listingToSave.images);
      if (uploadImages && uploadImages.length) {
        // console.log("abt to upload");
        const presignedUrls = await presignedUrlService.create({
          accountId: user.accountId,
          filenames: uploadImages.map((img) => img.filename),
        });
        const awsRes = await Promise.all(
          presignedUrls.map((url, i) =>
            uploadImageToS3(uploadImages[i].file, url)
          )
        );
        listingToSave.images = [
          ...images,
          ...awsRes.map((url) => ({ url: new URL(url).pathname.slice(1) })),
        ];
      }
      listingToSave.tone = capitalize(listingToSave.tone);
      listingToSave.finish = capitalize(listingToSave.finish);
      listingToSave.type = capitalize(listingToSave.type);
      ["width", "height"].forEach((i) => {
        listingToSave[i] =
          listingToSave.units === "inches"
            ? inchesToCm(listingToSave[i])
            : listingToSave[i];
      });
      listingToSave.manufacturer =
        listingToSave.customManufacturer || listingToSave.manufacturer;
      if(account.latitude && account.longitude)
        listingToSave.location = {
          type: "Point",
          coordinates: [account.longitude, account.latitude]
        }
      delete listingToSave.units;
      delete listingToSave.model;
      delete listingToSave.customManufacturer;
      let propNames = Object.getOwnPropertyNames(listingToSave)
      for(let i = 0; i < propNames.length; i++) {
        let propName = propNames[i]
        if(listingToSave[propName] === null){
          delete listingToSave[propName]
        }
      }
      onSave(listingToSave);
    }
  }

  return (
    <div className="EditListing px-8 pt-3 pb-12">
      <Formik
        initialValues={listing}
        validationSchema={listingSchema}
        onSubmit={values => submitForm(values)}
      >
        {props => (
          <>
            <div className={"flex flex-row"}>
              <SortableComponent images={props?.values?.images} setFieldValue={props.setFieldValue} touched={props.touched} errors={props.errors} onChange={async (e) => {
                const files = e.target.files;
                const displayableImg = URL.createObjectURL(files[0]);
                setPreviewImages([...previewImages, displayableImg]);
                // FileList does not work with array functions
                const images = [];
                for (let i = 0; i < files.length; i++) {
                  images.push({
                    filename: files[i].name,
                    file: files[i],
                    url: URL.createObjectURL(files[i]),
                    shouldUpload: true,
                  });
                }
                props.setFieldValue('images', [...props.values.images, ...images])
              }}/>
            </div>
            <div className="text-gray-400 font-sm mt-2 mb-6">
              Photos - {props.values.images.length}/6
              {props.touched.images && props.errors.images && (
                <p className="h-2 text-orange text-sm mt-1 ml-1">{props.errors.images}</p>
              )}
            </div>
            <div className="py-2">
              <SlabTypeSelector
                slabType={props.values.slabType}
                setSlabType={(u) => {
                  props.setFieldValue('slabType', u)
                }}
              />
              <div className="mt-4">
                <div className="flex flex-row items-center px-6">
                  <div className="text-5xl">
                    {props.values.slabType === "Full" ? "F" : "R"}
                  </div>
                  <div className="text-5xl mx-4">
                    -
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Location Id"
                      name="locationId"
                      type="number"
                      onChange={(e) => {
                        let newLocationId = e.target.value;
                        if(newLocationId.length > 5)
                          newLocationId = newLocationId.slice(0, 5)
                        props.setFieldValue('locationId', newLocationId)
                      }}
                      value={props.values.locationId}
                      error={true && props.errors.locationId} //props.touched.locationId does not exist for some weird reason
                    />
                  </div>
                  <div className="text-5xl mx-4">
                    -
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Slab Id"
                      name="slabId"
                      type="number"
                      onChange={(e) => {
                        let newSlabId = e.target.value;
                        if(newSlabId.length > 6)
                          newSlabId = newSlabId.slice(0, 6)
                        if(allSlabIds.indexOf(Number(newSlabId)) > -1) {
                          props.setFieldTouched("slabId", true, false)
                          props.setFieldError("slabId", "This slab id is taken.");
                          props.setFieldValue("slabId", newSlabId, false);
                        } else
                          props.setFieldValue("slabId", newSlabId);
                      }}
                      value={props.values.slabId}
                      error={props.touched.slabId && props.errors.slabId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Select
              label="Type"
              name="type"
              value={props.values.type.toLowerCase()}
              options={buildSelectOptions([
                "granite",
                "marble",
                "onyx",
                "porcelain",
                "quartz",
                "quartzite",
                "soapstone",
                "travertine",
              ])}
              onChange={(t) => {
                props.setFieldValue('type', t)
                props.setFieldValue('manufacturer', null)
                props.setFieldValue('brand', null)
              }}
            />
            {["quartz","quartzite","marble","granite"].includes(props.values.type.toLowerCase()) && (
              <div className="pt-2">
                <Select
                  label="Manufacturer"
                  name="manufacturer"
                  value={
                    props.values.manufacturer
                      ? MANUFACTURERS[props.values.type.toLowerCase()].includes(props.values.manufacturer)
                        ? props.values.manufacturer.toLowerCase()
                        : "Other"
                      : ""
                  }
                  options={buildSelectOptions([...MANUFACTURERS[props.values.type.toLowerCase()], "Other"])}
                  onChange={props.handleChange('manufacturer')}
                  error={props.touched.manufacturer && props.errors.manufacturer}
                />
                {props.values.manufacturer && props.values.manufacturer !== "Other" && (
                  <ModelSearch
                    value={props.values.model}
                    name="brand"
                    listing={props.values}
                    onChange={(values) => {
                      props.setFieldValue('brand', values.brand)
                      props.setFieldValue('modelId', values.modelId)
                    }}
                    types={[capitalize(props.values.type || "")]}
                    manufacturer={props.values.manufacturer}
                    error={props.touched.brand && props.errors.brand}
                  />
                )}
                {props.values.manufacturer === "Other" && (
                  <div className="pl-8">
                    <Input
                      label="Custom Manufacturer"
                      name="customManufacturer"
                      onChange={props.handleChange('customManufacturer')}
                      value={props.values.customManufacturer}
                      error={props.touched.customManufacturer && props.errors.customManufacturer}
                    />
                    <Input
                      label="Model Name"
                      name="brand"
                      onChange={props.handleChange('brand')}
                      value={props.values.brand}
                      error={props.touched.brand && props.errors.brand}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="my-2" />
            <Select
              label="Tone"
              name="tone"
              value={(props.values.tone || "").toLowerCase()}
              options={buildSelectOptions(["neutral", "white", "dark", "black"])}
              onChange={props.handleChange('tone')}
            />
            <div className="my-2" />
            <Select
              label="Finish"
              name="finish"
              value={(props.values.finish || "").toLowerCase()}
              options={buildSelectOptions(["natural", "polished", "honed", "rough", "leather", "brushed", "matte", "matte-textured"])}
              onChange={props.handleChange('finish')}
            />
            <Input
              label="Price"
              name="price"
              type="number"
              left="$"
              onWheel={(e) => e.target.blur()}
              placeholder="0"
              onChange={props.handleChange('price')}
              value={props.values.price || ""}
              autocomplete="off"
              error={props.touched.price && props.errors.price}
            />
            <div className="py-2">
              <UnitSelector
                units={props.values.units}
                setUnits={(u) => {
                  props.setFieldValue('units', u)
                  if (u === "inches") {
                    props.setFieldValue('width', cmToInches(props.values.width));
                    props.setFieldValue('height', cmToInches(props.values.height));
                  } else if (u === "centimeters") {
                    props.setFieldValue('width', inchesToCm(props.values.width));
                    props.setFieldValue('height', inchesToCm(props.values.height));
                  }
                }}
              />
            </div>
            <Input
              label="Width"
              name="width"
              type="number"
              onWheel={(e) => e.target.blur()}
              right={props.values.units || user.preferredUnits}
              placeholder="0"
              onChange={props.handleChange('width')}
              value={props.values.width || ""}
              autocomplete="off"
              error={props.touched.width && props.errors.width}
            />
            <Input
              label="Length"
              name="length"
              type="number"
              onWheel={(e) => e.target.blur()}
              right={props.values.units || user.preferredUnits}
              placeholder="0"
              onChange={props.handleChange('height')}
              value={props.values.height || ""}
              autocomplete="off"
              error={props.touched.height && props.errors.height}
            />
            <ThicknessSelector
              value={props.values.thickness}
              onChange={(value) => props.setFieldValue('thickness', value)}
            />
            <div className="pb-2" />
            <Input
              placeholder="Describe your item in a few sentences.."
              label="Item Description"
              name="description"
              type="text"
              onChange={props.handleChange('description')}
              value={props.values.description}
            />
            <label
              className="inline-flex items-center justify-between w-2/5nvm
                  hover:bg-orange-100 rounded-lg p-2 cursor-pointer checkbox-label"
            >
              <span className="ml-2 text-gray-700 mr-3">Publish to Marketplace</span>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-orange"
                checked={props.values.isPublished}
                onChange={() => props.setFieldValue("isPublished", !props.values.isPublished)}
              />
            </label>
            <div></div>
            <Button
              className="w-full mt-4"
              disabled={submitting}
              loading={submitting}
              onClick={props.handleSubmit}
            >
              {isNewListing ? "Create Listing" : "Save Changes"}
            </Button>
            {Object.keys(props.touched).length > 0 && Object.keys(props.errors).length > 0 && (
              <p className="h-2 text-orange text-sm mt-1 ml-1">Please fix the errors in this form</p>
            )}
            {!Object.keys(error).every(function (key) {
              return !error[key];
            }) && (
              <p className="text-red-500 mt-1">Please fill in all required fields.</p>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default EditListing;

const buildSelectOptions = (arr) =>
  arr.map((c) => ({
    value: c,
    icon: null,
  }));

// const buildColorSelectOptions = (colorArray) =>
//   colorArray.map((c) => ({
//     value: c,
//     icon: (
//       <div
//         className={`flex-shrink-0 h-6 w-6 rounded-xl bg-${
//           c === "black" ? c : `${c}-400`
//         }`}
//       />
//     ),
//   }));

const ThicknessSelector = ({ value, onChange }) => (
  <div className={`pt-4 flex justify-between`}>
    <div className="mr-4 text-lg font-semibold">Thickness:</div>
    <div className="">
      <label className="inline-flex items-center mr-4 cursor-pointer">
        <input
          type="radio"
          className="form-radio h-5 w-5 text-orange"
          checked={value === 2}
          onChange={() => onChange(2)}
        />
        <span className="ml-2 text-gray-700">2cm</span>
      </label>
      <label className="inline-flex items-center ">
        <input
          type="radio"
          className="form-radio h-5 w-5 text-orange cursor-pointer"
          checked={value === 3}
          onChange={() => onChange(3)}
        />
        <span className="ml-2 text-gray-700">3cm</span>
      </label>
    </div>
  </div>
);

export const ModelSearch = ({ types, listing, manufacturer, onChange, name, error, mode }) => {
  const [searchRes, setSearchRes] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [value, setValue] = useState(listing.brand);

  useEffect(() => {
    const fetchModels = async () => {
      let query = {
        $limit: 200,
        type: {
          $in: types,
        },
      }
      if(manufacturer)
        query.manufacturer = manufacturer
      const res = await brandService.find({query});
      let init = mode === "search" ? [{value: "Any"}] : []
      const data = init.concat(res.map((p) => {
        p.value = `${p.name?p.name:''}${p.name && p.modelId? ` - `:``}${p.modelId?p.modelId:''}`
        return p;
      }));
      setSearchRes(data);
      setFilteredModels(data);
    };
    fetchModels();
  }, [manufacturer, types]);

  useEffect(() => {
    setValue(null);
  }, [manufacturer]);

  return (
    <SearchInput
      // className="pl-10"
      onSearch={async (q = "") => {
        // TODO: debounce
        q = q.toLowerCase();
        const filtered = searchRes.filter((m) =>
          (m.name || "").toLowerCase().includes(q)
            || (m.modelId||"").toLowerCase().includes(q)
        );
        setFilteredModels(filtered);
      }}
      value={value}
      name={name}
      error={error}
      onChange={(model) => {
        // console.log("select!", model);
        onChange({
          modelId: model.modelId,
          manufacturer: model.manufacturer,
          brand: model.name,
        });
        setValue(model.value.trim());
      }}
      options={filteredModels}
    />
  );
};

export const MANUFACTURERS = {
  quartz: [
    "Caesarstone",
    "Dekton",
    "Silestone",
    "Vicostone",
    "Cambria",
    "LG Hausys",
    "Samsung Radianz",
    "Laminam",
    "TCE",
    "Omnia Quartz",
    "Color Quartz",
  ],
  quartzite: [
    "Sensa"
  ],
  granite: [
    "Sensa"
  ],
  marble: [
    "Sensa"
  ]
};
