import React, { useEffect, useState } from "react";

import {
  Button,
  ConfirmModal,
  Input,
  Screen,
  ScreenHeader,
} from "../../components";
import SlabDetails from "../../components/panels/SlabDetails";
import ListingsTable from "./ListingsTable";
import EditListing from "./EditListing";
import {
  adminCreateProduct,
  adminDeleteProducts,
  adminEditProduct,
  adminMarkProductSold,
  adminMarkProductUnsold,
  adminPublishProduct,
  fetchListings,
  selectListings,
  unReserveProduct,
} from "../../redux/reducers/productsReducer";
import { reservationService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/reducers/userReducer";
import { partitionArray } from "../../utils/ArrayUtils";
import { AlertContext } from "../../context/alert";
import useScreenTabs from "../../hooks/useScreenTabs";
import "./ListingsScreen.css";
import { customEvent} from "../../utils/AnalyticsUtils";

const ListingsScreen = () => {
  const [selectedSlabs, setSelectedSlabs] = useState(new Set());
  const [view, setView] = useState("TABLE"); // TABLE | DETAILS | EDIT | CREATE
  const [focusedSlab, setFocusedSlab] = useState(null);
  const [{ modal, modalProduct }, setModalData] = useState({
    modal: null,
    modalProduct: null,
  });
  const setModal = (modal, modalProduct) =>
    setModalData({ modal, modalProduct });
  const dispatch = useDispatch();
  const { dispatchAlert } = React.useContext(AlertContext);

  const selectSlab = ({ _id }) => {
    const selection = new Set(selectedSlabs);
    selection.has(_id) ? selection.delete(_id) : selection.add(_id);
    setSelectedSlabs(selection);
  };

  const user = useSelector(selectUser) || {};
  useEffect(() => {
    user.accountId && dispatch(fetchListings(user.accountId));
  }, [user.accountId, dispatch]);

  const unfocusSlab = () => {
    setFocusedSlab(null);
    setView("TABLE");
  };

  const [activeTab, tabJsx] = useScreenTabs(["For Sale", "Sold"], () => {
    unfocusSlab();
    setSelectedSlabs(new Set());
  });

  const listings = useSelector(selectListings);
  const [activeListings, soldListings] = partitionArray(
    listings,
    (l) => !l.isSold
  );

  const Header = view === "TABLE" ? TableHeader : DetailsHeader;

  return (
    <Screen
      header={
        <Header
          deleteEnabled={selectedSlabs.size > 0}
          onDelete={() => setModal("DELETE")}
          onCreate={() => setView("CREATE")}
          clearSelection={unfocusSlab}
          tabJsx={tabJsx}
          view={view}
        />
      }
      fullHeight
      className="ListingsScreen"
    >
      <div className="flex flex-row h-16">
        <ListingsTable
          activeTab={activeTab}
          slabs={activeTab === 0 ? activeListings : soldListings}
          // hideActions={activeTab === 1}
          focusedSlab={focusedSlab}
          selectedSlabs={selectedSlabs}
          selectSlab={selectSlab}
          onSlabClick={(slab) => {
            if (focusedSlab && focusedSlab._id === slab._id) {
              // second click on row, unfocus
              unfocusSlab();
            } else {
              setView("DETAILS");
              setFocusedSlab(slab);
            }
          }}
          setModal={setModal}
          isFullWidth={view === "TABLE"}
        />
        {view !== "TABLE" && (
          <div className="RightSide w-full">
            {view === "DETAILS" && (
              <DetailsView
                slab={focusedSlab}
                setView={setView}
                onDelete={() => setModal("DELETE")}
              />
            )}
            {view === "EDIT" && (
              <EditListing
                listing={focusedSlab}
                onSave={(productData) => {
                  dispatch(adminEditProduct(productData));
                  dispatchAlert({
                    type: "open",
                    position: "right",
                    message: `${
                      productData.type +
                      (productData.brand ? " - " + productData.brand : "")
                    } has been edited.`,
                  });
                  setView("TABLE");
                  customEvent("edit_product", productData)
                }}
              />
            )}
            {view === "CREATE" && (
              <EditListing
                onSave={(p) => {
                  p.accountId = user.accountId;
                  p.images = p.images.length
                    ? p.images
                    : [
                        {
                          url: DEFAULT_IMAGE,
                          featured: true,
                        },
                      ];
                  dispatch(adminCreateProduct(p)).then((res) => {
                    if (res) {
                      dispatchAlert({
                        type: "open",
                        position: "right",
                        message: `${
                          p.type + (p.brand ? " - " + p.brand : "")
                        } has been ${p.isPublished ? "published." : "listed."}`,
                      });

                      customEvent("create_product", p)
                    }
                  });
                  setView("TABLE");
                }}
              />
            )}
          </div>
        )}
      </div>
      {modal === "DELETE" && (
        <ConfirmModal
          title="Delete Listing"
          body="Are you sure you want to delete this listing? This action cannot be undone."
          confirmText="Delete"
          onConfirm={() => {
            unfocusSlab();
            !focusedSlab && setSelectedSlabs(new Set());
            setModal(null);
            dispatch(
              adminDeleteProducts(
                focusedSlab ? [focusedSlab._id] : Array.from(selectedSlabs)
              )
            );
            dispatchAlert({
              type: "open",
              position: "right",
              message: focusedSlab
                ? `${
                    focusedSlab.type +
                    (focusedSlab.brand ? " - " + focusedSlab.brand : "")
                  } has been deleted.`
                : "Selected items have been deleted.",
            });
            console.log(focusedSlab, selectedSlabs)
            if(focusedSlab)
              customEvent("delete_product", modalProduct)
            else if (selectedSlabs.length > 1)
              customEvent("bulk_delete_product", modalProduct)
          }}
          onCancel={() => setModal(null)}
        />
      )}
      {modal === "UNRESERVE" && (
        <ConfirmModal
          title="Unreserve Item"
          body="Are you sure you want to unreserve this item? Unreserving the item will remove it from the buyer's list of reservations."
          confirmText="Unreserve"
          onConfirm={() => {
            setModal(null);
            setView("TABLE");
            setFocusedSlab(null);
            dispatch(unReserveProduct(modalProduct));
            dispatchAlert({
              type: "open",
              position: "right",
              message: `${
                modalProduct.type +
                (modalProduct.brand ? " - " + modalProduct.brand : "")
              } has been unreserved.`,
            });
            customEvent("unreserve_product_by_owner", modalProduct)
          }}
          onCancel={() => setModal(null)}
        />
      )}
      {modal === "SOLD" && (
        <ConfirmSoldModal
          defaultPrice={modalProduct.price}
          onConfirm={(soldPrice) => {
            setModal(null);
            setView("TABLE");
            setFocusedSlab(null);
            if (modalProduct.reservationId) {
              reservationService.patch(modalProduct.reservationId._id, {
                status: "COMPLETED",
              });
            }
            dispatch(adminMarkProductSold({ ...modalProduct, soldPrice }));
            dispatchAlert({
              type: "open",
              position: "right",
              confetti: true,
              message: `${
                modalProduct.type +
                (modalProduct.brand ? " - " + modalProduct.brand : "")
              } has been sold. Congratulations!`,
            });
            customEvent("sold_product", {...modalProduct, sold_price: soldPrice})
          }}
          onCancel={() => setModal(null)}
        />
      )}
      {modal === "UNSOLD" && (
        <ConfirmModal
          title="Relist Product"
          body="Are you sure this item isn't sold? This will add the item back to the marketplace."
          confirmText="Relist"
          icon="currency-dollar"
          color="green"
          onConfirm={() => {
            setModal(null);
            setView("TABLE");
            setFocusedSlab(null);
            dispatch(adminMarkProductUnsold(modalProduct));
            dispatchAlert({
              type: "open",
              position: "right",
              message: `${
                modalProduct.type +
                (modalProduct.brand ? " - " + modalProduct.brand : "")
              } has been relisted.`,
            });
          customEvent("relist_product", modalProduct)
          }}
          onCancel={() => setModal(null)}
        />
      )}
      {modal === "PUBLISH" && (
        <ConfirmModal
          title="Publish Product"
          body="Are you sure you want to publish this item? This will add the item to the marketplace."
          confirmText="Publish"
          icon="book"
          color="green"
          onConfirm={() => {
            setModal(null);
            setView("TABLE");
            setFocusedSlab(null);
            dispatch(adminPublishProduct(modalProduct));
            dispatchAlert({
              type: "open",
              position: "right",
              message: `${
                modalProduct.type +
                (modalProduct.brand ? " - " + modalProduct.brand : "")
              } has been published.`,
            });
            customEvent("publish_product", modalProduct)
          }}
          onCancel={() => setModal(null)}
        />
      )}
    </Screen>
  );
};

export default ListingsScreen;

const TableHeader = ({ deleteEnabled = false, onDelete, tabJsx, onCreate }) => (
  <div className="flex w-full h-full justify-between items-center pr-4">
    <div className="flex items-center">
      Listings <span className="ml-8">{tabJsx}</span>
    </div>

    <div className="text-sm flex mr-4">
      <Button
        onClick={onDelete}
        disabled={!deleteEnabled}
        type="secondary"
        icon="trash"
        className="mr-4"
      >
        Delete
      </Button>
      <Button onClick={onCreate} icon="plus">
        New Listing
      </Button>
    </div>
  </div>
);

const DetailsHeader = ({
  clearSelection,
  view,
  tabJsx,
  onDelete,
  deleteEnabled,
  onCreate,
}) => (
  <ScreenHeader
    titles={[
      <div className="flex w-full h-full justify-between items-center pr-4">
        <div className="flex items-center">
          Listings <span className="ml-8">{tabJsx}</span>
        </div>
        <div className={`text-sm flex mr-1 ${view !== "TABLE" && "hidden xl:flex"}`}>
          <Button
            onClick={onDelete}
            disabled={!deleteEnabled}
            type="secondary"
            icon="trash"
            className="mr-4"
          >
            Delete
          </Button>
          <Button onClick={onCreate} icon="plus">
            New Listing
          </Button>
        </div>
      </div>,
      {
        DETAILS: "Item Details",
        EDIT: "Edit Listing",
        CREATE: "Create New Listing",
      }[view],
    ]}
    isSelected
    clearSelection={clearSelection}
    className="pr-2"
  />
);

const DetailsView = ({ slab, setView, onDelete }) => (
  <SlabDetails
    product={slab}
    showPublishDate
    buttonLeft={null}
    buttonRight={null}
  >
    <div className="flex w-full gap-6 px-4 pt-8 pb-12 flex-col xl:flex-row">
      <Button
        type="secondary"
        className="w-full justify-center gap-2"
        onClick={() => onDelete(slab)}
        icon="trash"
      >
        Delete Listing
      </Button>
      {!slab.isSold && (
        <Button
          type="primary"
          className="w-full flex justify-center gap-2"
          onClick={() => setView("EDIT")}
          icon="pencil"
        >
          Edit Listing
        </Button>
      )}
    </div>
  </SlabDetails>
);

const DEFAULT_IMAGE =
  "https://images.unsplash.com/photo-1587749158407-58ef2b89ccf8";

const ConfirmSoldModal = ({ defaultPrice, onConfirm, onCancel }) => {
  const [price, setPrice] = useState(defaultPrice);
  return (
    <ConfirmModal
      title="Mark as Sold"
      body={
        <div>
          Are you sure you want to mark this item as sold? This will remove the
          item from the marketplace.
          <div className="flex flex-row flex-center items-center">
            <div className="text-lg mr-4">Sell Price</div>
            <Input value={price} onChange={(e) => setPrice(e.target.value)} />
          </div>
        </div>
      }
      confirmText="Mark as Sold"
      icon="currency-dollar"
      color="green"
      onConfirm={() => onConfirm(price)}
      onCancel={onCancel}
    />
  );
};
