import React, {useState} from 'react';
import {Formik} from 'formik'
import * as yup from 'yup'
import createYupSchema from "../../createYupSchema";
import {Button, Input} from "../../components";
import {capitalize} from "../../utils/StringUtils";
import {app, fabricatorUserService, userService} from "../../services";
import {AlertContext} from "../../context/alert";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {setUser} from "../../redux/reducers/userReducer";
import SocialButton from "../../components/SocialButton/SocialButton";
import {customEvent, setUserProps} from "../../utils/AnalyticsUtils";

const SignUpFree = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false)
  const { dispatchAlert } = React.useContext(AlertContext);

  const fields = [
    { field: "firstName", displayName: "First Name", width: "half", pos: "first", validationType: "string", validations: [{type: "required", params: ["This field is required."]}] },
    { field: "lastName", displayName: "Last Name", width: "half", pos: "second", validationType: "string", validations: [{type: "required", params: ["This field is required."]}] },
    { field: "email", displayName: "Email", type: "email", validationType: "string", validations: [{type: "required", params: ["This field is required."]}] },
    { field: "password", displayName: "Password", type: "password", validationType: "string", validations: [{type: "required", params: ["This field is required."]}, {type: "min", params: [8, "Password must be 8 or more characters."]}] },
    { field: "confirmPassword", displayName: "Confirm Password", type: "password", validationType: "string", validations: [{type: "required", params: ["This field is required."]}, {type: "oneOf", params: [[yup.ref('password'), null], "Passwords must match."]}] },
  ];

  const yepSchema = fields.reduce(createYupSchema, {});
  const validateSchema = yup.object().shape(yepSchema);

  const renderFormInputs = props => (
    fields.map((f, i) => {
      let error = props.errors.hasOwnProperty(f.field) && props.errors[f.field];
      let touched = props.touched.hasOwnProperty(f.field) && props.touched[f.field];

      return (
        <div className={`mt-1 ${f.width === "half" ? "w-1/2" : "w-full"}`} key={i}>
          <div
            className={`${
              f.pos === "first" ? "mr-2" : f.pos === "second" && "ml-2"
            }`}
          >
            <Input
              key={i}
              value={props.values[f.field]}
              onChange={props.handleChange(f.field)}
              placeHolder={f.displayName || capitalize(f.field)}
              onKeyDown={(e) => e.key === "Enter" && props.handleSubmit()}
              type={f.type || null}
              error={touched && error}
            />
          </div>
        </div>
      )
    })
  )

  const initialValues = {}
  fields.forEach(f => {
    initialValues[f.field] = ""
  })

  const handleSubmit = async (values) => {
    setSubmitting(true)

    try {
      const res = await userService.create({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password
      });

      if(res)
        app
          .authenticate({
            strategy: "local",
            email: values.email,
            password: values.password,
          })
          .then((sessionData) => {
            setUserProps(sessionData.user._id, sessionData.user)
            customEvent("user_signup")
            dispatch(setUser(sessionData.user));
            setSubmitting(false)
            history.push("/dashboard");
          })
          .catch((e) => {
            // Show login page (potentially with `e.message`)
            setSubmitting(false)
            dispatchAlert({
              type: "open",
              position: "right",
              message: "There was an error creating your account.",
            });
          });
    } catch (err) {
      if(err.code === 409){
        setSubmitting(false)
        dispatchAlert({
          type: "open",
          position: "right",
          message: "Email already in use.",
        });
      }
    }
  }

  return (
    <div className="flex flex-col h-3/4 mb-12 justify-center items-center md:flex-row mr-24">
      <div className="p-4 w-2/5">
        <p className="text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
          Enter your Email and Password
        </p>
        <div className="flex flex-row justify-between flex-wrap">
          <Formik
            initialValues={initialValues}
            validationSchema={validateSchema}
            onSubmit={values => handleSubmit(values)}
          >
            {(props) => (
              <>
                {renderFormInputs(props)}
                <Button
                  className="w-full mt-2"
                  disabled={submitting}
                  loading={submitting}
                  onClick={props.handleSubmit}
                >
                  Create Account
                </Button>
              </>
            )}
          </Formik>
        </div>
      </div>
      <div className="px-6 my-2 flex flex-col items-center">
        <div className={`border-l-2 border-gray-200 h-8 my-6`} />
        <div className="text-gray-400 px-2">OR</div>
        <div className={`border-l-2 border-gray-200 h-8 my-6`} />
      </div>
      <div className="flex flex-col">
        <p className="text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
          Sign Up with Social Media
        </p>
        {["Google","Facebook", "Apple"].map(type => <SocialButton type={type} />)}
      </div>
    </div>
  );
};

export default SignUpFree;
