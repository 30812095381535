import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";

const app = feathers();
const restClient = rest("/api");

app.configure(
  auth({
    storageKey: "auth",
  })
);
app.configure(restClient.fetch(window.fetch));

const messageService = app.service("messages");
const productService = app.service("products");
const favoriteService = app.service("favourites");
const userService = app.service("users");
const fabricatorAdminService = app.service("fabricator_admins");
const fabricatorUserService = app.service("fabricator_users");
const accountService = app.service("accounts");
const reservationService = app.service("reservations");
const presignedUrlService = app.service("presigned_urls");
const conversationService = app.service("conversations");
const brandService = app.service("brands");
const authService = app.service("auth-management");
const customerPortalSessionService = app.service("stripe/customer-portal-session");

export {
  app,
  accountService,
  brandService,
  favoriteService,
  messageService,
  productService,
  reservationService,
  userService,
  fabricatorAdminService,
  fabricatorUserService,
  presignedUrlService,
  conversationService,
  authService,
  customerPortalSessionService
};
