import React, { useState } from "react";
import { capitalize } from "../../utils/StringUtils";

const Select = ({ value, label, options = [], onChange, error, mode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false)
  return (
    <div>
      <label
        id="listbox-label"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <button
          type="button"
          className={`relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${error ? `border-red-500` : ""}`}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => setIsOpen(!isOpen)}
          onBlur={() => !isHover && setIsOpen(false)}
        >
          <span className="flex items-center">
            {(options.find(({ value: v }) => v === value) || {}).icon}
            <span className={`block truncate ${value.icon ? "ml-3" : ""} ${(!value && mode !== "search") && "text-gray-400"}`}>
              {value ? capitalize(value) : mode === "search" ? "Any" : "Choose a manufacturer..."}
            </span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>

        <ul
          className={`absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
            isOpen ? "z-10" : "invisible"
          }`}
          tabIndex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {options &&
            options.map(({ value: v, icon }, i) => (
              <li
                onClick={() => {
                  onChange(v);
                  setIsOpen(false);
                }}
                className={`text-gray-900 cursor-default select-none relative 
                      py-2 pl-3 pr-9 cursor-pointer hover:bg-gray-200 ${
                        value === v ? "bg-blue-100" : ""
                      }`}
                id="listbox-option-0"
                key={i}
              >
                <div className="flex items-center">
                  {icon}
                  <span
                    className={`font-normal block truncate ${icon ? "ml-3" : ""}`}
                  >
                    {capitalize(v)}
                  </span>
                </div>
                {value === v && (
                  <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                )}
              </li>
            ))}
        </ul>
      </div>
      {error && (
        <p className="h-2 text-orange text-sm mt-1 ml-1">{error}</p>
      )}
    </div>
  );
};

export default Select;
