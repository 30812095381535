import React from 'react';
import { MdBusiness, MdHome } from "react-icons/md";
import { Link } from "react-router-dom";
import {customEvent} from "../../utils/AnalyticsUtils";

const SignUpType = () => {
  return (
    <div className="flex flex-col items-center">
      <p className="my-8 text-3xl leading-normal font-bold tracking-tight text-gray-900 2xl:text-4xl">Are you a designer or a fabricator?</p>
      <div className="flex flex-col lg:flex-row items-stretch">
        <div className="my-8 w-96 flex flex-col items-center flex-grow">
          <MdHome style={{color: '#f2421b', fontSize: 48}}/>
          <p className="mt-2 text-2xl leading-normal font-bold tracking-tight text-gray-900 2xl:text-3xl">Designer</p>
          <p className="my-4 text-sm leading-normal tracking-tight text-gray-900 md:text-lg">Are you a designer looking to save time and money on your projects? Sign up for a free SlabXChange account and browse hundreds of offcuts and remnant slabs in the marketplace!</p>
          <span class="flex-grow"></span>
          <Link to="/signup/free">
            <button
              onClick={() => customEvent("sign_up_selection", {type: "designer"})}
              className="relative rounded border bg-orange border-orange transition-color duration-100
            hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center mr-4"
            >
              Sign Up for a Free Account
            </button>
          </Link>
        </div>
        <div className="mx-8 my-auto border-l-4 border-orange h-12 hidden lg:block" />
        <div className="my-8 w-96 flex flex-col items-center">
          <MdBusiness style={{color: '#f2421b', fontSize: 48}}/>
          <p className="mt-2 text-2xl leading-normal font-bold tracking-tight text-gray-900 2xl:text-3xl">Fabricator</p>
          <p className="my-4 text-sm leading-normal tracking-tight text-gray-900 md:text-lg">Do you have offcuts and remnant slabs you are looking to sell? Sign up for a premium SlabXChange account to list your slabs on our marketplace or buy new slabs to fill out your inventory.</p>
          <Link to="/signup/companyInformation">
            <button
              onClick={() => customEvent("sign_up_selection", {type: "fabricator"})}
              className="relative rounded border bg-orange border-orange transition-color duration-100
            hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center mr-4"
            >
              Sign Up for a Premium Account
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SignUpType;
