import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from "react-router-dom";
import { useDispatch } from "react-redux";

import ChatScreen from "./screens/ChatScreen/ChatScreen";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import LandingScreen from "./screens/LandingScreen/LandingScreen";
import ListingsScreen from "./screens/ListingsScreen/ListingsScreen";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import ResetPasswordScreen from "./screens/LoginScreen/ResetPasswordScreen";
import SavedScreen from "./screens/SavedScreen/SavedScreen";
import SearchResults from "./screens/SearchScreen/SearchResults";
import SearchScreen from "./screens/SearchScreen/SearchScreen";
import SettingsScreen from "./screens/SettingsScreen/SettingsScreen";
import ServiceAgreementScreen from './screens/ServiceAgreementScreen/ServiceAgreementScreen'
import { Navbar } from "./components";
import SideMenu from "./components/Screen/SideMenu";
import {accountService, app} from "./services";
import { setUser, setAccount } from "./redux/reducers/userReducer";
import { AlertProvider } from "./context/alert";
import {SearchProvider} from "./context/search";
import "./assets/styles/App.css";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {customEvent, setUserProps} from "./utils/AnalyticsUtils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHING_KEY);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <SearchProvider>
        <AlertProvider>
          <Router>
            <Routes />
          </Router>
        </AlertProvider>
      </SearchProvider>
    </Elements>
  );
}

export default App;

const Routes = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const NON_NAV_SCREENS = ["/login", "/", "/signup", "/signup/companyInformation", "/signup/pricingPlan", "/signup/paymentInformation", "/signup/password", "/signup/type", "/signup/free","/reset-password","/service-agreement"];
  const GUEST_SCREENS = ["/dashboard"];
  const STORE_SCREEN = (pathname.search(/store/i) >= 0)
  const [isAuthed, setIsAuthed] = useState(null);
  const dispatch = useDispatch();

  const fetchAccount = async (accountId) => {
    const account = await accountService.get(accountId);
    setAccount(account);
  };

  useEffect(() => {
    const getAuth = async () => {
      app
        .reAuthenticate()
        .then(({ user }) => {
          ["/login", "/", "/oauth-callback"].includes(pathname) && history.push("/dashboard");
          setIsAuthed(true);
          dispatch(setUser(user));
          if(user.accountId)
            fetchAccount(user.accountId)
          setUserProps(user._id, user)
          customEvent('user_reauthenticated')
        })
        .catch(() => {
          !NON_NAV_SCREENS.includes(pathname) && !GUEST_SCREENS.includes(pathname) && !STORE_SCREEN &&
            history.push("/login");
          setIsAuthed(false);
        });
    };
    getAuth();
    // eslint-disable-next-line
  }, []);

  return isAuthed === null ? (
    <span /> // initial load
  ) : (
    <>
      {!NON_NAV_SCREENS.includes(pathname) && (
        <>
          <Navbar store={STORE_SCREEN} />
          <SideMenu store={STORE_SCREEN} />
        </>
      )}
      <Switch>
        <Route path="/login">
          <LoginScreen />
        </Route>
        <Route path="/reset-password">
          <ResetPasswordScreen />
        </Route>
        <Route path="/service-agreement">
          <ServiceAgreementScreen />
        </Route>
        <Route path="/search/:storeId?">
          <SearchScreen />
        </Route>
        <Route path="/search-results/:storeId?">
          <SearchResults />
        </Route>
        <Route path="/saved">
          <SavedScreen />
        </Route>
        <Route path="/messages/:conversationId">
          <ChatScreen />
        </Route>
        <Route path="/messages">
          <ChatScreen />
        </Route>
        <Route path="/inventory">
          <ListingsScreen />
        </Route>
        <Route path="/settings">
          <SettingsScreen />
        </Route>
        <Route path="/dashboard">
          <HomeScreen />
        </Route>
        <Route path="/store/:storeId">
          <HomeScreen />
        </Route>
        <Route path="/signup/:screen">
          <SignUpScreen />
        </Route>
        <Route path="/">
          <LandingScreen />
        </Route>
      </Switch>
    </>
  );
};
