import React from "react";
import moment from "moment";

import TableCell from "./TableCell";
import TableHeader from "./TableHeader";
import { Icon } from "../../components";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/reducers/userReducer";
import { convertProductUnits } from "../../utils/UnitUtils";
import imageRequest from "../../utils/ImageUtils";

const ListingsTable = ({
  activeTab,
  slabs,
  focusedSlab,
  selectedSlabs,
  isFullWidth = true,
  hideActions = false,
  selectSlab,
  setModal,
  onSlabClick,
}) => {
  const user = useSelector(selectUser) || {};
  return (
    <div className="ListingsTable LeftSide pt-0 mx-auto relative">
      <div className="bg-white shadow-md rounded">
        <table className={`table table-sticky w-full text-left`}>
          <thead>
            <tr className="bg-white">
              <TableHeader></TableHeader>
              <TableHeader>SLAB ID</TableHeader>
              <TableHeader>TYPE</TableHeader>
              {isFullWidth && (
                <>
                  <TableHeader>TONE</TableHeader>
                  {activeTab === 0 && (
                    <>
                      <TableHeader>DATE LISTED</TableHeader>
                      <TableHeader>PRICE</TableHeader>
                    </>
                  )}
                  {activeTab === 1 && (
                    <>
                      <TableHeader>DATE LISTED/SOLD</TableHeader>
                      <TableHeader>PRICE/SOLD</TableHeader>
                    </>
                  )}
                  <TableHeader>DIMENSIONS</TableHeader>
                </>
              )}
              {!hideActions && <TableHeader>ACTIONS</TableHeader>}
            </tr>
          </thead>
          <tbody>
            {slabs.map((s, i) => {
              const u = user.preferredUnits;
              const { description } = convertProductUnits(s, u, 0);
              return (
                <tr
                  className={`hover:bg-orange-200 transition-color duration-100 cursor-pointer ${
                    focusedSlab && focusedSlab.id === i ? "bg-orange-100" : ""
                  } ${!s.isPublished && !s.isSold && "bg-gray-100"}`}
                  key={i}
                  onClick={() => onSlabClick({ ...s, id: i })}
                >
                  <TableCell>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="h-5 w-5 text-orange mr-4"
                        checked={selectedSlabs.has(s._id)}
                        onChange={() => selectSlab(s)}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <img
                        src={imageRequest(s.images[0].url, 800)}
                        className="rounded-md w-16 h-16 object-cover"
                        alt="Slab"
                      />
                    </div>
                  </TableCell>
                  <TableCell nowrap>{(s.slabType === "Full" ? "F" : "R")+"-"+(s.locationId ? s.locationId : 0)+"-"+s.slabId}</TableCell>
                  <TableCell>
                    {s.type + (s.brand ? " - " + s.brand : "")}
                  </TableCell>
                  {isFullWidth && (
                    <>
                      <TableCell>{s.tone}</TableCell>
                      {activeTab === 0 && (
                        <>
                          <TableCell>
                            {s.isPublished || s.isSold ? (
                              moment(s.publishedAt).format("MMM Do YYYY")
                            ) : (
                              <div className="text-red-400">Unpublished</div>
                            )}
                          </TableCell>
                          <TableCell>${s.price}</TableCell>
                        </>
                      )}
                      {activeTab === 1 && (
                        <>
                          <TableCell>
                            {moment(s.publishedAt).format("MMM Do YYYY")}
                            /<span className="text-red-500">{moment(s.soldAt).format("MMM Do YYYY")}</span>
                          </TableCell>
                          <TableCell>${s.price}/<span className="text-red-500">${s.soldPrice}</span></TableCell>
                        </>
                      )}
                      <TableCell>{description}</TableCell>
                    </>
                  )}
                  {!hideActions && (
                    <TableCell>
                      {s.isSold ? (
                        <div className="tooltip">
                          <Icon
                            name="gavel"
                            color="#6B7280"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModal("UNSOLD", s);
                            }}
                          />
                          <span className="tooltiptext">Relist</span>
                        </div>
                      ) : (
                        <div className="flex gap-2 relative">
                          {s.isPublished ? (
                            <div
                              className={`${
                                s.reservationId ? "" : "invisible"
                              } tooltip relative`}
                            >
                              <Icon
                                name="bookmark"
                                className={`fill-current text-gray-500 mr-1`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setModal("UNRESERVE", s);
                                }}
                              />
                              <span className="tooltiptext">Unreserve</span>
                            </div>
                          ) : (
                            <div className={`tooltip relative`}>
                              <Icon
                                name="book"
                                color="#6B7280"
                                className={`fill-current text-gray-500 mr-1`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setModal("PUBLISH", s);
                                }}
                              />
                              <span className="tooltiptext">Publish</span>
                            </div>
                          )}
                          <div className="tooltip">
                            <Icon
                              name="gavel"
                              color="#6B7280"
                              onClick={(e) => {
                                e.stopPropagation();
                                setModal("SOLD", s);
                              }}
                            />
                            <span className="tooltiptext">Mark Sold</span>
                          </div>
                        </div>
                      )}
                    </TableCell>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListingsTable;
