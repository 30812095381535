import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConversations,
  selectConversations,
} from "../redux/reducers/chatReducer";
import { selectUser } from "../redux/reducers/userReducer";
import moment from 'moment';

const useChat = (pollRate) => {
  const [unreadCount, setUnreadCount] = useState(UNREAD_COUNT);
  const [unreadConvos, setUnreadConvos] = useState([]);
  const dispatch = useDispatch();
  const convos = useSelector(selectConversations);
  const user = useSelector(selectUser) || {}; //eslint-disable-line
  useEffect(() => {
    if (!user._id) return;
    const unreadIds = [];
    const unreadCount = convos.reduce((acc, convo) => {
      const userData =
        convo.participantData.find(({ _id }) => _id._id === user._id) || {};
      const seenAt = userData.lastSeenAt;
      const lastMsg = convo.lastMessageId;
      const hasUnread =
        lastMsg &&
        lastMsg.sentBy !== user._id &&
        (!seenAt || new Date(seenAt) < new Date(lastMsg.createdAt))
          ? 1
          : 0;
      const isNew = !seenAt;
      (hasUnread || isNew) && unreadIds.push(convo._id);
      return {
        unread: acc.unread + hasUnread + isNew,
        selling:
          acc.selling + (convo.accountId === user.accountId ? hasUnread + isNew : 0),
        buying:
          acc.buying + (convo.accountId !== user.accountId ? hasUnread + isNew : 0),
      };
    }, UNREAD_COUNT);
    setUnreadCount(unreadCount);
    setUnreadConvos(unreadIds);
  }, [convos, user._id, user.accountId]);

  useEffect(() => {
    let interval;
    if (user.conversations && pollRate !== false) {
      const fetch = () => dispatch(fetchConversations(user.conversations));
      if (pollRate) interval = setInterval(fetch, pollRate);
      fetch();
    }
    return () => clearInterval(interval);
  }, [user.conversations, pollRate, dispatch]);

  return { conversations: convos, unreadCount, unreadConvos };
};

export default useChat;

const UNREAD_COUNT = { unread: 0, selling: 0, buying: 0 };
