import React, { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Card, Screen, Filler } from "../../components";
import SlabDetails from "../../components/panels/SlabDetails";
import {
  selectStoreOwner,
  selectHomeFeed,
  fetchHomeFeed
} from "../../redux/reducers/productsReducer";
import { selectUser, setFabricator } from "../../redux/reducers/userReducer";
import {accountService, reservationService} from "../../services";
import { convertProductUnits } from "../../utils/UnitUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import Icon from "../../components/Icon/Icon";
import useStoreId from "../../hooks/useStoreId";
import {customEvent} from "../../utils/AnalyticsUtils";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const {storeId} = useStoreId();
  const [page, setPage] = useState(1);
  const user = useSelector(selectUser) || {};
  const storeOwner = useSelector(selectStoreOwner) || {};
  useEffect(() => dispatch(fetchHomeFeed(page, storeId)), [dispatch, page, storeId]);
  const { data: products, total } = useSelector(selectHomeFeed);
  const [selectedProduct, setSelectedProduct] = useState(null);
  let history = useHistory ();

  useEffect(() => {
    if(history?.location?.state?.product)
      setSelectedProduct(history.location.state.product)
  }, [])

  useEffect(async () => {
    if(user.accountId){
      let account = await accountService.get(user.accountId);
      dispatch(setFabricator(account))
    }
  }, [user])

  useEffect(() => {
    if (!selectedProduct || !selectedProduct.reservationId || Object.keys(user).length === 0) return;
    async function fetchReservation() {
      if (selectedProduct.reservationDetails) return;
      const reservationId = selectedProduct.reservationId._id;
      const reservationDetails = await reservationService.get(reservationId);
      setSelectedProduct({ ...selectedProduct, reservationDetails });
    }
    fetchReservation();
  }, [selectedProduct]);

  return (
    <Screen header={
        <div className="z-10 h-12 md:text-2xl text-xl font-semibold bg-white w-full flex flex-row justify-between items-center flex-1">
          <div className="md:hidden flex">
            { selectedProduct ? (
              <div className="flex items-center">
                <Icon
                  name="arrow-left"
                  className="mr-2"
                  onClick={() => setSelectedProduct(null)}
                />
                Back to Browse
              </div>
            ) : (
              storeId && storeOwner.name ?
                `Browse ${storeOwner.name}'s Products`
              : "Browse"
            )}
          </div>
          <div className="hidden md:flex">
            {storeId && storeOwner.name ?
              `Browse ${storeOwner.name}'s Products`
              : "Browse"
            }
          </div>
        </div>
    } autopadding={false}>
      <div className="flex flex-row">
        <div id="infiniteParent" className={`LeftSide ${selectedProduct ? "hidden md:flex" : "bg-white"}`}>
          <InfiniteScroll
            className="CardGallery"
            dataLength={total-products.length} //This is important field to render the next data
            hasChildren={true}
            next={() => setPage(page + 1)}
            hasMore={products.length < total}
            endMessage={<div className="w-full flex flex-row justify-center items-center py-5">
              <p className="ml-3 text-xl">No more results.</p>
            </div>}
            loader={
              products.length < total && (
                <div className="w-full flex flex-row justify-center items-center py-5">
                  <Icon
                    className="animate-spin text-orange"
                    size={8}
                    name="loading"
                  />
                  <p className="ml-3 text-xl">Loading...</p>
                </div>
              )
            }
            scrollableTarget="infiniteParent"
          >
            {products &&
              products.map((p) => {
                const u = user.preferredUnits;
                const { description } = convertProductUnits(p, u, 0);
                return (
                  <Card
                    key={p._id}
                    product={p}
                    image={p.images[0].url}
                    heading={p.type + (p.brand ? " - " + p.brand : "")}
                    subheading={description}
                    onClick={() => {
                      setSelectedProduct(p);
                      customEvent('product_clickthrough', p)
                    }}
                    bgColor={
                      selectedProduct && selectedProduct._id === p._id
                        ? "orange-200"
                        : "white"
                    }
                  />
                );
              })}
          </InfiniteScroll>
        </div>
        <div className={`RightSide ${selectedProduct ? "" : "bg-white hidden md:flex"}`}>
          {selectedProduct ? (
            <>
              <SlabDetails
                storeId={storeId}
                product={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </>
          ) : (
            <Filler type="product" />
          )}
        </div>
      </div>
    </Screen>
  );
};

export default HomeScreen;
