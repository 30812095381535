import caesarstone from "./caesarstone.png";
import cambria from "./cambria.png";
import laminam from "./laminam.png";
import lgHausys from "./lg-hausys.png";
import radianz from "./radianz.png";
import silostone from "./silostone.png";
import tce from "./tce.png";
import vicostone from "./vicostone.png";

export default [caesarstone, cambria, laminam, lgHausys, radianz, silostone, tce, vicostone]
