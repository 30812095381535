import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AlertContext } from "../../context/alert";
import {
  removeProductFromFavorites,
  setProductAsFavorite,
} from "../../redux/reducers/productsReducer";
import { selectUser, updateUser } from "../../redux/reducers/userReducer";
import { Icon } from "..";
import { customEvent} from "../../utils/AnalyticsUtils";

const FavoriteButton = ({ product, onClickNoUser }) => {
  const user = useSelector(selectUser) || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const { dispatchAlert } = React.useContext(AlertContext);
  const userFavorites = user.favourites || [];
  const productIsFavorited = userFavorites.includes(product._id);
  const isMyProduct = product.accountId === user.accountId;

  return isMyProduct ? (
    <span />
  ) : (
    <Icon
      name="heart"
      color={productIsFavorited ? "" : "white"}
      className={productIsFavorited ? "fill-current text-orange" : ""}
      size={8}
      onClick={() => {
        if (Object.keys(user).length > 0) {
          if (productIsFavorited) {
            // UNFAVORITE
            const trimmedFaves = [...userFavorites];
            trimmedFaves.splice(userFavorites.indexOf(product._id), 1);
            dispatch(updateUser({ favourites: trimmedFaves }));
            dispatch(removeProductFromFavorites(product));
            dispatchAlert({
              type: "open",
              position: "right",
              message: `${
                product.type + (product.brand ? " - " + product.brand : "")
              } removed from favorites.`,
            });
            customEvent("unliked_product", product)
          } else {
            // FAVORITE
            dispatch(
              updateUser({
                favourites: [...userFavorites, product._id],
              })
            );
            dispatch(setProductAsFavorite(product));
            dispatchAlert({
              type: "open",
              position: "right",
              message: `${
                product.type + (product.brand ? " - " + product.brand : "")
              } added to favorites.`,
            });
            customEvent("liked_product", product)
          }
        } else {
          onClickNoUser ? onClickNoUser() : history.push("/login");
        }
      }}
    />
  )
};

export default FavoriteButton;
