import React, {useState} from "react";
import {Button} from "../../components";
import appExampleImg1 from "../../assets/images/app_example_1.png";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {accountService} from "../../services";

const SignUpPayment = ({ signUpData, goToNextScreen, goBack, paymentStatus }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [submitting, setSubmitting]= useState(false)

  const handleSubmit = async (event) => {
    if(paymentStatus === "succeeded")
      goToNextScreen()
    else{
      setSubmitting(true)
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      //const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
        //payment_method: {
          //card: cardElement,
        //}
      //})

      let accountData = {
        name: signUpData.companyInformation.name,
        email: signUpData.companyInformation.email,
        mainContact: {
          phoneNumber: signUpData.companyInformation.phoneNumber,
          firstName: signUpData.companyInformation.firstName,
          lastName: signUpData.companyInformation.lastName,
        },
        address: {
          street: signUpData.companyInformation.address,
          suiteNumber: signUpData.companyInformation.suiteNumber,
          city: signUpData.companyInformation.city,
          province: signUpData.companyInformation.province,
          country: signUpData.companyInformation.country,
          postalCode: signUpData.companyInformation.postalCode
        },
        latitude: signUpData.companyInformation.latitude,
        longitude: signUpData.companyInformation.longitude,
        priceId: signUpData.pricingPlan.priceId,
      }
        
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log('[error]', error);
      } else {

        accountData.stripePaymentMethod = {
          id: paymentMethod.id
        }
        
        const response = await accountService.create(accountData)

        setSubmitting(false)
        if(response.accountDetails._id){
          goToNextScreen({ status: "succeeded", accountId: response.accountDetails._id })
        }
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col md:items-center  md:flex-row md:mt-10">
        <div className="p-4 md:w-1/2">
          <p className="mt-2 text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
            Step 3/4
          </p>
          <p className="my-4 text-lg leading-normal tracking-tight text-gray-900">
            Enter payment information
          </p>
          <div className="w-full">
            <CardField
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </div>
          <Button
            disabled={error || !cardComplete || submitting}
            loading={submitting}
            className="w-full mt-2 mr-4"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
        <div className="overflow-hidden justify-self-center w-4/5 md:w-1/2 hidden md:block">
          <img
            src={appExampleImg1}
            alt="app example"
            className="example-image hero"
          />
        </div>
      </div>
    </div>
  )
};

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#f46748",
      padding: 6,
      color: "#4d4d4d",
      fontWeight: 500,
      borderRadius: "0.375rem",
      borderWidth: 1,
      borderColor: "#ccc",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#4d4d4d"
      },
      "::placeholder": {
        color: "#ccc"
      }
    },
    invalid: {
      iconColor: "#cb2e0b",
      color: "#f46748"
    }
  }
};

const CardField = ({ onChange }) => (
  <div className="rounded-md p-6 bg-gray-100 mb-4">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

export default SignUpPayment;
