import React, { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import SignUpCompanyInfo from "./SignUpCompanyInfo";
import SignUpPassword from "./SignUpPassword";
import SignUpPayment from "./SignUpPayment";
import SignUpPricing from "./SignUpPricing";
import SignUpType from "./SignUpType";
import SignUpFree from "./SignUpFree";
import slabGrey from "../../assets/images/slab-grey.png";
import logo from "../../assets/images/logo.png";
import Footer from "../Footer";

const SignUpScreen = () => {
  const [signUpData, setSignUpData] = useState({
    companyInformation: {},
    pricingPlan: {period: "annually"},
    paymentInformation: {},
    password: {}
  });

  const params = useParams();
  const history = useHistory();

  const goToNextScreen = (formData) => {
    if(formData)
      setSignUpData({ ...signUpData, [params.screen]: formData });
    const screens = Object.keys(signUpData);
    history.push(screens[screens.indexOf(params.screen) + 1]);
  };

  const goBack = () => {
    const screens = Object.keys(signUpData);
    history.push(screens[screens.indexOf(params.screen) - 1]);
  };

  return (
    <div className="SignUpScreen min-h-screen flex flex-col justify-between">
      <div className="flex">
        <img src={slabGrey} alt="grey slab" className="angled-slab-img w-24 hidden md:block" />
        <div className="py-4 pl-4 w-full">
          <div className="flex items-center justify-between mb-4 pr-8">
            <Link to="/">
              <img src={logo} alt="app logo" className="w-36" />
            </Link>
            <div className="flex hidden md:block">
              <Link to="/login">
                <button
                  className="relative rounded border border-orange-400 transition-color duration-100
         hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-3"
                >
                  Log in
                </button>
              </Link>
            </div>
          </div>
          {
            {
              type: (
                <SignUpType />
              ),
              free: (
                <SignUpFree />
              ),
              companyInformation: (
                <SignUpCompanyInfo
                  signUpData={signUpData}
                  goToNextScreen={goToNextScreen}
                />
              ),
              pricingPlan: (
                <SignUpPricing
                  signUpData={signUpData}
                  goToNextScreen={goToNextScreen}
                  goBack={goBack}
                />
              ),
              paymentInformation: (
                <SignUpPayment
                  signUpData={signUpData}
                  goToNextScreen={goToNextScreen}
                  goBack={goBack}
                  paymentStatus={signUpData.paymentInformation.status}
                  accountId={signUpData.paymentInformation.accountId}
                />
              ),
              password: (
                <SignUpPassword
                  goToNextScreen={goToNextScreen}
                  goBack={goBack}
                  signUpData={signUpData}
                />
              ),
            }[params.screen]
          }
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUpScreen;
