import React from "react";
import { Link } from "react-router-dom";

import Icon from "../Icon/Icon";
import "./Button.css";

const Button = ({ type = "primary", disabled, theme, className, ...props }) => {
  theme = theme || themes[disabled ? "disabled" : type];
  const classN = `${className} ${type} relative rounded border-${theme.border}
    bg-${theme.bg} transition-color duration-100 hover:bg-${theme.hover || "gray-400"}
    text-${theme.color} font-bold py-2 px-4
    cursor-${disabled ? "default" : "pointer"}
    ${props.icon ? " inline-flex items-center gap-1" : ""} text-center`;

  return props.to ? (
    <Link to={props.to} className={classN}>
      {props.icon && <Icon size={4} name={props.icon} />}
      {props.children}
    </Link>
  ) : (
    <button
      {...props}
      onClick={disabled ? () => {} : props.onClick}
      className={classN}
    >
      {props.loading && <Icon className="animate-spin absolute top-0" size={6} name="loading" />}
      {props.icon && <Icon size={4} name={props.icon} />}
      {props.children}
    </button>
  );
};

export default Button;

const themes = {
  primary: {
    color: "white",
    bg: "orange",
    border: "orange",
    hover: "orange-700",
  },
  secondary: {
    color: "orange",
    bg: "",
    border: "orange-400",
    hover: "gray-100",
  },
  disabled: {
    color: "gray-400",
    bg: "gray-300",
    border: "gray-300",
    hover: "gray-300",
  },
};
