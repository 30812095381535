import React, {useState} from "react";
import Button from "../../components/Button/Button";

let plans = {
  lite: {
    monthly: process.env.REACT_APP_LITE_MONTHLY_PRICE_ID,
    annually: process.env.REACT_APP_LITE_ANNUALLY_PRICE_ID,
  },
  standard: {
    monthly: process.env.REACT_APP_STANDARD_MONTHLY_PRICE_ID,
    annually: process.env.REACT_APP_STANDARD_ANNUALLY_PRICE_ID,
  },
  premium: {
    monthly: process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID,
    annually: process.env.REACT_APP_PREMIUM_ANNUALLY_PRICE_ID,
  }
}

const SignUpPricing = ({ goToNextScreen, goBack, signUpData }) => {
  const [plan, setPlan] = useState({...signUpData.pricingPlan})
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async () => {
    goToNextScreen({...plan, priceId: plans[plan.type][plan.period]})
    /*
    if(!signUpData.pricingPlan.clientSecret){
      //setSubmitting(true)

      let accountData = {
        name: signUpData.companyInformation.name,
        email: signUpData.companyInformation.email,
        mainContact: {
          phoneNumber: signUpData.companyInformation.phoneNumber,
          firstName: signUpData.companyInformation.firstName,
          lastName: signUpData.companyInformation.lastName,
        },
        address: {
          street: signUpData.companyInformation.address,
          suiteNumber: signUpData.companyInformation.suiteNumber,
          city: signUpData.companyInformation.city,
          province: signUpData.companyInformation.province,
          country: signUpData.companyInformation.country,
          postalCode: signUpData.companyInformation.postalCode
        },
        latitude: signUpData.companyInformation.latitude,
        longitude: signUpData.companyInformation.longitude,
        priceId: plans[plan.type][plan.period]
      }
      const response = await accountService.create(accountData)
      setSubmitting(false)
      if(response.clientSecret)
        goToNextScreen({...plan, priceId: plans[plan.type][plan.period], clientSecret: response.clientSecret, accountId: response.accountDetails._id})
    } else
      goToNextScreen()
    */
  }

  return (
    <div className="flex items-center p-4">
      <div className="w-full">
        <p className="mt-2 text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
          Step 2/4
        </p>
        <p className="my-4 text-lg leading-normal tracking-tight text-gray-900">
          Choose a pricing plan
        </p>

        <div className="flex w-full justify-center md:mt-0 mt-8">
          {periodSelector(plan.period || "annually", (period) => setPlan({...plan, period}))}
        </div>
        <div className="flex flex-col w-full md:flex-row">
          <div className="w-full mr-3 md:w-1/3 mt-7 flex-col flex justify-between">
            <div>
              <div className="w-full border-b-4 border-orange"/>
              <p className="text-2xl my-3 font-bold">Lite</p>
              <p className="text-xl my-3">{plan.period === "annually" ? "$1,089/year" : "$99/month"}</p>
              {plan.period === "annually" && (
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                  <p>1 free month</p>
                </div>
              )}
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Get 1 reservation at a time</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>1 user</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Unlimited posting</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Inventory tracking</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Live messaging</p>
              </div>
              <div className="flex flex-row my-2">
                <div className="w-3 h-3 mt-2 rounded-full border-2 border-orange mr-2"/>
                <p className="w:full md:w-3/5">
                  Access to thousands of slabs and remnants in your area
                </p>
              </div>
            </div>

            <button
              onClick={() => {
                setPlan({...plan, type: "lite"})
              }}
              className={`mt-2 relative rounded border ${plan.type !== "lite" ? "bg-white text-orange hover:bg-gray-200" : "bg-orange text-white over:bg-orange-700"} 
              border-orange transition-color duration-100 font-bold py-2 px-4 cursor-pointer text-center w-full`}
            >
              Get started with Lite
            </button>
          </div>
          <div className="w-full md:w-1/3 flex-col flex justify-between md:mt-0 mt-16">
            <div>
              <p className="font-bold text-orange text-xl mb-px">RECOMMENDED</p>
              <div className="bg-orange-100">
                <div className="w-full border-b-4 border-orange"/>
                <div className="pl-2">
                  <p className="text-2xl my-3 font-bold">Standard</p>
                  <p className="text-xl my-3">{plan.period === "annually" ? "$1,639/year" : "$149/month"}</p>
                  {plan.period === "annually" && (
                    <div className="flex flex-row items-center my-2">
                      <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                      <p>1 free month</p>
                    </div>
                  )}
                  <div className="flex flex-row items-center my-2">
                    <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                    <p>Get 5 reservations at a time</p>
                  </div>
                  <div className="flex flex-row items-center my-2">
                    <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                    <p>3 users</p>
                  </div>
                  <div className="flex flex-row items-center my-2">
                    <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                    <p>Unlimited posting</p>
                  </div>
                  <div className="flex flex-row items-center my-2">
                    <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                    <p>Inventory tracking</p>
                  </div>
                  <div className="flex flex-row items-center my-2">
                    <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                    <p>Live messaging</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <div className="w-3 h-3 mt-2 rounded-full border-2 border-orange mr-2"/>
                    <p className="w-full md:w-3/5">
                      Access to thousands of slabs and remnants in your area
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setPlan({...plan, type: "standard"})
              }}
              className={`mt-2 relative rounded border ${plan.type !== "standard" ? "bg-white text-orange hover:bg-gray-200" : "bg-orange text-white over:bg-orange-700"} 
              border-orange transition-color duration-100 font-bold py-2 px-4 cursor-pointer text-center w-full`}
            >
              Get started with Standard
            </button>
          </div>
          <div className="w-full md:w-1/3 md:ml-3 flex-col flex justify-between">
            <div>
              <div className="w-full border-b-4 border-orange md:mt-7 mt-16"/>
              <p className="text-2xl my-3 font-bold">Premium</p>
              <p className="text-xl my-3">{plan.period === "annually" ? "$2,189/year" : "$199/month"}</p>
              {plan.period === "annually" && (
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                  <p>1 free month</p>
                </div>
              )}
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Get unlimited reservations at a time</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Unlimited users</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Unlimited posting</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Inventory tracking</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Live messaging</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2"/>
                <p>Free promotions on our social media platforms</p>
              </div>
              <div className="flex flex-row my-2">
                <div className="w-3 h-3 mt-2 rounded-full border-2 border-orange mr-2"/>
                <p className="w-3/5">
                  Access to thousands of slabs and remnants in your area
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                setPlan({...plan, type: "premium"})
              }}
              className={`mt-2 relative rounded border ${plan.type !== "premium" ? "bg-white text-orange hover:bg-gray-200" : "bg-orange text-white over:bg-orange-700"} 
              border-orange transition-color duration-100 font-bold py-2 px-4 cursor-pointer text-center w-full`}
            >
              Get started with Premium
            </button>
          </div>
        </div>

        <div className="flex flex:row w-full justify-between mt-8">
          <div className="w-full pr-3 md:w-1/3">
            <button
              className="relative rounded border border-orange-400 transition-color duration-100
         hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center w-full mt-2"
              onClick={goBack}
            >
              Go Back
            </button>
          </div>
          <div/>
          <div className="w-full pl-3 md:w-1/3">
            <Button
              disabled={!plan.type || submitting}
              loading={submitting}
              className="w-full mt-2"
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
};

const periodSelector = (period, setPeriod) => {
  return (
    <div className="flex flex-row border-2 border-orange rounded p-1 mb-6">
      <button
        onClick={() => {
          setPeriod("annually")
        }}
        className={`outline-none relative rounded ${period !== "annually" ? "bg-white text-orange hover:bg-gray-200" : "bg-orange text-white over:bg-orange-700"} 
              transition-color duration-100 font-bold py-2 px-4 cursor-pointer text-center w-full mr-1`}
      >
        Yearly
      </button>
      <button
        onClick={() => {
          setPeriod("monthly")
        }}
        className={`outline-none relative rounded ${period !== "monthly" ? "bg-white text-orange hover:bg-gray-200" : "bg-orange text-white over:bg-orange-700"} 
               transition-color duration-100 font-bold py-2 px-4 cursor-pointer text-center w-full`}
      >
        Monthly
      </button>
    </div>
  )
}

export default SignUpPricing;
