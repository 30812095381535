import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useScreenTabs from "../../hooks/useScreenTabs";
import { Card, Screen, ScreenHeader, Filler } from "../../components";
import SlabDetails from "../../components/panels/SlabDetails";
import { selectUser } from "../../redux/reducers/userReducer";
import {
  fetchSavedProducts,
  selectFavorites,
  selectReserved,
} from "../../redux/reducers/productsReducer";
import { convertProductUnits } from "../../utils/UnitUtils";
import "./SavedScreen.css";
import moment from "moment";

(function(){
  if (typeof Object.defineProperty === 'function'){
    try{Object.defineProperty(Array.prototype,'sortBy',{value:sb}); }catch(e){}
  }
  if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

  function sb(f){
    for (let i=this.length;i;){
      let o = this[--i];
      this[i] = [].concat(f.call(o,o,i),o);
    }
    this.sort(function(a,b){
      for (let i=0,len=a.length;i<len;++i){
        if (a[i]!=b[i]) return a[i]<b[i]?-1:1;
      }
      return 0;
    });
    for (let i=this.length;i;){
      this[--i]=this[i][this[i].length-1];
    }
    return this;
  }
})();

const SavedScreen = () => {
  const dispatch = useDispatch();
  const [activeTab, TabJsx] = useScreenTabs(["Favourites", "Reserved"]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [loadComplete, setLoadComplete] = useState(false);
  const [validReservations, setValidReservations] = useState([]);
  useEffect(() => setSelectedItem(null), [activeTab]);

  const favoriteProducts = useSelector(selectFavorites);
  const reservedProducts = useSelector(selectReserved);
  const user = useSelector(selectUser) || {};
  const faveProductIds = (user || {}).favourites || [];

  useEffect(
    () => dispatch(fetchSavedProducts(faveProductIds)),
    [user, dispatch] // eslint-disable-line
  );
  useEffect(() => {
    if (!user || loadComplete || activeTab !== 1) return;
    const reservedProductIds =
      user.reservations && user.reservations.map((r) => r.productId);
    dispatch(fetchSavedProducts(reservedProductIds, true));
    setLoadComplete(true);
  }, [activeTab, user]); // eslint-disable-line

  useEffect(() => {
    const filteredReservations = reservedProducts.filter((p, i) => {
      let expired = true;
      const reservation = user.reservations.find(o => o._id === p?.reservationId?._id)
      if(reservation){
        const dateThreshold = moment().subtract(2, 'days');
        expired = moment(reservation.createdAt).isBefore(dateThreshold)
      }
      return !expired
    })
    setValidReservations(filteredReservations)
  }, [user.reservations, reservedProducts])

  return (
    <Screen
      header={
        <ScreenHeader
          titles={["Saved", "Item Details"]}
          isSelected={selectedItem}
          clearSelection={() => setSelectedItem(null)}
        />
      }
      className="SavedScreen"
      autopadding={false}
    >
      <div className="flex items-start">
        <div className="pt-4 w-full mx-auto relative">
          {TabJsx}
          <div className="SavedLeft LeftSide CardGallery">
            {(activeTab === 0 ? favoriteProducts : validReservations).map(
              (p, i) => {
                const u = user.preferredUnits;
                const { description } = convertProductUnits(p, u, 0);
                return (
                  <Card
                    key={i}
                    product={p}
                    image={p.images[0].url}
                    heading={p.type + (p.brand ? " - " + p.brand : "")}
                    subheading={description}
                    bgColor={
                      selectedItem && selectedItem._id === p._id
                        ? "orange-200"
                        : "white"
                    }
                    onClick={() => setSelectedItem(p)}
                    isLiked={activeTab === 0 || user.favourites.includes(p._id)}
                    isReservedByMe={
                      activeTab === 1 || user.reservations.includes(p._id)
                    }
                  />
                );
              }
            )}
            {activeTab === 0 && !favoriteProducts.length ? (
              <h2 className="mt-4 ml-2">No favorited items yet</h2>
            ) : (
              activeTab === 1 &&
              !validReservations.length && (
                <h2 className="mt-4 ml-2">No reserved items yet</h2>
              )
            )}
          </div>
        </div>
        <div className={`RightSide ${selectedItem ? "" : "bg-white"}`}>
          {selectedItem ? (
            <SlabDetails
              product={selectedItem}
              setSelectedProduct={setSelectedItem}
            />
          ) : (
            <Filler type="product" />
          )}
        </div>
      </div>
    </Screen>
  );
};

export default SavedScreen;
