import React, {useState, useEffect} from 'react';
import ServiceAgreementPDF from "../../components/ServiceAgreement/ServiceAgreementPDF";
import { PDFViewer } from '@react-pdf/renderer';
import { useSelector } from "react-redux";
import {selectUser} from "../../redux/reducers/userReducer";
import {accountService} from "../../services";
import Icon from "../../components/Icon/Icon";

const ServiceAgreementScreen = () => {
  let user = useSelector(selectUser);
  const [client, setClient] = useState(null);

  const clientObject = {
    companyInformation: {
      name: 'Test Co',
      suiteNumber: '12a',
      address: '1 Marley Road',
      city: 'Toronto',
      province: 'Ontario',
      postalCode: 'M4S1C9',
      email: 'test@test.com'
    }
  }

  useEffect(() => {
    console.log(user)
    const fetchAccount = async (accountId) => {
      const account = await accountService.get(accountId);
      const address = account.address || {};
      const mainContact = account.mainContact || {}
      let clientInfo = {
        name: account.name,
        phoneNumber: mainContact.phoneNumber,
        address: address.street,
        suiteNumber: address.suiteNumber,
        postalCode: address.postalCode,
        province: address.province,
        city: address.city,
        country: address.country,
        email: user.email
      }
      setClient(clientInfo)
    };
    user?.accountId && fetchAccount(user.accountId);
  }, [user]);

  return (
    <>
      { client ? (
        <PDFViewer width={window.innerWidth} height={window.innerHeight}>
          <ServiceAgreementPDF client={client} />
        </PDFViewer>
      ) : (
        <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}>
          <Icon className="animate-spin top-0" size={12} name="loading" />
        </div>
      )}
    </>
  )
}

export default ServiceAgreementScreen;
