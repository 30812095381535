import React from "react";

import "./Screen.css";

const Screen = ({ children, header, className, autopadding, fullHeight }) => {
  return (
    <div className={`Screen flex ${className}`}>
      <div className="flex-col w-full">
        <ScreenHeader autopadding={autopadding}>{header}</ScreenHeader>
        <div style={fullHeight && {height: "100%"}} className="Screen-content">{children}</div>
      </div>
    </div>
  );
};

export default Screen;

const ScreenHeader = ({ children, autopadding = true }) => {
  return (
  <>
    <div className="p-3 sm:p-0 z-10 h-16 text-2xl font-semibold border-b-2 bg-white w-full flex flex-row justify-between items-center flex-1">
      {typeof children === "string" ? (
        <p className="self-center">{children || "Header"}</p>
      ) : (
        <>{children || "Header"}</>
      )}

    </div>
    {autopadding && <div className="" />}
  </>
)};
