import { createSlice } from "@reduxjs/toolkit";
import { userService } from "../../services";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    fabricator: null
  },
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      updateUserInDb(state.user._id, action.payload);
    },
    setFabricator: (state, action) => {
      state.fabricator = action.payload;
    },
    updateUserState: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { setAccount, setUser, updateUser,updateUserState, setFabricator } = userSlice.actions;

const updateUserInDb = (uid, userData) => userService.patch(uid, userData);

export const selectUser = (state) => state.user.user;
export const selectFabricator = (state) => state.user.fabricator;
export const selectAccount = (state) => state.user.account

export default userSlice.reducer;
