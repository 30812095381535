import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import createYupSchema from "../../createYupSchema";

import { Input, Button } from "../../components";
import { capitalize } from "../../utils/StringUtils";
import appExampleImg3 from "../../assets/images/app_example_3.png";
import {geocodeAddress} from "../../utils/GeocodeUtils";

const SignUpCompanyInfo = ({ goToNextScreen, signUpData }) => {
  let formData = signUpData.companyInformation;
  const [submitting, setSubmitting] = useState(false);
  const [fields] = useState([
    {
      field: "name",
      displayName: "Company name",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "firstName",
      displayName: "First name",
      width: "half",
      pos: "first",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "lastName",
      displayName: "Last name",
      width: "half",
      pos: "second",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "email",
      displayName: "Email",
      width: "half",
      pos: "first",
      type: "email",
      validationType: "string",
      validations: [
        { type: "required", params: ["This field is required."] },
        { type: "email", params: ["Please enter a valid email."] },
      ],
    },
    {
      field: "phoneNumber",
      displayName: "Phone Number",
      width: "half",
      pos: "second",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "address",
      displayName: "Address",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "suiteNumber",
      displayName: "Suite Number",
      validationType: "string",
    },
    {
      field: "city",
      displayName: "City",
      width: "half",
      pos: "first",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "province",
      displayName: "Province",
      width: "half",
      pos: "second",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "country",
      displayName: "Country",
      width: "half",
      pos: "first",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    },
    {
      field: "postalCode",
      displayName: "Postal code",
      width: "half",
      pos: "second",
      validationType: "string",
      validations: [{ type: "required", params: ["This field is required."] }],
    }
  ]);

  const yepSchema = fields.reduce(createYupSchema, {});
  const validateSchema = yup.object().shape(yepSchema);

  const renderFormInputs = (props) =>
    fields.map((f, i) => {
      let error = props.errors.hasOwnProperty(f.field) && props.errors[f.field];
      let touched =
        props.touched.hasOwnProperty(f.field) && props.touched[f.field];

      return (
        <div
          className={`mt-1 ${f.width === "half" ? "w-1/2" : "w-full"}`}
          key={i}
        >
          {f.field === "couponCode" && (
            <div className="w-full flex justify-center">
              <p className="text-gray-600 text-sm text-center flex md:w-3/5">
                Do you have a coupon code? Enter your code below to claim your
                reward!
              </p>
            </div>
          )}
          <div
            className={`${
              f.pos === "first" ? "mr-2" : f.pos === "second" && "ml-2"
            }`}
          >
            <Input
              key={i}
              value={props.values[f.field]}
              onChange={props.handleChange(f.field)}
              placeHolder={f.displayName || capitalize(f.field)}
              name={f.field}
              error={touched && error}
            />
          </div>
        </div>
      );
    });

  const initialValues = {};
  fields.forEach((f) => {
    initialValues[f.field] = formData[f.field] || "";
  });

  const handleSubmit = async (values) => {
    if (!formData.latitude) {
      setSubmitting(true);
      let accountData = { ...values };
      const coords = await geocodeAddress(accountData.postalCode);
      if (coords) {
        accountData.latitude = coords.latitude;
        accountData.longitude = coords.longitude;
      }
      
      setSubmitting(false);

      goToNextScreen(accountData);
      
    } else goToNextScreen();
  };

  return (
    <div>
      <div className="flex  items-center flex-col md:flex-row">
        <div className="p-4 md:w-1/2">
          <p className="mt-2 text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
            Step 1/4
          </p>
          <p className="my-4 text-lg leading-normal tracking-tight text-gray-900">
            Enter your company information
          </p>
          <div className="flex flex-row justify-between flex-wrap">
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {(props) => (
                <>
                  {renderFormInputs(props)}
                  <Button
                    loading={submitting}
                    disabled={submitting}
                    className="w-full mt-2"
                    onClick={props.handleSubmit}
                  >
                    Continue
                  </Button>
                </>
              )}
            </Formik>
          </div>
        </div>
        <div className="overflow-hidden justify-self-center w-4/5 md:w-1/2 hidden md:block">
          <img
            src={appExampleImg3}
            alt="app example"
            className="md:example-image hero"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpCompanyInfo;
