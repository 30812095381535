import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {Card, Filler, Icon, Screen, ScreenHeader} from "../../components";
import SlabDetails from "../../components/panels/SlabDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMultiQuery } from "../../hooks/useQuery";
import { selectUser } from "../../redux/reducers/userReducer";
import {accountService, productService} from "../../services";
import { convertProductUnits, inchesToCm } from "../../utils/UnitUtils";
import "./SearchResults.css";
import { AlertContext } from "../../context/alert";
import useStoreId from "../../hooks/useStoreId";
import {capitalize} from "../../utils/StringUtils";

const SearchResults = () => {
  const {
    query,
    slabTypes,
    colors,
    finishes,
    thickness,
    minWidth,
    minLength,
    minPrice,
    maxPrice,
    tones,
    types,
    dimensions,
    manufacturer,
    brand,
    maxDistance,
    latitude,
    longitude
  } = useMultiQuery([
    "query",
    "slabTypes",
    "colors",
    "finishes",
    "thickness",
    "minWidth",
    "minLength",
    "minPrice",
    "maxPrice",
    "dimensions",
    "tones",
    "types",
    "brand",
    "manufacturer",
    "maxDistance",
    "latitude",
    "longitude"
  ]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const user = useSelector(selectUser) || {};
  const [account, setAccount] = useState({});
  const {storeId} = useStoreId();
  const userReservations = user.reservations || [];
  const [page, setPage] = useState(1);
  const isReserved = selectedProduct && selectedProduct.reservationId;
  const isSelfReserved =
    isReserved &&
    userReservations.find((r) => selectedProduct.reservationId._id === r._id);
  const [totalResults, setTotalResults] = useState(0);
  const [results, setResults] = useState([]);
  const handleProductReserved = (p) => {
    setSelectedProduct(p);
    setResults(
      results.map((r) => (r._id === p._id ? p : r)
    ))
  };
  const { dispatchAlert } = React.useContext(AlertContext);

  useEffect(() => {
    const fetchAccount = async (accountId) => {
      const account = await accountService.get(accountId);
      setAccount(account);
    };
    user.accountId && fetchAccount(user.accountId);
  }, [user]);

  const performSearch = async (query = "", loadMore) => {
    let storeAccountId = "";
    if(storeId) {
      const storeAccount = await accountService.find({
        query: {
          storeId
        }
      });
      storeAccountId = storeAccount.data[0]?._id;
    }
    let queryObj = query
      ? {
        $or: [
          { manufacturer: { $regex: query, $options: "igm" } },
          { brand: { $regex: query, $options: "igm" } },
          { type: { $regex: query, $options: "igm" } },
          { description: { $regex: query, $options: "igm" } },
          { finish: { $regex: query, $options: "igm" } },
          { slabType: { $regex: query, $options: "igm" } }
        ],
      }
      : {};
    //  include any in array, ie: thickness=2,3&colors=white,black
    [colors, finishes, thickness, tones, types, manufacturer, brand].forEach(
      (prop, i) => {
        const names = [
          "colour",
          "finish",
          "thickness",
          "tone",
          "type",
          "manufacturer",
          "brand",
        ];
        const newQuery = prop
          ? {
            [names[i]]: {
              $in: prop.split(","),
            },
          }
          : {};
        queryObj = { ...queryObj, ...newQuery };
      }
    );
    if(slabTypes) {
      if (slabTypes.indexOf("Remnant") > -1) {
        let slabQueryArray = slabTypes.split(",");
        slabQueryArray.push(null)
        const newQuery = {
          $or: [
            {
              "slabType": {
                $in: slabQueryArray
              }
            },
            {"slabType": {$exists: false}}
          ]
        }
        queryObj = {...queryObj, ...newQuery};
      } else {
        const newQuery = {
          "slabType": {
            $in: slabTypes.split(",")
          }
        }
        queryObj = {...queryObj, ...newQuery};
      }
    }
    // Value comparisons
    [minWidth, minLength, minPrice, maxPrice].forEach((prop, i) => {
      const names = ["width", "height", "price", "price"];
      const newQuery = prop
        ? {
          [names[i]]: {
            [i === 3 ? "$lte" : "$gte"]:
              i < 3 && dimensions === "inches" ? inchesToCm(prop) : prop,
          },
        }
        : {};
      queryObj = { ...queryObj, ...newQuery };
    });
    const available = {
      isPublished: true,
      isSold: false,
      $skip: (page - 1) * 20,
      $sort: {
        createdAt: -1
      }
    };
    queryObj = { ...queryObj, ...available };
    if(maxDistance) {
      const distance = {
        maxDistance: parseInt(maxDistance)*1000, //Backend requires maxDistance to be in meters
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude)
      }
      queryObj = {...queryObj, ...distance};
    }

    //If on fabricator storefront, use store id in query
    if(storeAccountId)
      queryObj.accountId = storeAccountId;

    console.log(queryObj)

    const searchRes = await productService.find({ query: queryObj });
    let {data, total} = searchRes;
    if(loadMore) {
      let totalResults = [...results, ...data].reduce(
        (res, data, index, arr) => {
          if (res.findIndex((slab) => slab._id === data._id) < 0) {
            res.push(data);
          }
          return res;
        },
        []
      );
      setResults(totalResults)
    } else {
      setResults(data);
    }
    setTotalResults(total)
  };

  useEffect(() => {
    performSearch(query, true);
  }, [page])

  useEffect(() => {
    performSearch(query);
  }, [
    query,
    dimensions,
    slabTypes,
    colors,
    finishes,
    maxPrice,
    minLength,
    minPrice,
    minWidth,
    thickness,
    tones,
    types,
    manufacturer,
    brand,
    maxDistance,
    latitude,
    longitude
  ]);

  useEffect(() => {
    if (isReserved && !isSelfReserved && !storeId) {
      dispatchAlert({
        type: "open",
        message:
          "You can't reserve this item right now because it has been reserved by someone else.",
        position: "left",
        action: () => {},
      });
    }
    // eslint-disable-next-line
  }, [isReserved, isSelfReserved]);

  return (
    <Screen
      header={
        selectedProduct ? (
            <div className="flex items-center">
              <Icon
                name="arrow-left"
                className="mr-2"
                onClick={() => setSelectedProduct(null)}
              />
              Back to Results
            </div>
          ) : (
            <ResultsHeader
              numResults={totalResults}
              isSelected={selectedProduct}
              clearSelection={() => setSelectedProduct(null)}
            />
          )
      }
      className="SearchResults"
      autopadding={false}
    >
      <div className="flex flex-row">
        <div id="infiniteParent" className={`LeftSide ${selectedProduct ? "hidden md:flex" : "bg-white"}`}>
          <InfiniteScroll
            className="CardGallery"
            dataLength={totalResults-results.length} //This is important field to render the next data
            hasChildren={true}
            next={() => setPage(page + 1)}
            hasMore={results.length < totalResults}
            endMessage={<div className="w-full flex flex-row justify-center items-center py-5">
              <p className="ml-3 text-xl">No more results.</p>
            </div>}
            loader={
              results.length < totalResults && (
                <div className="w-full flex flex-row justify-center items-center py-5">
                  <Icon
                    className="animate-spin text-orange"
                    size={8}
                    name="loading"
                  />
                  <p className="ml-3 text-xl">Loading...</p>
                </div>
              )
            }
            scrollableTarget="infiniteParent"
          >
            {results &&
            results.map((p) => {
              const u = user.preferredUnits;
              const { description } = convertProductUnits(p, u, 0);
              return (
                <Card
                  key={p._id}
                  product={p}
                  image={p.images[0].url}
                  heading={p.type + (p.brand ? " - " + p.brand : "")}
                  subheading={description}
                  onClick={() => setSelectedProduct(p)}
                  bgColor={
                    selectedProduct && selectedProduct._id === p._id
                      ? "orange-200"
                      : "white"
                  }
                />
              );
            })}
          </InfiniteScroll>
        </div>
        <div className={`RightSide ${selectedProduct ? "" : "bg-white hidden md:flex"}`}>
          {selectedProduct ? (
            <>
              <SlabDetails
                storeId={storeId}
                product={selectedProduct}
                setSelectedProduct={handleProductReserved}
              />
            </>
          ) : (
            <Filler type="product" />
          )}
        </div>
      </div>
    </Screen>
  );
};

export default SearchResults;

const ResultsHeader = ({ numResults, isSelected, clearSelection }) => {
  const history = useHistory();

  const LeftHeader = (
    <div className="flex items-center">
      <Icon name="arrow-left" className="mr-2" onClick={history.goBack} />
      <div>{numResults} Products</div>
    </div>
  );

  return (
    LeftHeader
  );
};
