import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {Formik} from 'formik'
import * as yup from 'yup'
import {customEvent, setUserProps} from "../../utils/AnalyticsUtils";

import {Button, ConfirmModal, Input, Screen} from "../../components";
import { app } from "../../services";
import { setUser } from "../../redux/reducers/userReducer";
import logo from "../../assets/images/logo.png";
import slabGrey from "../../assets/images/slab-grey.png";
import appExampleImg5 from "../../assets/images/app_example_5.png";
import { authService } from "../../services";
import Footer from "../Footer";
import SocialButton from "../../components/SocialButton/SocialButton";

const LoginScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [creds, setCreds] = useState({ email: "", password: "" });
  const [err, setErr] = useState(null);
  const [{ modal, modalProduct }, setModalData] = useState({
    modal: null,
    modalProduct: null,
  });
  const setModal = (modal, modalProduct) =>
    setModalData({ modal, modalProduct });
  const emailLogin = () => {
    app
      .authenticate({
        strategy: "local",
        email: creds.email,
        password: creds.password,
      })
      .then((sessionData) => {
        setUserProps(sessionData.user._id, sessionData.user)
        customEvent("user_login")
        dispatch(setUser(sessionData.user));
        history.push("/dashboard");
      })
      .catch((e) => {
        // Show login page (potentially with `e.message`)
        setErr(e.message);
      });
  };

  return (
    <div className="LoginScreen h-screen">
      <div className="flex">
        <img src={slabGrey} alt="grey slab" className="angled-slab-img-login w-24" />
        <div className="py-4 pl-4 w-full">
          <div className="flex items-center justify-between mb-4 pr-8">
            <Link to="/">
              <img src={logo} alt="app logo" className="w-36" />
            </Link>
            <Link to="/signup/type">
              <button
                className="relative rounded border border-orange-400 transition-color duration-100
         hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-3"
              >
                Sign up
              </button>
            </Link>
          </div>
          <div className="bg-white w-full flex flex-row justify-center items-center">
            <div className="px-12 w-full md:w-3/4 lg:w-1/2">
              <div className="text-2xl font-bold mb-4">Please log in below.</div>
              <Input
                onChange={(e) => setCreds({ ...creds, email: e.target.value })}
                onKeyDown={(e) => e.key === "Enter" && emailLogin()}
                placeholder="Email"
                value={creds.email}
                className="w-full mt-2"
              />
              <Input
                onChange={(e) => setCreds({ ...creds, password: e.target.value })}
                onKeyDown={(e) => e.key === "Enter" && emailLogin()}
                type="password"
                value={creds.password}
                placeholder="Password"
                className="mt-1 w-full"
              />
              <div className="inline-block underline cursor-pointer mt-4 mb-3" onClick={() => setModal('FORGOTPASSWORD')}>
                Forgot password?
              </div>
              <Button className="w-full" onClick={emailLogin}>
                Continue
              </Button>
              <div className={err ? `text-red-400 mt-4` : `invisible mt-10`}>
                {err && "Login error.  Are you sure your password is correct?"}
              </div>
              <div className="px-2 my-2 flex items-center">
                <div className={`border-b-2 border-gray-200 w-full my-6`} />
                <div className="text-gray-400 px-2">OR</div>
                <div className={`border-b-2 border-gray-200 w-full my-6`} />
              </div>
              <div className="flex flex-col xl:flex-row flex-wrap w-full justify-center">
                {["Google","Facebook", "Apple"].map(type => (
                  <div className="mx-2">
                    <SocialButton type={type} />
                  </div>
                  ))}
              </div>
            </div>
            <div className="overflow-hidden w-1/2 lg:block hidden">
              <img
                src={appExampleImg5}
                alt="app example"
                className="example-image hero"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {modal === "FORGOTPASSWORD" && (
        <ForgotPasswordModal
          onConfirm={() => {
            setModal(null);
          }}
          onCancel={() => setModal(null)}
        />
      )}
    </div>
  );
};

export default LoginScreen;

const listingSchema = yup.object().shape({
  email: yup.string().label('Email').email().required(),
})

const ForgotPasswordModal = ({ onConfirm, onCancel }) => {
  const [status, setStatus] = useState('none')
  let initialValues = {
    email: ''
  }

  const submitForm = async (values) => {
    setStatus('submitting')
    authService.create({
      action: "sendResetPwd",
      value: {email: values.email}
    }).then(() => {
      customEvent("password_reset_request")
      setStatus('submitted')
    }).catch(() => {
      setStatus('submitted')
    })
  }

  return (<Formik
    initialValues={initialValues}
    validationSchema={listingSchema}
    onSubmit={values => submitForm(values)}
  >
    {props => (
      <>
        <ConfirmModal
          title={status !== "submitted" ? "Forgot Password" : "Email Sent"}
          body={
                <div>
                  {status !== 'submitted' ? "Enter the email address you signed up with and a reset password email will be sent to you." : "A password reset email has been sent to the provided email address."}
                  {status !== "submitted" && (
                    <div className="w-full pr-6">
                      <Input className="w-full" placeholder="Email" value={props.values.email} name="email" onChange={props.handleChange('email')} error={props.touched.email && props.errors.email} />
                    </div>
                  )}
                </div>
          }
          confirmText={status !== "submitted" ? "Submit" : "Ok"}
          icon="email"
          color="blue"
          loading={status === "submitting"}
          onConfirm={() => {
            if(status !== 'submitted')
              props.handleSubmit()
            else
              onCancel()
          }}
          onCancel={status !== "submitted" ? onCancel : null}
        />
      </>
    )}
    </Formik>
  );
};
