import * as React from "react";
import { Alert } from "../components";

const initialState = {
  type: "close",
  open: false,
  message: "",
  label: "OK",
  position: "left",
  duration: 3000,
  error: false,
  confetti: false,
  action: () => {}
};

export const AlertContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case "close":
      return {
        ...state,
        open: false,
        confetti: false,
        error: false
      };
    case "open":
      return {
        ...state,
        ...action,
        open: true
      };
    default:
      throw new Error();
  }
};

export const AlertProvider = ({ children }) => {
  const [alertState, dispatchAlert] = React.useReducer(reducer, initialState);
  return (
    <AlertContext.Provider
      value={{
        alertState,
        dispatchAlert
      }}>
      <>
        {children}
        <Alert />
      </>
    </AlertContext.Provider>
  );
};
