import React, {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { usePosition } from '../../hooks/usePosition';
import {SearchContext} from "../../context/search";

import { Button, Icon, Screen, Select } from "../../components";
import UnitSelector from "../../components/Input/UnitSelector";
import { selectUser } from "../../redux/reducers/userReducer";
import { capitalize } from "../../utils/StringUtils";
import { ModelSearch, MANUFACTURERS } from "../ListingsScreen/EditListing";
import "./SearchScreen.css";
import {accountService} from "../../services";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {customEvent} from "../../utils/AnalyticsUtils";
import useStoreId from "../../hooks/useStoreId";

const SearchScreen = () => {
  const { filters, setFilters, searchUri, resetFilters } = useContext(SearchContext);
  const user = useSelector(selectUser) || {};
  const [account, setAccount] = useState({});

  const history = useHistory();
  const position = usePosition();
  const {storeId} = useStoreId();

  const checkPositionPermission = () => {
    if(position.error === "User denied Geolocation" && !account.latitude && !account.longitude) {
      alert('You must allow geolocation to filter by distance.')
      return false;
    }
    if(position.error === "Geolocation is not supported"){
      alert('Geolocation not supported.')
      return false;
    }
    return true;
  }

  const joinManufacturers = (types) => {
    let manus = ["Any"];
    for (const type in types){
      if(types[type] && MANUFACTURERS[type]){
        manus = [...manus, ...MANUFACTURERS[type]]
      }
    }
    return [...new Set(manus)].sort()
  }

  useEffect(() => {
    const fetchAccount = async (accountId) => {
      const account = await accountService.get(accountId);
      setAccount(account);
    };
    user.accountId && fetchAccount(user.accountId);
  }, [user]);

  return (
    <Screen
      header={
        <div className="flex items-center">
          <Icon
            name="arrow-left"
            className="mr-2"
            onClick={() => history.push(storeId ? "/store/"+storeId : "/dashboard")}
          />
          Search
        </div>
      }
      className="SearchScreen"
    >
      <div className="LeftSide">
        <div className="SearchScreen w-full sm:w-2/5">
          <div className=" sm:p-0 p-4">
            <SlabTypeSelector
              types={filters.slabTypes}
              onChange={(slabTypes) => setFilters({ ...filters, slabTypes })}
            />
            <StoneTypeSelector
              types={filters.types}
              onChange={(types) => setFilters({ ...filters, types })}
            />
            {(filters.types.quartz || filters.types.quartzite || filters.types.granite || filters.types.marble) && (
              <div className="mt-6 ml-4">
                <Select
                  mode="search"
                  label="Manufacturer"
                  value={(filters.manufacturer || "").toLowerCase()}
                  options={joinManufacturers(filters.types).map((v) => ({ value: v, icon: null }))}
                  onChange={(m) => {
                    setFilters({
                      ...filters,
                      manufacturer: m === "Any" ? null : m,
                      brand: null,
                    });
                  }}
                />
                <ModelSearch
                  mode="search"
                  value={filters.brand}
                  listing={{ brand: filters.brand }}
                  onChange={({ brand }) =>
                    setFilters({
                      ...filters,
                      brand: brand === "Any" ? null : brand
                    })
                  }
                  types={["Porcelain", "Quartz"].filter(
                    (t) => filters.types[t.toLowerCase()]
                  )}
                  manufacturer={filters.manufacturer}
                />
              </div>
            )}
            <ToneSelector
              tones={filters.tones}
              onChange={(tones) => setFilters({ ...filters, tones })}
            />
            <FinishSelector
              finishes={filters.finishes}
              onChange={(finishes) => setFilters({ ...filters, finishes })}
            />
            <DimensionSelector
              values={filters}
              onChange={(key, val) =>
                setFilters({ ...filters, [key]: val })
              }
            />
            <PriceSelector
              values={filters}
              onChange={(key, val) =>
                setFilters({ ...filters, [key]: val })
              }
            />
            <DistanceSelector
              values={filters}
              onChange={(key, val) =>
                setFilters({ ...filters, [key]: val })
              }
              checkPositionPermission={checkPositionPermission}
            />
          </div>
          <div className="pt-20"></div>
          <footer className="flex mt-6 px-6 border-t-2 py-6 w-full sm:w-auto">
            <Button
              className="w-full mr-12"
              type="secondary"
              onClick={() => resetFilters}
            >
              Clear All
            </Button>
            <Button
              className="w-full"
              onClick={() => {
                history.push("/search-results" + (storeId ? "/"+storeId : "") + searchUri);
                customEvent("search_products", {searchUri})
              }}
            >
              Search
            </Button>
          </footer>
        </div>
      </div>
    </Screen>
  );
};

export default SearchScreen;

const CheckSection = ({ label, fields, onChange = () => {} }) => {
  const [fieldVals, setFieldVals] = useState(fields);

  const toggleField = (f) => {
    const mergedFields = { ...fieldVals, [f]: !fieldVals[f] };
    setFieldVals(mergedFields);
    onChange(mergedFields);
  };

  useEffect(() => {
    setFieldVals(fields);
  }, [fields]);

  return (
    <div className="flex flex-col pt-2">
      {Object.keys(fieldVals).sort().map((f, i) => (
        <label
          key={i}
          className="inline-flex items-center justify-between w-2/5nvm
            hover:bg-orange-100 rounded-lg p-2 cursor-pointer checkbox-label"
        >
          <span className="ml-2 text-gray-700">{capitalize(f)}</span>
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-orange"
            checked={fieldVals[f]}
            onChange={() => toggleField(f)}
          />
        </label>
      ))}
    </div>
  );
};

const SlabTypeSelector = ({ onChange, types }) => (
  <SearchSection label="SLAB TYPE">
    <CheckSection fields={types} onChange={onChange} />
  </SearchSection>
);

const StoneTypeSelector = ({ onChange, types }) => (
  <SearchSection label="TYPE">
    <CheckSection fields={types} onChange={onChange} />
  </SearchSection>
);

const ToneSelector = ({ onChange, tones }) => (
  <SearchSection label="TONE">
    <CheckSection fields={tones} onChange={onChange} />
  </SearchSection>
);

const FinishSelector = ({ onChange, finishes }) => (
  <SearchSection label="FINISH">
    <CheckSection fields={finishes} onChange={onChange} />
  </SearchSection>
);

const DimensionSelector = ({ onChange, values }) => (
  <SearchSection label="DIMENSIONS">
    <UnitSelector
      units={values.dimensionUnits}
      setUnits={(u) => {
        onChange("dimensionUnits", u);
      }}
      className="pl-2"
    />
    <div className="flex justify-between mt-6">
      <div className="pl-2">
        <div className="text-lg font-semibold">Minimum Width</div>
        <input
          placeholder="1"
          type="number"
          className="my-2"
          value={values.minWidth}
          onChange={(e) => onChange("minWidth", e.target.value)}
        />
        <div className="text-lg font-semibold">Minimum Length</div>
        <input
          placeholder="1"
          type="number"
          className="my-2"
          value={values.minLength}
          onChange={(e) => onChange("minLength", e.target.value)}
        />
      </div>
      <div>
        <div className="text-lg font-semibold">Thickness</div>
        <CheckSection
          fields={values.thickness}
          onChange={(val) => onChange("thickness", val)}
        />
      </div>
    </div>
  </SearchSection>
);

const convertToDistance = (input) => {
  if(input < 20)
    return input * 10;
  else if(input >= 20 && input < 35)
    return (input - 20)*20+200;
  else if(input >= 35 && input < 45)
    return (input - 35)*50+500;
  else if(input >= 45 && input < 55)
    return (input - 45)*100+1000;
  else if(input >= 55 && input < 70)
    return (input - 55)*200+2000;
  else if(input >= 70 && input <= 80)
    return (input - 70)*500+5000;
}

const convertFromDistance = (distance) => {
  if(distance <= 200)
    return distance/10
  else if(distance > 200 && distance <= 500)
    return (distance-200)/20+20;
  else if(distance > 500 && distance <= 1000)
    return (distance-500)/50+35
  else if(distance > 1000 && distance <= 2000)
    return (distance-1000)/100+45
  else if(distance > 2000 && distance <= 5000)
    return (distance-2000)/200+55
  else if(distance > 5000 && distance <= 10000)
    return (distance-5000)/500+70
}

const DistanceSelector = ({onChange, values, checkPositionPermission}) => (
  <SearchSection label="DISTANCE" noBorder headerRight={<label
      className="ml-4 inline-flex items-center justify-between w-2/5nvm
            hover:bg-orange-100 rounded-lg p-2 cursor-pointer checkbox-label"
    >
      <span className="ml-2 text-gray-700 text-sm mr-2">Filter by Distance</span>
      <input
        type="checkbox"
        className="form-checkbox h-4 w-4 text-orange"
        checked={values.sortDistance}
        onChange={(e) => {
          if(checkPositionPermission())
            onChange("sortDistance", !values.sortDistance)
        }}
      />
    </label>}>
    <div className="pl-2 mt-4 flex flex-row items-center justify-between">
      <div className="text-lg font-semibold">Maximum Distance</div>
      <div className="relative items-center">
        <span className="absolute right-4 top-5 text-gray-500">km</span>
        <input
          disabled={!values.sortDistance}
          placeholder="1"
          type="number"
          className={`my-2 ${!values.sortDistance && "opacity-60"}`}
          value={values.maxDistance}
          onChange={(e) => onChange("maxDistance", e.target.value)}
        />
      </div>
    </div>
    <div className={`p-4 ${!values.sortDistance && "opacity-60"}`}>
      <Slider
        disabled={!values.sortDistance}
        value={convertFromDistance(values.maxDistance)}
        max={80}
        min={0}
        handleStyle={[{ backgroundColor: "white", borderColor: "#f2421b" }]}
        trackStyle={[{backgroundColor: '#f2421b'}]}
        onChange={(value) => onChange("maxDistance", convertToDistance(value))} />
    </div>
  </SearchSection>
)

const PriceSelector = ({ onChange, values }) => (
  <SearchSection label="PRICE" noBorder>
    <div className="pl-2 mt-4">
      <div className="text-lg font-semibold">Minimum Price</div>
      <input
        placeholder="1"
        type="number"
        className="my-2"
        value={values.minPrice}
        onChange={(e) => onChange("minPrice", e.target.value)}
      />
      <div className="text-lg font-semibold">Maximum Price</div>
      <input
        placeholder="1"
        type="number"
        className="my-2"
        value={values.maxPrice}
        onChange={(e) => onChange("maxPrice", e.target.value)}
      />
    </div>
  </SearchSection>
);

const SearchSection = ({ label = "", noBorder = false, children, headerRight }) => (
  <div className={`SearchSection ${noBorder ? "" : "border-b-2"} py-6`}>
    <div className="flex flex-row items-center justify-between">
      <h2 className="text-lg font-bold">{label}</h2>
      {headerRight && headerRight}
    </div>
    {children}
  </div>
);
