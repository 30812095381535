const capitalize = (str) =>
  str ? str.charAt(0).toUpperCase() + str.substring(1) : "";

const camelCase = (str) => {
  if (str.includes(" ")) {
    str = str
      .toLowerCase()
      .replace(/[^A-Za-z0-9]/g, " ")
      .split(" ")
      .reduce((result, word) => result + capitalize(word.toLowerCase()));
  }
  return str.charAt(0).toLowerCase() + str.slice(1);
};

const camelToTitle = (camelCase) =>
  camelCase
    .replace(/([A-Z]|[^a-z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());

export { camelCase, camelToTitle, capitalize };
