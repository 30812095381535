import React, { useState } from "react";
import useChat from "../hooks/useChat";
import { useLocation } from "react-router-dom";

const useScreenTabs = (tabs = [], onChange, className) => {
  const [activeTab, setActiveTab] = useState(0);
  const { pathname } = useLocation();
  const { unreadCount } = useChat(
    pathname.includes("/messages") ? false : 30 * 1000
  );

  const TabJsx = (
    <div className={`ScreenTabs flex text-xl font-semibold ${className}`}>
      {tabs.map((t, i) => (
        <div
          className={`cursor-pointer relative pt-2 px-2 pb-1 mr-2 ${
            activeTab === i ? "border-b-2 border-orange" : "text-gray-400"
          }`}
          key={i}
          onClick={() => {
            setActiveTab(i);
            onChange && i !== activeTab && onChange(i);
          }}
        >
          {unreadCount[t === "Buying from" ? "buying" : "selling"] > 0 && (
            <div
              className="absolute -right-3 top-0 flex ml-3 flex-shrink-0 h-5 w-5 rounded-xl
                  bg-orange-300 text-white items-center justify-center"
            >
              <span className="pr-px text-sm">{unreadCount[t === "Buying from" ? "buying" : "selling"]}</span>
            </div>
          )}
          {t}
        </div>
      ))}
    </div>
  );

  return [activeTab, TabJsx, setActiveTab];
};

export default useScreenTabs;
