import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {Formik} from 'formik'
import * as yup from 'yup'
import { useMultiQuery } from "../../hooks/useQuery";

import {Button, Input} from "../../components";
import Footer from "../Footer"
import logo from "../../assets/images/logo.png";
import slabGrey from "../../assets/images/slab-grey.png";
import { authService } from "../../services";

const initialValues = {
  newPassword: '',
  confirmPassword: ''
}

const passwordSchema = yup.object().shape({
  newPassword: yup.string().label('New Password').required().min(8, "Password must be a minimum of 8 characters."),
  confirmPassword: yup.string().label('Confirm New Password').oneOf([yup.ref('newPassword'), null], 'Passwords must match').required(),
})

const ResetPasswordScreen = () => {
  const history = useHistory();
  const [status, setStatus] = useState('none');
  const {token} = useMultiQuery(["token"]);
  const resetPassword = (values) => {
    setStatus('submitting')
    authService.create({
      action: "resetPwdLong",
      value: {
        token,
        password: values.newPassword
      }
    }).then(() => {
      setStatus('submitted')
    }).catch(() => {
      setStatus('err')
    })
  };

  return (
    <div className="LoginScreen h-screen flex flex-col">
      <div className="flex flex-grow flex-row">
        <img src={slabGrey} alt="grey slab" className="angled-slab-img-login w-24 hidden sm:block" />
        <div className="py-4 pl-4 w-full">
          <div className="flex items-center justify-between mb-4 pr-8">
            <Link to="/">
              <img src={logo} alt="app logo" className="w-36" />
            </Link>
          </div>
          {status === "submitted" ? (
            <div className="bg-white w-full h-3/5 flex flex-row justify-center items-center">
              <div className="px-12 w-1/2 flex-1">
                <div className="text-2xl font-bold mb-4">Password Reset</div>
                <div className="text-mm mb-1">Your password has been reset!</div>
                <div className="text-mm mb-4">You may now log in with your new password.</div>
                <Link to="/login">
                  <button
                    className="relative rounded border border-orange-400 transition-color duration-100
           hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-3"
                  >
                    Log in
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={passwordSchema}
              onSubmit={values => resetPassword(values)}
            >
              {props => (
                <>
                  <div className="w-full h-4/5 flex flex-row justify-start items-center">
                    <div className="px-12 w-full md:w-2/3 lg:w-3/4 xl:w-1/2">
                      <div className="text-2xl font-bold mb-4">Enter a new password.</div>
                      <div className="my-4">
                        <Input
                          onChange={props.handleChange('newPassword')}
                          value={props.values.newPassword}
                          name="newPassword"
                          type="password"
                          placeholder="New Password"
                          className="w-full"
                          error={props.touched.newPassword && props.errors.newPassword}
                        />
                      </div>
                      <div className="mb-4">
                        <Input
                          onChange={props.handleChange('confirmPassword')}
                          value={props.values.confirmPassword}
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          className="w-full"
                          error={props.touched.confirmPassword && props.errors.confirmPassword}
                        />
                      </div>
                      <Button disabled={status === "submitting"} loading={status === "submitting"} className="w-full" onClick={props.handleSubmit}>
                        Reset Password
                      </Button>
                      <div className={status === "error" ? `text-red-400 mt-4` : `invisible mt-10`}>
                        {status === "error" && "Reset password error. Please try again."}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordScreen;
