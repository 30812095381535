import React from "react";

const UnitSelector = ({ units, setUnits, className = "" }) => (
  <div className={`pt-4 flex justify-between ${className}`}>
    <div className="mr-4 text-lg font-semibold">Units:</div>
    <div className="">
      <label className="inline-flex items-center mr-4 cursor-pointer">
        <input
          type="radio"
          className="form-radio h-5 w-5 text-orange"
          checked={units === "inches"}
          onChange={() => setUnits("inches")}
        />
        <span className="ml-2 text-gray-700">Inches</span>
      </label>
      <label className="inline-flex items-center ">
        <input
          type="radio"
          className="form-radio h-5 w-5 text-orange cursor-pointer"
          checked={units === "centimeters"}
          onChange={() => setUnits("centimeters")}
        />
        <span className="ml-2 text-gray-700">Centimeters</span>
      </label>
    </div>
  </div>
);

export default UnitSelector;
