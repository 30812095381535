import {useState, useEffect} from 'react';
import {coordsDistance} from "../utils/GeocodeUtils";
import {usePosition} from './usePosition'
import { useSelector } from "react-redux";
import {selectFabricator} from "../redux/reducers/userReducer";

const useDistance = (productCoords) => {
  const [distance, setDistance] = useState(null);
  const position = usePosition()
  const fabricator = useSelector(selectFabricator) || {};

  useEffect(() => {
    async function calculateDistance() {
      if (!productCoords) return;

      let coords = null;
      let account;

      if(position.latitude && position.longitude && !account)
        coords = {latitude: position.latitude, longitude: position.longitude}
      else if(fabricator.latitude && fabricator.longitude)
        coords = {latitude: fabricator.latitude, longitude: fabricator.longitude}

      if(productCoords.latitude && productCoords.longitude && coords)
        setDistance(coordsDistance(productCoords.latitude, productCoords.longitude, coords.latitude, coords.longitude))
      else
        setDistance(null)
    }
    calculateDistance();
    // eslint-disable-next-line
  }, [productCoords, position, fabricator]);

  return distance;
}

export default useDistance
