import React from "react";
import Icon from "../Icon/Icon";

const ScreenHeader = ({
  isSelected,
  clearSelection,
  titles = [],
  className,
}) => {
  return (
    <div className={`ResultsHeader flex w-full items-center ${className}`}>
      <div className="flex w-full justify-between">{titles[0]}</div>
      <div className="flex w-full justify-between px-6">
        {isSelected && (
          <>
            <div className="">{titles[1]}</div>
            <Icon name="x" className="" onClick={clearSelection} />
          </>
        )}
      </div>
    </div>
  );
};

export default ScreenHeader;
