import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useScreenTabs from "../../hooks/useScreenTabs";
import { convertProductUnits } from "../../utils/UnitUtils";
import { selectUser } from "../../redux/reducers/userReducer";
import { productService } from "../../services";
import {
  Button,
  FavoriteButton,
  Filler,
  Icon,
  Screen,
  ScreenHeader,
} from "../../components";
import {
  setActiveConvoId,
  selectMessages,
  selectActiveConvo,
  fetchMessages,
  updateLastSeenAt,
  sendMessage,
  fetchConversations,
} from "../../redux/reducers/chatReducer";
import useChat from "../../hooks/useChat";
import { useQuery } from "../../hooks/useQuery";
import imageRequest from "../../utils/ImageUtils";
import "./ChatScreen.css";
import {hasTouch} from "../../utils/UIUtils";
import SlabDetails from "../../components/panels/SlabDetails";
import { customEvent} from "../../utils/AnalyticsUtils";

const ChatScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser) || {};
  const history = useHistory();
  const { conversationId } = useParams();
  const productId = useQuery().get("productId");
  const newConversation = getNewConversation(productId);
  let activeConversation = useSelector(selectActiveConvo);
  if (conversationId === "new") {
    activeConversation = newConversation;
  }
  const messages = useSelector(selectMessages);
  const {
    conversations,
    unreadCount: { buying, selling },
    unreadConvos,
  } = useChat(15 * 1000);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getTab = (txt, val) => `${txt}${val ? ` (${val})` : ""}`;
  const [activeTab, TabJsx, setActiveTab] = useScreenTabs(
    [getTab(`Buying from`, buying), getTab(`Selling to`, selling)],
    () => {
      setConvo(null);
      history.push("/messages");
    }
  );
  const setConvo = (conversation) => {
    if (conversation) {
      const userData =
        conversation.participantData.find(({ _id }) => _id._id === user._id) || {};
      setActiveTab(conversation.accountId === user.accountId ? 1 : 0);
      const lastMsg = messages[messages.length - 1] || {};
      if (
        conversation.lastMessageId &&
        (!activeConversation ||
          lastMsg.body !== conversation.lastMessageId.body)
      ) {
        dispatch(fetchMessages(conversationId));
        dispatch(updateLastSeenAt(conversation, user._id));
      }
      if(!userData.lastSeenAt)
        dispatch(updateLastSeenAt(conversation, user._id, true));
    }
    dispatch(setActiveConvoId(conversation ? conversation._id : null));
  };

  useEffect(
    () => {
      conversationId === "new"
        ? dispatch(setActiveConvoId("new"))
        : setConvo(conversations.find((c) => c._id === conversationId));
    },
    [conversationId, conversations] // eslint-disable-line
  );

  const filteredConversations = conversations.filter((c) =>
    c.accountId === user.accountId ? activeTab === 1 : activeTab === 0
  );

  if (activeTab === 0 && conversationId === "new") {
    // clicked from non reserved product, check if we already have a
    // convo for that product, if not open the new msg screen
    const existingConvo = filteredConversations.find(
      (c) => c.productId === productId
    );
    existingConvo
      ? history.push(`/messages/${existingConvo._id}`)
      : filteredConversations.unshift(newConversation);
  }

  return (
    <Screen
      header={
        <ScreenHeader
          titles={["Messages", selectedProduct !== null ? "Product" : "Conversation"]}
          isSelected={activeConversation}
          clearSelection={() => {
            if(selectedProduct !== null)
              setSelectedProduct(null)
            else
              setConvo(null)
          }}
        />
      }
      className="ChatScreen"
      autopadding={false}
    >
      <div className="flex flex-1">
        <div className="LeftSide Messages-conversations pt-4 flex flex-col">
          {TabJsx}
          <div className="pt-3 pr-8">
            {filteredConversations.length ? (
              filteredConversations.map((c, i) => (
                <span key={i}>
                  <ConversationListItem
                    key={i}
                    conversation={c}
                    hasUnread={unreadConvos.includes(c._id)}
                    onClick={() => {
                      console.log('clicking')
                      setSelectedProduct(null)
                      setConvo(c)
                    }}
                    className={
                      activeConversation && activeConversation._id === c._id
                        ? "bg-orange-200"
                        : ""
                    }
                  />
                  {i < conversations.length - 1 && (
                    <div className="px-2">
                      <div className="border-b-2 border-gray-200 my-1" />
                    </div>
                  )}
                </span>
              ))
            ) : (
              <h2 className="mt-4 ml-2">No conversations yet</h2>
            )}
          </div>
        </div>
        {activeConversation ? (
          <div className="RightSide Messages-chat flex flex-col w-full">
            {selectedProduct ? (
                <SlabDetails
                  noButtons
                  product={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
              ) : (
                <Chat setSelectedProduct={setSelectedProduct} conversation={activeConversation} messages={messages} />
              )}
          </div>
        ) : (
          <div className="Messages-chat flex justify-center items-center">
            <Filler type="message" />
          </div>
        )}
      </div>
    </Screen>
  );
};

export default ChatScreen;

const ConversationListItem = ({ conversation, hasUnread, className,onClick }) => {
  const user = useSelector(selectUser) || {};
  const lastMessage = conversation.lastMessageId || { body: "" };
  const contact =
    (conversation.participantData || []).find((p) => p._id !== user._id) || {};
  let title = `${contact.firstName ? `${contact.firstName} - ` : ""}${
    conversation.productTitle
  }`;

  return (
    <Link
      to={`/messages/${conversation._id}`}
      onClick={onClick}
      className={`ConversationListItem flex justify-between w-full p-4 
    ${className} ${!hasTouch() && "hover:bg-orange-100"} cursor-pointer rounded-lg`}
    >
      <div className="flex-col">
        <div className="font-semibold flex items-center">
          <div>{title}</div>
          {hasUnread && (
            <div className="ml-2 flex-shrink-0 h-4 w-4 rounded-xl bg-orange-300" />
          )}
        </div>
        <div className="">
          {lastMessage.body
            ? lastMessage.body.substring(0, 40)
            : "Write a message to get started.."}
        </div>
      </div>
      <div className="text-gray-500">
        {moment(lastMessage.createdAt || conversation.createdAt).fromNow()}
      </div>
    </Link>
  );
};

const Chat = ({ conversation, messages, className, setSelectedProduct }) => {
  const [messageInput, setMessageInput] = useState("");
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser) || {};
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const objDiv = document.getElementById("message-box");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [messages.length]);
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const product = await productService.get(conversation.productId);
        setProduct(product);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.className === "not-found") setProduct("not found");
      }
    };
    fetchProduct();
  }, [conversation.productId]);

  const getName = (id) => {
    let participantData = conversation.participantData;
    if (typeof participantData[0]._id !== "object") return "";
    let participant = participantData.filter((p) => p._id._id === id)[0]._id;
    return participant.firstName + " " + participant.lastName;
  };

  const sendMsg = async () => {
    const message = {
      conversationId: conversation._id,
      sentBy: user._id,
      body: messageInput,
    };
    if (conversation._id === "new") {
      const sentMessage = await dispatch(
        sendMessage(message, conversation, product)
      );
      const newConvoId = sentMessage.conversationId;
      dispatch(fetchConversations([...user.conversations, newConvoId]));
      history.push(`/messages/${newConvoId}`);
      customEvent('new_conversation', {...conversation, ...product, ...message})
    } else {
      dispatch(sendMessage(message, conversation, product));
      customEvent('send_message', {productId: product._id, ...message})
    }

    setMessageInput("");
  };

  return (
    <div className={`Chat flex-1 ${className}`}>
      <SlabDropdown loading={loading} product={product} setSelectedProduct={setSelectedProduct}/>
      <div className="Chat-messages pt-2" id="message-box">
        {conversation.lastMessageId && messages ? (
          messages.map((m, i) => (
            <div
              className={`Message my-2 p-4 rounded-lg w-4/5 float-${
                m.sentBy === user._id ? "right" : "left bg-white"
              }`}
              key={i}
            >
              {(!messages[i - 1] || m.sentBy !== messages[i - 1].sentBy) && (
                <div className="font-semibold">{getName(m.sentBy)}</div>
              )}
              <div className="">{m.body}</div>
              <div className="text-gray-500 text-right">
                {moment(m.createdAt).fromNow()}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-600 text-lg mt-4">
            This is the beginning of your conversation
          </div>
        )}
        <div className="invisible">
          DO NOT DELETE. This is ghetto AF, but fixes a bug. Without full width
          inner content, the chatbox won't fill the screen, can't figure why
          right now, but I'm done wasting time with it
        </div>
      </div>
      <div className="Chat-send w-full p-6 px-12">
        <div className="relative rounded-md shadow-sm ml-2 w-full">
          <input
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && messageInput) sendMsg();
            }}
            value={messageInput}
            placeholder="Write a message..."
            className="Chat-textbox pr-12 hidden md:inline focus:shadow-lg w-full"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <Icon
              name="chat"
              color="#f2421b"
              onClick={() => messageInput && sendMsg()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SlabDropdown = ({ product = {}, loading, setSelectedProduct }) => {
  const [shown, setShown] = useState(false);
  const user = useSelector(selectUser) || {};
  const { description } = convertProductUnits(product, user.preferredUnits, 0);
  const imageSrc =
    product && product.images && product.images[0] && product.images[0].url;

  return (
    <div
      className="SlabDropdown  flex-col Chat-header bg-white shadow-lg h-full pr-8
       w-full flex items-center"
    >
      {loading ? (
        <div className="h-20">
          <Icon className="animate-spin text-orange" size={8} name="loading" />
        </div>
      ) : (
        <>
          {product !== "not found" ? (
            <>
              <div
                className="SlabDropdown-header cursor-pointer flex w-full justify-between py-3"
                onClick={() => {
                  if (product && !product.isSold) setShown(!shown);
                }}
              >
                <div className="flex">
                  {product && product.isSold && (
                    <div className="flex w-full h-20 absolute bg-white bg-opacity-75 justify-center items-center flex-row">
                      <div className="p-2 bg-white rounded">
                        <p className="text-red-500 text-5xl">SOLD</p>
                      </div>
                    </div>
                  )}
                  <img
                    src={imageRequest(imageSrc, 800)}
                    alt=""
                    className="rounded-lg h-20 w-20 ml-6 mr-3 object-cover"
                  />
                  <div className="flex-col">
                    <div className="font-semibold text-xl">
                      {product &&
                      product.type +
                      (product.brand ? " - " + product.brand : "")}
                    </div>
                    <div className="text-xl">{description}</div>
                  </div>
                </div>
                <Icon name="chevron-down" className="mb-2" size={6} />
              </div>
              {shown && (
                <div className="Message-dropdown w-full rounded-b-lg">
                  <div className="px-8 pt-4 pb-5 flex items-center">
                    <div className="relative  w-1/2 mr-6 2xl:block hidden">
                      <img
                        src={imageRequest(imageSrc, 800)}
                        alt="Slab"
                        className="RightPanel-large-image rounded-2xl bg-cover mx-auto w-full"
                      />
                      <div className="absolute top-0 right-0 pt-4 pr-6">
                        <FavoriteButton product={product} />
                      </div>
                    </div>
                    <div className="w-full pl-1 pt-4">
                      <div className="w-full flex flex-row justify-between items-center">
                        <h2 className="text-2xl font-semibold">
                          {product &&
                            product.type +
                              (product.brand ? " - " + product.brand : "")}
                        </h2>
                        <Button
                          type="secondary"
                          className="w-44 ml-4"
                          onClick={() => setSelectedProduct(product)}
                        >
                          View Listing
                        </Button>
                      </div>
                      <h4 className="text-xl font-semibold mt-4">
                        Item Details
                      </h4>
                      <ul>
                        <li>{description}</li>
                        <li>{product.description}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="flex w-full flex-row items-center justify-center h-full px-4">
              <div className="w-16 flex justify-center items-center">
                <Icon
                  className="mr-3"
                  name="exclamation"
                  size="md"
                  color="red"
                />
              </div>
              <p className="font-semibold text-sm xl:text-lg">
                This product has been removed and is not available for sale.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const getNewConversation = (productId) => ({
  accountId: null,
  participantData: [],
  participantIds: [],
  productId,
  productTitle: "New Conversation",
  _id: "new",
});
