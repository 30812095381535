import React from "react";

import Modal from "./Modal";
import Icon from "../Icon/Icon";

const ConfirmModal = ({
  title = "",
  body = "Are you sure you want to do this?",
  customBody = null,
  confirmText = "I'm Sure",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  color = "red",
  icon = "exclamation",
  loading = false,
  disabled = false,
  standardModal = false
}) => (
  <Modal title={title} customBody={customBody} body={body} iconColor={color} icon={icon} standardModal={standardModal}>
    <button
      type="button"
      disabled={loading || disabled}
      className={`${(loading || disabled) ? `bg-gray-300 text-gray-700 cursor-default` : `text-white bg-${color}-500 hover:bg-${color}-600`} w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 sm:ml-3 sm:w-auto sm:text-sm `}
      onClick={onConfirm}
    >
      {loading && <Icon className="animate-spin absolute" size={6} name="loading" />}
      {confirmText}
    </button>
    {onCancel && (
      <button
        type="button"
        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={onCancel}
      >
        {cancelText}
      </button>
    )}
  </Modal>
);

export default ConfirmModal;
