const cmToInches = (cm = 0, decimalPlaces = 2) =>
  format(parseFloat(cm) / 2.54, decimalPlaces);

const inchesToCm = (inches = 0, decimalPlaces = 2) =>
  format(parseFloat(inches) * 2.54, decimalPlaces);

// product can be either 1 or a list [p1,p2]
const convertProductUnits = (product, units = "inches", decimals = 2) => {
  if (!product) return {};
  const u = units === "inches" ? '"' : "cm";
  const width =
    u === "cm"
      ? format(product.width, decimals)
      : format(cmToInches(product.width), decimals);
  const height =
    u === "cm"
      ? format(product.height, decimals)
      : format(cmToInches(product.height), decimals);
  const description = `${width}${u} W x ${height}${u} L x ${product.thickness}cm Thick`;

  return { description, units, width, height };
};

export { convertProductUnits, cmToInches, inchesToCm };

// prevents numbers like 23.00, inner parse float casts string to num
const format = (num = 0, decimals) =>
  parseFloat(parseFloat(num).toFixed(decimals));
