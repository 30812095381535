import React from 'react';

const Footer = () => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-center sm:h-20 w-full bg-orange sm:px-32 px-12 sm:py-0 py-2">
      <div className="w-full sm:w-1/2 lg:w-1/3 sm:my-0 my-4">
        <div>
          <p className="text-white">220-3689 E 1st Ave, Vancouver BC</p>
          <p className="text-white">V5M 1C2</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/3 sm:my-0 my-4">
        <div>
          <a className="text-white underline" href="mailto:info@slabxchange.com">info@slabxchange.com</a>
          <p className="text-white">778-322-6554</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
