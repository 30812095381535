const imageRequest = (image_path, width) => {
  return `https://d3d9si4if26yfm.cloudfront.net/${
    window.btoa(
      JSON.stringify({
        bucket: process.env.REACT_APP_ENV === 'production' ? 'sxc-prod' : 'sxc-test',
        key: decodeURI(image_path),
        edits: {
          resize: { 
            width: width
          }
        }
      })
    )
  }`
};

export default imageRequest;
