import React, {useState} from "react";
import {Formik} from 'formik'
import * as yup from 'yup'
import createYupSchema from '../../createYupSchema';
import {fabricatorAdminService} from "../../services";
import { Link } from "react-router-dom";

import {Input, Button, ConfirmModal} from "../../components";
import { capitalize } from "../../utils/StringUtils";
import appExampleImg2 from "../../assets/images/app_example_2.png";
import ServiceAgreement from "../../components/ServiceAgreement/ServiceAgreement";

const SignUpPassword = ({signUpData = {} }) => {
  const [{ modal, modalData }, setModalData] = useState({
    modal: null,
    modalData: null,
  });
  const setModal = (modal, modalData) =>
    setModalData({ modal, modalData });
  const [submitting, setSubmitting] = useState(false)
  const [completed, setCompleted] = useState(false)
  let formData = {...signUpData.password, email: signUpData.companyInformation.email}
  const fields = [
    { field: "email", displayName: "Email", type: "email", validationType: "string", validations: [{type: "required", params: ["This field is required."]}] },
    { field: "password", displayName: "Password", type: "password", validationType: "string", validations: [{type: "required", params: ["This field is required."]}, {type: "min", params: [8, "Password must be 8 or more characters."]}] },
    { field: "confirmPassword", displayName: "Confirm Password", type: "password", validationType: "string", validations: [{type: "required", params: ["This field is required."]}, {type: "oneOf", params: [[yup.ref('password'), null], "Passwords must match."]}] },
    {field: "serviceAgreement", displayName: "Service Agreement", type: 'checkbox', validationType: "boolean", validations: [{type: "oneOf", params: [[true], "You must accept the service agreement."]}]}
  ];

  const yepSchema = fields.reduce(createYupSchema, {});
  const validateSchema = yup.object().shape(yepSchema);


  const renderFormInputs = props => (
    fields.map((f, i) => {
      let error = props.errors.hasOwnProperty(f.field) && props.errors[f.field];
      let touched = props.touched.hasOwnProperty(f.field) && props.touched[f.field];

      return (
        <div className={`mt-1 w-full`} key={i}>
          <div
            className={`${
              f.pos === "first" ? "mr-2" : f.pos === "second" && "ml-2"
            }`}
          >
            {f.type === "checkbox" ? (
              <div className="w-full flex flex-col justify-center items-center m-4">
                <div key={i} className="w-full flex flex-row justify-center items-center">
                  <input
                    type="checkbox"
                    className="h-5 w-5 text-orange mr-4"
                    checked={props.values[f.field]}
                    onClick={() => props.setFieldValue("serviceAgreement", !props.values.serviceAgreement)}
                  />
                  <p>I agree to the SlabXChange <span className="pl-2 text-blue-500 hover:text-blue-900 cursor-pointer" onClick={() => setModal("SERVICEAGREEMENT")}>General Service Agreement</span></p>
                </div>
                {touched && error && <p className="h-2 text-orange text-sm mt-1 ml-1">{error}</p>}
              </div>
            ) : (
              <Input
                key={i}
                value={props.values[f.field]}
                onChange={props.handleChange(f.field)}
                placeHolder={f.displayName || capitalize(f.field)}
                type={f.type || null}
                error={touched && error}
              />
            )}
          </div>
        </div>
      )
    })
  )

  const initialValues = {}
  fields.forEach(f => {
    if(f.type === "checkbox")
      initialValues[f.field] = false;
    else
      initialValues[f.field] = formData[f.field] || ""
  })

  const handleSubmit = async (values) => {
    setSubmitting(true)
    const response = await fabricatorAdminService.create({
      email: values.email,
      password: values.password,
      firstName: signUpData.companyInformation.firstName,
      lastName: signUpData.companyInformation.lastName,
      accountId: signUpData.paymentInformation.accountId,
      agreedAt: Date.now(),
      agreedVersion: 'V1'
    })
    setSubmitting(false)
    if(response._id){
      setCompleted(true);
    }
  }

  return (
    <div>
      <div className="flex flex-col md:items-center  md:flex-row md:mt-10">
        {completed ? (
          <div className="p-4 md:w-1/2">
            <p className="text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
              Thank you for registering!
            </p>
            <p className="my-4 text-lg leading-normal tracking-tight text-gray-900 hidden md:block">
              You may now log in to your account.
            </p>
            <p className="my-4 text-lg leading-normal tracking-tight text-gray-900 md:hidden">
              You can now log in to your account on your desktop.
            </p>
            <div className="flex hidden md:block">
              <Link to="/login">
                <button
                  className="relative rounded border border-orange-400 transition-color duration-100
         hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-3"
                >
                  Log In Now
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="p-4 md:w-1/2">
            <p className="text-2xl font-bold leading-normal tracking-tight text-gray-900 sm:text-2xl">
              Step 4/4
            </p>
            <p className="my-4 text-lg leading-normal tracking-tight text-gray-900">
              Set a password
            </p>
            <div className="flex flex-row justify-between flex-wrap">
              <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={values => handleSubmit(values)}
              >
                {(props) => (
                  <>
                    {renderFormInputs(props)}
                    <Button
                      className="w-full mt-2"
                      disabled={submitting}
                      loading={submitting}
                      onClick={props.handleSubmit}
                    >
                      Create Account
                    </Button>
                    {modal === "SERVICEAGREEMENT" && (
                      <ConfirmModal
                        title="General Service Agreement"
                        customBody={
                          <div className="flex w-full overflow-y-auto mt-8" style={{height: "65vh"}}>
                            <ServiceAgreement client={signUpData} />
                          </div>
                        }
                        confirmText="Agree"
                        onConfirm={() => {
                          props.setFieldValue("serviceAgreement", true)
                          setModal(null);
                        }}
                        onCancel={() => setModal(null)}
                      />
                    )}
                  </>
                )}
              </Formik>
            </div>
          </div>
        )}
        <div className="overflow-hidden justify-self-center w-4/5  md:w-1/2 hidden md:block">
          <img
            src={appExampleImg2}
            alt="app example"
            className="example-image w-11/12 md:mr-16 md:w-2/3"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpPassword;
