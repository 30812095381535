import React, {useRef, useEffect, useState} from "react";
import { Link, useHistory  } from "react-router-dom";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import appExampleImg3 from "../../assets/images/app_example_3.png";
import appExampleImg4 from "../../assets/images/app_example_4.png";
import appExampleImg6 from "../../assets/images/app_example_6.png";
import appExampleImg7 from "../../assets/images/app_example_7.png";
import environmentalGraphic from "../../assets/images/environmental-graphic.png";
import brandLogos from "../../assets/images/brand-logos/brands"
import logo from "../../assets/images/logo.png";
import slabGrey from "./slab_grey.png";
import "./landing.css";
import Footer from "../Footer";
import { Card, Icon } from '../../components';
import {fetchHomeFeed, selectHomeFeed} from "../../redux/reducers/productsReducer";
import {useDispatch, useSelector} from "react-redux";
import {convertProductUnits} from "../../utils/UnitUtils";
import {customEvent} from "../../utils/AnalyticsUtils";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const LandingScreen = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null)
  const [randomProducts, setRandomProducts] = useState([])
  let history = useHistory ();
  const {width, height} = useWindowDimensions();
  const routeChange = (path, params) =>{
    history.push(path, params);
  }

  useEffect(() => dispatch(fetchHomeFeed(0, null, 50)), [dispatch, 0, null]);
  const { data: products } = useSelector(selectHomeFeed);

  useEffect(() => {
    if(products.length > 0) {
      let cacheProducts = [...products].sort(() => 0.5 - Math.random()).slice(0, 10);
      setRandomProducts(cacheProducts);
    }
  }, [products])

  const executeScroll = () => scrollRef.current.scrollIntoView();

  const renderLogos = () => (
    brandLogos.map((logo, i) => {
      return (
        <img className={`flex items-center w-28 m-6 object-contain ${i > 5 && "hidden md:block"}`} style={{ filter: "grayscale(100%)", "&:hover": {filter: "grayscale(0)"}, }} src={logo} alt={"logo-"+i}/>
      )
    })
  )

  return (
  <body>
    <div className="flex">
      <img
        src={slabGrey}
        alt="grey slab"
        className="angled-slab-img w-24 hidden md:block"
      />
      <div className="py-4 pl-4">
        <div className="flex items-center justify-between pr-8">
          <img src={logo} alt="app logo" style={{imageRendering: "-webkit-optimize-contrast"}} className="w-52 sm:w-72" />
          <div className="flex pr-4">
            <Link to="/login" className="hidden md:block">
              <button
                className="relative rounded border border-orange-400 transition-color duration-100
          hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-3 "
              >
                Log in
              </button>
            </Link>
            <Link to="/signup/type" className="hidden sm:block">
              <button
                className="relative rounded border bg-orange border-orange transition-color duration-100
          hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center "
              >
                Sign up
              </button>
            </Link>
          </div>
        </div>
        <div className="flex items-center">
          <div className="p-4 md:w-1/2 ">
            <p className="mt-2 text-3xl leading-normal font-bold tracking-tight text-gray-900 2xl:text-4xl">
              The marketplace to buy and sell high quality remnant slabs
            </p>
            <p className="my-4 text-lg leading-normal tracking-tight text-gray-900 2xl:text-lg">
              Are you tired of spending thousands of dollars every year throwing
              away your remnant offcuts? SlabXChange helps you turn your waste
              into profits.
            </p>
            <div className="flex content-center flex-col md:flex-row 2xl:flex-row">
              <Link to="/signup/type" onClick={() => customEvent("cta_click", {text: "Sign Up Today", location: "hero landing"})}>
                <button
                  className="relative rounded border bg-orange border-orange transition-color duration-100
          hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center mr-4 hidden md:block"
                >
                  Sign Up Today
                </button>
              </Link>
              <button
                className="relative rounded transition-color duration-100
         hover:bg-gray-100 text-orange font-bold py-2 px-8 cursor-pointer text-center my-8 md:my-0"
                onClick={executeScroll}
              >
                Learn More
              </button>
              <div className="w-11/12  block md:hidden">
                <img
                  src={appExampleImg6}
                  alt="app example"
                  // className="example-image mobile"
                />
              </div>
            </div>
            <div className="flex content-center flex-col md:flex-row justify-center items-center 2xl:flex-row mt-8">
              <a className="w-60" target="_blank"
                 href='https://play.google.com/store/apps/details?id=com.slabxchange&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                 onClick={() => customEvent("cta_click", {text: "Get It On Google Play", location: "hero landing"})}
              >
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
              </a>
              <a className="w-60 h-24 p-4" target="_blank"
                 href="https://apps.apple.com/ca/app/slabxchange/id1580424664?itsct=apps_box_badge&amp;itscg=30200"
                 onClick={() => customEvent("cta_click", {text: "Download on the Apple Store", location: "hero landing"})}
              >
                <img className="w-full h-full" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1628899200&h=f9466739937513791eadf98e811ff0b5"
                     alt="Download on the App Store"/>
              </a>
            </div>
          </div>
          <div className="overflow-hidden w-1/2 hidden md:block">
            <img
              src={appExampleImg3}
              alt="app example"
              className="example-image hero"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col items-center my-12">
      <p className="mt-2 mb-4 text-3xl leading-normal font-bold tracking-tight text-gray-900 2xl:text-4xl">Popular products</p>
      <Link to="/dashboard">
        <button
          onClick={() => customEvent("cta_click", {text: "View Marketplace", location: "product slider"})}
          className="relative rounded border bg-white border-orange transition-color duration-100
          hover:bg-orange hover:text-white text-orange font-bold py-4 px-8 cursor-pointer text-center mr-4 hidden md:block"
        >
          View Full Marketplace
        </button>
      </Link>
      <div className="w-4/5 my-8 flex items-center relative">
        <button className="swiper-prev absolute md:-left-12 -left-8">
          <Icon size="lg" name="chevron-left"></Icon>
        </button>
        <Swiper
          spaceBetween={50}
          slidesPerView={width > 639 ? 4 : 1}
          navigation={{
            prevEl: ".swiper-prev",
            nextEl: ".swiper-next",
          }}
          pagination={{ clickable: true }}
        >
          {randomProducts.length > 0 && randomProducts.map(p => {
              const { description } = convertProductUnits(p, "inches", 0);
              return (
                <SwiperSlide>
                  <div className="pb-12">
                    <Card
                      key={p._id}
                      product={p}
                      image={p.images[0].url}
                      heading={p.type + (p.brand ? " - " + p.brand : "")}
                      subheading={description}
                      onClick={() => {
                        routeChange('/dashboard', {product: p})
                        customEvent("popular_product_click", p)
                      }}
                      bgColor="white"
                      landing
                    />
                  </div>
                </SwiperSlide>
              )
            }
          )}
        </Swiper>
        <button className="swiper-next absolute md:-right-12 -right-8">
          <Icon size="lg" name="chevron-right"></Icon>
        </button>
      </div>
    </div>
    <div ref={scrollRef} className="flex flex-col items-center py-8 px-8 md:px-48 2xl:pl-48">
      <div className="video-container">
        <iframe src="https://www.youtube.com/embed/cManX9ziALM" title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </div>
    </div>
    <div ref={scrollRef} className="flex flex-col items-center py-8 px-8 md:px-36 2xl:pl-24">
      <p className="mt-2 text-3xl leading-normal font-bold tracking-tight text-gray-900 2xl:text-4xl">Find all the best brands</p>
      <div className="mx-12 flex flex-row flex-wrap justify-center">
        {renderLogos()}
      </div>
      <p className="mt-2 text-2xl leading-normal  tracking-tight text-gray-700 xl:text-2xl">And more...</p>
    </div>
    <div ref={scrollRef} className="flex items-center mt-10 bg-gray-100 pl-8 py-24 2xl:pl-24">
      <div className=" md:w-1/2 md:p-4">
        <div className="p-4">
          <p className="mt-2 text-2xl leading-normal tracking-tight text-gray-900 sm:text-3xl">
            Quickly post, browse, and sell offcuts and remnant slabs
          </p>
          <p className="my-4 text-sm leading-normal tracking-tight text-gray-900 md:text-lg">
            With slabxchange get instant access to thousands of pieces in your
            local market area. You can reserve the stones and pick them up right
            away. Helping you save time and money.
          </p>
          <div className="relative flex items-center my-6">
            <div className="flex w-12 rounded-full bg-white items-center justify-center h-12 w-12 text-orange shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </div>
            <p className="ml-3 w-3/5 text-sm text-gray-900 md:text-base">
              Browse hundreds of slab remnants to find what's right for you
            </p>
          </div>
          <div className="relative flex items-center my-6">
            <div className="flex w-12 rounded-full bg-white items-center justify-center h-12 w-12 text-orange shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z" />
              </svg>
            </div>
            <p className="ml-3 w-3/5 text-sm text-gray-900 md:text-base">
              Reserve the perfect piece to ensure you get what you need
            </p>
          </div>
          <div className="relative flex items-center my-6">
            <div className="flex w-12 rounded-full bg-white items-center justify-center h-12 w-12 text-orange shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
                <g>
                  <g>
                    <path d="M21.41,11.41l-8.83-8.83C12.21,2.21,11.7,2,11.17,2H4C2.9,2,2,2.9,2,4v7.17c0,0.53,0.21,1.04,0.59,1.41l8.83,8.83 c0.78,0.78,2.05,0.78,2.83,0l7.17-7.17C22.2,13.46,22.2,12.2,21.41,11.41z M12.83,20L4,11.17V4h7.17L20,12.83L12.83,20z" />
                    <circle cx="6.5" cy="6.5" r="1.5" />
                  </g>
                </g>
              </svg>
            </div>
            <p className="ml-3 w-3/5 text-sm text-gray-900 md:text-base">
              Post your remnants and get access to local buyers
            </p>
          </div>
          <div className="relative flex items-center my-6">
            <div className="flex w-12 rounded-full bg-white items-center justify-center h-12 w-12 text-orange shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M4.01 6.03l7.51 3.22-7.52-1 .01-2.22m7.5 8.72L4 17.97v-2.22l7.51-1M2.01 3L2 10l15 2-15 2 .01 7L23 12 2.01 3z" />
              </svg>
            </div>
            <p className="ml-3 w-3/5 text-sm text-gray-900 md:text-base">
              Message and communicate with hundreds of local fabricators and
              suppliers
            </p>
          </div>
        </div>
        <div className="flex flex-row">
          <Link to="/signup/type">
            <button
              onClick={() => customEvent("cta_click", {text: "Get Started Today!", location: "product description section"})}
              className="relative rounded border bg-orange border-orange transition-color duration-100
          hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center mr-4"
            >
              Get Started Today!
            </button>
          </Link>
        </div>
        <div className="w-11/12 mt-10 block md:hidden">
          <img src={appExampleImg7} alt="app example" />
        </div>
      </div>
      <div className="overflow-hidden w-1/2 hidden md:block">
        <img
          src={appExampleImg4}
          alt="app example"
          className="example-image hero"
        />
      </div>
    </div>

    <div className="flex flex-col-reverse lg:flex-row justify-center items-center mt-10 px-16 xl:px-36 py-12 2xl:px-48 2xl:py-24">
      <div className="w-full text-center lg:text-left lg:w-3/5 xl:w-1/2 mt-12 lg:mt-0">
        <p style={{color: '#719B57'}} className="text-4xl sm:text-6xl font-bold">Reuse The Past,</p>
        <p style={{color: '#719B57'}} className="text-4xl sm:text-6xl font-bold">Save The Future</p>
        <p className="text-xl mt-8">Did you know there is</p>
        <p style={{color: '#F57255'}} className="text-4xl sm:text-6xl font-bold">2.01 billion tons</p>
        <p className="text-xl">of stone wasted every year?</p>
        <p className="text-xl mt-8">Join us in the fight against climate change, <strong>every stone matters.</strong></p>
      </div>
      <div className="w-full lg:w-2/5 xl:w-1/2 flex justify-center items-center">
        <img
          src={environmentalGraphic}
          alt="app example"
          className="w-3/5 sm:w-2/5 lg:w-4/5 xl:w-3/5"
        />
      </div>
    </div>

    <div className="flex items-center mt-10 px-5 py-5 2xl:px-24 2xl:py-24">
      <div className="p-4 w-full">
        <div className="p-4">
          <p className="mt-2 text-3xl leading-normal tracking-tight text-gray-900 sm:text-3xl">
            Plans & Prices
          </p>
          <p className="my-4 text-base leading-normal tracking-tight text-gray-900 2xl:w-3/5">
            SlabXChange plans and prices are flexible to fit your slab buying
            and selling requirements. See our plans below to find out what works
            for you.
          </p>
          <div className="flex justify-center w-full">
            <div className="w-1/3 px-3 hidden md:block">
              <p className="font-bold text-orange text-xl">RECOMMENDED</p>
            </div>
          </div>
          <div className="flex flex-col w-full md:flex-row">
            <div className="w-full md:mr-3 md:w-1/3">
              <div className="w-full border-b-4 border-orange" />
              <p className="text-2xl my-3 font-bold">Lite</p>
              <p className="text-xl my-3">$99/month</p>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Get 3 reservation at a time</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>1 user</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Unlimited posting</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Inventory tracking</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Live messaging</p>
              </div>
              <div className="flex flex-row my-2">
                <div className="w-3 h-3 mt-2 rounded-full border-2 border-orange mr-2" />
                <p className="w:full md:w-3/5">
                  Access to thousands of slabs and remnants in your area
                </p>
              </div>
            </div>
            <div className="w-full md:mx-3 bg-orange-100 md:w-1/3">
              <div className="w-full border-b-4 border-orange" />
              <div className="pl-2">
                <p className="text-2xl my-3 font-bold">Standard</p>
                <p className="text-xl my-3">$149/month</p>
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                  <p>Get 5 reservations at a time</p>
                </div>
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                  <p>3 users</p>
                </div>
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                  <p>Unlimited posting</p>
                </div>
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                  <p>Inventory tracking</p>
                </div>
                <div className="flex flex-row items-center my-2">
                  <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                  <p>Live messaging</p>
                </div>
                <div className="flex flex-row my-2">
                  <div className="w-3 h-3 mt-2 rounded-full border-2 border-orange mr-2" />
                  <p className="w-full md:w-3/5">
                    Access to thousands of slabs and remnants in your area
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:ml-3 md:w-1/3">
              <div className="w-full border-b-4 border-orange" />
              <p className="text-2xl my-3 font-bold">Premium</p>
              <p className="text-xl my-3">$199/month</p>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Get unlimited reservations at a time</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Unlimited users</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Unlimited posting</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Inventory tracking</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Live messaging</p>
              </div>
              <div className="flex flex-row items-center my-2">
                <div className="w-3 h-3 rounded-full border-2 border-orange mr-2" />
                <p>Free promotions on our social media platforms</p>
              </div>
              <div className="flex flex-row my-2">
                <div className="w-3 h-3 mt-2 rounded-full border-2 border-orange mr-2" />
                <p className="w:full md:w-3/5">
                  Access to thousands of slabs and remnants in your area
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-6">
          <Link to="/signup/companyInformation">
            <button
              onClick={() => customEvent("cta_click", {text: "Sign Up and Choose Your Plan", location: "plan list"})}
              className="relative rounded border bg-orange border-orange transition-color duration-100
            hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center mr-4"
            >
              Sign Up and Choose Your Plan
            </button>
          </Link>
        </div>
      </div>
    </div>
    <Footer />
  </body>
)};

export default LandingScreen;
