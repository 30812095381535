import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useMultiQuery(params) {
  const query = useQuery();
  const resObj = {};
  params.forEach((p) => (resObj[p] = query.get(p)));
  return resObj;
}

export { useQuery, useMultiQuery };
