import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { matchPath } from 'react-router';

import { selectUser, setUser } from "../../redux/reducers/userReducer";
import { app } from "../../services";
import useChat from "../../hooks/useChat";
import Icon from "../Icon/Icon";
import {customEvent} from "../../utils/AnalyticsUtils";
import useStoreId from "../../hooks/useStoreId";

const SideMenu = (props) => {
  const user = useSelector(selectUser);
  const isAdmin =
    user &&
    user.permissions.some((p) =>
      ["fabricator_admin", "fabricator_user"].includes(p)
    );

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const {storeId} = useStoreId();
  let rootPath =  matchPath(pathname, { path:"/:location" })
  let match = matchPath(pathname, {path: "/"+rootPath.params.location+"/:storeId"});

  const { unreadCount } = useChat(
    pathname.includes("/messages") ? false : 30 * 1000
  );

  useEffect(() => {
    let interval;
    if (unreadCount.unread > 0) {
      let i = 0;
      interval = setInterval(() => {
        document.title =
          ++i % 2 === 0
            ? `slabXchange`
            : `New message${unreadCount.unread > 1 ? `s` : ``}..`;
      }, 2500);
    } else {
      document.title = "slabXchange";
    }
    return () => clearInterval(interval);
  }, [unreadCount]);

  return (
    <div className="SideMenu hidden fixed sm:flex flex-col md:flex-row">
      <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white flex-shrink-0">
        <nav className="flex-grow flex-col pb-4 md:pb-0 md:overflow-y-auto">
          {user ? (
            <>
              <MenuHeader>General</MenuHeader>
              <SideMenuOption icon="search" to="/dashboard">
                Browse
              </SideMenuOption>
              <SideMenuOption icon="heart" to="/saved">
                Saved
              </SideMenuOption>
              <SideMenuOption icon="chat" to="/messages">
                <span className="flex items-center">
                  Messages
                  {unreadCount.unread > 0 && (
                    <div
                      className="flex ml-3 flex-shrink-0 h-5 w-5 rounded-xl
                  bg-orange-300 text-white items-center justify-center"
                    >
                      <span className="pr-px">{unreadCount.unread}</span>
                    </div>
                  )}
                </span>
              </SideMenuOption>
              {isAdmin && (
                <>
                  <MenuDivider />
                  <MenuHeader>ADMIN</MenuHeader>
                  <SideMenuOption icon="archive" to="/inventory">
                    My Listings
                  </SideMenuOption>
                </>
              )}
              <SideMenuOption icon="cog" to="/settings">
                Settings
              </SideMenuOption>
              <SideMenuOption
                icon="logout"
                onClick={() =>
                  app
                    .logout()
                    .then(dispatch(setUser(null)))
                    .finally(() => history.push("/dashboard"))
                }
              >
                Logout
              </SideMenuOption>
            </>
          ) : (
            <div className="w-full p-6">
              <Link to="/signup/type">
                <button
                  className="w-full relative rounded border bg-orange border-orange transition-color duration-100
            hover:bg-orange-700 text-white font-bold py-2 px-4 cursor-pointer text-center"
                >
                  Create an Account
                </button>
              </Link>
              <Link to="/login">
                <button
                  className="mt-8 w-full relative rounded border bg-white border-orange transition-color duration-100
            hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center"
                >
                  Login
                </button>
              </Link>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default SideMenu;

const SideMenuOption = ({ icon, children, to = "", onClick }) => {
  const { pathname } = useLocation();
  const Wrapper = (props) => (to ? <Link {...props} /> : <div {...props} />);
  const rootPath = `/${pathname.split("/")[1]}`; // /messages/:id => /messages

  return (
    <Wrapper
      to={to}
      className={`block pl-6 py-3 mt-2 text-sm font-semibold bg-${
        rootPath === to
          ? "orange-200 text-orange border-l-2 border-orange-700 hover:text-orange-700 transition-color duration-100"
          : "transparent text-gray-500 hover:bg-orange-100 hover:text-gray-900 transition-color duration-100"
      } rounded-r-lg  focus:outline-none focus:ring w-5/6 cursor-pointer`}
      onClick={() => {
        if(onClick)
          onClick()
        customEvent("side_menu", {option: to})
      }}
    >
      <span className="flex">
        <Icon name={icon} size="sm" className="mr-2" /> {children}
      </span>
    </Wrapper>
  );
};

const MenuHeader = ({ children }) => (
  <div className="flex-shrink-0 px-6 flex flex-row items-center justify-between">
    <div
      className="text-md mt-4 mb-1 font-semibold tracking-widest text-gray-400 uppercase rounded-lg
       focus:outline-none focus:shadow-outline"
    >
      {children}
    </div>
  </div>
);

const MenuDivider = () => (
  <div className="border-b-2 border-gray-200 w-48 ml-4 mt-4" />
);
