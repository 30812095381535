import React, {useRef, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { Icon } from "..";
import { selectUser, selectFabricator } from "../../redux/reducers/userReducer";
import useDistance from "../../hooks/useDistance";
import "./Card.css";
import imageRequest from "../../utils/ImageUtils";
import {customEvent} from "../../utils/AnalyticsUtils";
import { useInViewport } from 'react-in-viewport';

const Card = ({
  product = {},
  image,
  heading,
  subheading,
  bgColor,
  link,
  onClick,
  landing
}) => {
  const user = useSelector(selectUser) || {};
  const productIsFavorited = (user.favourites || []).includes(product._id);

  const productReservedByMe =
    product.reservationId &&
    (user.reservations || []).find((r) => r.productId === product._id);
  const isMyProduct = product.accountId === user.accountId;
  const bannerText = isMyProduct ? "Your Product" : null;
  let distance = useDistance({latitude: product?.location?.coordinates?.[1], longitude: product?.location?.coordinates?.[0]});
  let expireHours = 0;
  if(product.reservationExpiresAt)
    expireHours = moment.duration(moment(product.reservationExpiresAt).diff(moment())).asHours()

  const ref = useRef()
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(ref);

  const [impression, setImpression] = useState(false)
  useEffect(() => {
    if(enterCount === 1 && !impression)
      customEvent('product_impression', product)
    else
      setImpression(false)
  }, [enterCount])


  const content = (
    <InnerContent
      product={product}
      distance={!isMyProduct ? distance : null}
      image={image}
      heading={heading}
      subheading={subheading}
      bgColor={bgColor}
      bannerText={bannerText}
      isLiked={productIsFavorited}
      isReservedByMe={productReservedByMe}
      expireHours={expireHours}
      landing={landing}
    />
  );

  return onClick && !landing ? (
    <div ref={ref} className="w-full xl:w-1/2 2xl:w-1/3 px-2">
      <div
        className="Card overflow-hidden rounded-lg my-3 shadow-lg hover:shadow-2xl cursor-pointer"
        onClick={onClick}
      >
        {content}
      </div>
    </div>
  ) : landing ? (
    <div className="w-full px-2">
      <div
        onClick={onClick}
        className="Card overflow-hidden rounded-lg w-64 mx-2 my-3 shadow-lg  hover:shadow-2xl"
      >
        {content}
      </div>
    </div>
  ) : (
    <div className="md:w-1/2 px-2">
      <Link
        to={link}
        className="overflow-hidden rounded-lg w-64 mx-2 my-3 shadow-lg  hover:shadow-2xl"
      >
        {content}
      </Link>
    </div>
  );
};

export default Card;

const InnerContent = ({
  distance,
  product,
  image,
  heading,
  subheading,
  isLiked,
  isReservedByMe,
  bannerText,
  bgColor = "white",
  expireHours,
                        landing
}) => (
  <>
    <div className="relative">
      {isReservedByMe && expireHours > 0 && (
        <div className="Card-banner absolute top-0 left-0 mt-2 text-white pl-2 px-4 py-2 ml-0 justify-center rounded-r-lg">
          <p className="text-xs">Reservation expires in {Math.floor(expireHours)} hours</p>
        </div>
      )}
      <div className="absolute top-0 right-0 pt-2 pr-2 flex">
        {isReservedByMe && expireHours > 0 && (
          <Icon name="bookmark" className="fill-current text-orange mr-1" />
        )}
        {isLiked && <Icon name="heart" className=" fill-current text-orange" />}
      </div>
      {bannerText && (
        <div className="Card-banner absolute top-0 left-0 mt-4 text-white text-md py-1 px-3 text-center rounded-r-lg">
          {bannerText}
        </div>
      )}
      <img
        alt="Placeholder"
        className="rounded-t-lg h-32 w-full object-cover"
        src={imageRequest(image, 800)}
      />
    </div>
    <div className={`p-2 pl-4 bg-${bgColor}`}>
      <header className="flex items-start justify-between leading-tight align-start">
        <div className="w-7/12">
          <h1 className="font-semibold text-md whitespace-nowrap overflow-ellipsis overflow-hidden">{heading}</h1>
          {product.manufacturer ? (
              <h1 className="text-md text-gray-500 whitespace-nowrap overflow-ellipsis overflow-hidden">{product.manufacturer}</h1>
          ) : <span style={{height: 20}}>&nbsp;</span>}
          <h1 className="text-sm whitespace-nowrap overflow-ellipsis overflow-hidden">{subheading}</h1>
        </div>
        <div className="ml-2 flex flex-col items-end w-5/12">
          {product.price && (
            <h1 className="text-orange-600 font-semibold">${product.price}</h1>
          )}
          {(distance && !landing) && (
            <h1 className="text-gray-500">{distance}km</h1>
          )}
        </div>
      </header>
    </div>
  </>
);
