import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../reducers/chatReducer";

import productsReducer from "../reducers/productsReducer";
import userReducer from "../reducers/userReducer";

export default configureStore({
  reducer: {
    user: userReducer,
    products: productsReducer,
    chat: chatReducer,
  },
});
