import React from "react";

const TableHeader = ({ children }) => (
  <th
    className="TableHeader whitespace-nowrap sticky top-0 py-4 px-6 bg-grey-lightest font-semibold uppercase text-sm
      text-gray-500 "
  >
    {children}
  </th>
);

export default TableHeader;
