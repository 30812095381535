import React, {useEffect, useState} from "react";
import { AlertContext } from "../../context/alert";
import ReactCanvasConfetti from 'react-canvas-confetti';

import "./Alert.css";
import {Icon} from "../index";

let timeout;

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
}

const Alert = () => {
  const { alertState, dispatchAlert } = React.useContext(AlertContext);
  const [hide, setHide] = useState(false);
  const [fire, setFire] = useState(false)

  const closeMe = () => {
    dispatchAlert({ type: "close", confetti: false });
  };

  useEffect(() => {
    if(hide) {
      clearTimeout(timeout)
      setTimeout(() => {
        closeMe()
      }, 500)
    }
    // eslint-disable-next-line
  }, [hide])

  useEffect(() => {
    if(alertState.open) {
      setFire(alertState.confetti)
      timeout = setTimeout(() => {
        setFire(false)
        setHide(true)
      }, alertState.duration)
    } else {
      setHide(false)
    }
    // eslint-disable-next-line
  }, [alertState.open])

  return (
    <>
      {alertState.open && (
          <div
            className={`Alert p-6 ${alertState.position} rounded-lg w-1/4 animate__animated animate__fadeIn animate__faster ${hide && "animate__fadeOut"}`}
          >
            <div className={"flex flex-row"}>
              {alertState.error && (
                <Icon name="exclamation" color="#f2421b" size="md" className="mr-2" />
              )}
              <p>{alertState.message}</p>
            </div>
            <p
              className="underline text-right mt-2 cursor-pointer"
              onClick={() => {
                setHide(true)
                setFire(false)
                alertState.action()
              }}
            >
              {alertState.label}
            </p>
            <ReactCanvasConfetti particleCount={200} origin={{x: 0.9, y: 0.9}} drift={-1} spread={120} angle={120} fire={fire} style={canvasStyles}/>
          </div>
        )}
      </>
  );
};

export default Alert;
