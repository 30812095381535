import React from "react";

import { Icon } from "..";

const Modal = ({
  title,
  body,
  customBody,
  icon = "exclamation",
  iconColor = "red",
  children,
  standardModal = false
}) => (
  <div className="Modal">
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className={`flex items-end justify-center min-h-screen max-h-screen pt-4 px-4 pb-20 text-center sm:block ${(customBody && !standardModal) ? "px-16" : "sm:p-0"}`}>
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${(!customBody || standardModal) && "sm:max-w-lg"} sm:w-full`}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${iconColor}-100 sm:mx-0 sm:h-10 sm:w-10`}
              >
                <Icon name={icon} className={`h-6 w-6 text-${iconColor}-600`} />
              </div>
              <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {title}
                </h3>
                {customBody ? (
                  <>
                    {customBody}
                  </>
                ) : (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{body}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
