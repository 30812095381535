import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import {Button, ConfirmModal, Icon, Input, Screen} from "../../components";
import { selectUser, updateUser } from "../../redux/reducers/userReducer";
import {
  accountService,
  app,
  customerPortalSessionService,
  userService,
  fabricatorUserService
} from "../../services";
import { camelToTitle, capitalize } from "../../utils/StringUtils";
import "./SettingsScreen.css";
import {AlertContext} from "../../context/alert";
import {geocodeAddress} from "../../utils/GeocodeUtils";
import {customEvent} from "../../utils/AnalyticsUtils";

const SettingsScreen = () => {
  let fields = { ...FIELDS };
  let user = useSelector(selectUser);
  user = user || {};
  const [tab, setTab] = useState(
    `${user.accountId ? "Company" : "Contact"} Information`
  );
  const [account, setAccount] = useState({});
  const dispatch = useDispatch();
  const { dispatchAlert } = React.useContext(AlertContext);
  useEffect(() => {
    const fetchAccount = async (accountId) => {
      const account = await accountService.get(accountId);
      setAccount(account);
    };
    user.accountId && fetchAccount(user.accountId);
  }, [user]);

  const address = account.address || {};
  const mainContact = account.mainContact || {}

  const formData = {
    "Company Information": {
      name: account.name,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: mainContact.phoneNumber,
      street: address.street,
      suiteNumber: address.suiteNumber,
      postalCode: address.postalCode,
      province: address.province,
      country: address.country
    },
    "Account Information": {
      firstName: user.firstName,
      lastName: user.lastName,
    },
    "Contact Information": {
      firstName: user.firstName,
      lastName: user.lastName,
    },
    "Login Email": {
      email: user.email,
    },
    Preferences: {
      preferredUnits: user.preferredUnits,
      pushNotify: user.pushNotify,
      emailNotify: user.emailNotify,
    },
    "Change Profile Picture": {
      profilePicture: user.profilePicture,
    },
  };

  return (
    <Screen header="Settings" autopadding={false}>
      <div className="flex">
        <SettingsTabs tab={tab} setTab={setTab} />
        <div className="MenuContent w-3/4 p-6">
          <SettingsContent
            title={tab}
            fields={fields[tab]}
            values={formData[tab] || {}}
            account={account}
            onSave={async (formVals) => {
              if (
                tab === "Contact Information" ||
                tab === "Change Profile Picture" ||
                tab === "Preferences"
              ) {
                dispatch(updateUser(formVals));
                dispatchAlert({
                  type: "open",
                  position: "right",
                  message: (tab === "Change Profile Picture" ? "Profile Picture" : tab)+" updated.",
                });
                // TODO: talk to Hassan about file uploads, if we're using s3 or what?
              } else if (tab === "Company Information") {
                // TODO: currently account patch fails with 403, this should work though
                let companyData = {
                  name: formVals.name,
                  mainContact: {
                    phoneNumber: formVals.phoneNumber,
                    firstName: formVals.firstName,
                    lastName: formVals.lastName
                  },
                  address: {
                    street: formVals.street,
                    suiteNumber: formVals.suiteNumber,
                    postalCode: formVals.postalCode,
                    province: formVals.province,
                    country: formVals.country
                  }
                }
                const coords = await geocodeAddress(formVals.postalCode)
                if(coords) {
                  companyData.latitude = coords.latitude;
                  companyData.longitude = coords.longitude;
                }
                accountService.patch(account._id, companyData);
                dispatch(
                  updateUser({
                    firstName: formVals.firstName,
                    lastName: formVals.lastName
                  })
                );
                dispatchAlert({
                  type: "open",
                  position: "right",
                  message: `Company Information updated.`,
                });
              } else if (tab === "Login Email") {
                if (formVals.newEmail !== formVals.newEmailConfirm)
                  return alert("Looks like your emails don't match");
                dispatch(updateUser({ email: formVals.newEmail }));
              } else if (tab === "Password") {
                const { newPassword = "", newPasswordConfirm = "" } = formVals;
                if (newPassword.length < 8)
                  return alert("Password must be a minimum of 8 characters.");
                if (newPassword !== newPasswordConfirm)
                  return alert("Passwords must match.");
                app
                  .authenticate({
                    strategy: "local",
                    email: user.email,
                    password: formVals.currentPassword,
                  })
                  .then(() => {
                    dispatch(
                      updateUser({
                        password: formVals.newPassword,
                      })
                    );
                    dispatchAlert({
                      type: "open",
                      position: "right",
                      message: `Changes saved.`,
                    });
                  })
                  .catch(() => alert("Current password not correct."));
              }
              customEvent("profile_edit", {tab})
            }}
          >
            {tab === "Manage Users" && <ManageUsers account={account} />}
          </SettingsContent>
        </div>
      </div>
    </Screen>
  );
};

export default SettingsScreen;

const SettingsTabs = ({ tab, setTab }) => {
  let user = useSelector(selectUser) || {};
  const firstTab = `${user.accountId ? "Company" : "Account"} Information`;

  function manageBilling() {
    customerPortalSessionService.create({}).then((res) => {
      window.location = res.session_url;
    });
    customEvent("manage_billing_pressed")
  }

  return (
    <div className="xl:w-1/4 w-1/3  mt-">
      {user?.permissions?.some((p) =>
        ["fabricator_admin", "fabricator_user"].includes(p)
      ) ? (
        <MenuTab
          className="pt-1px border-t-0"
          selected={tab === firstTab}
          onClick={() => setTab(firstTab)}
        >
          {firstTab}
        </MenuTab>
      ) : (
        <MenuTab
          className="pt-1px border-t-0"
          selected={tab === "Contact Information"}
          onClick={() => setTab("Contact Information")}
        >
          Contact Information
        </MenuTab>
      )}
      <MenuTab
        selected={tab === "Login Email"}
        onClick={() => setTab("Login Email")}
      >
        Login Email
      </MenuTab>
      <MenuTab selected={tab === "Password"} onClick={() => setTab("Password")}>
        Password
      </MenuTab>
      <MenuTab
        selected={tab === "Preferences"}
        onClick={() => setTab("Preferences")}
      >
        Preferences
      </MenuTab>
      {/*<MenuTab*/}
      {/*  selected={tab === "Change Profile Picture"}*/}
      {/*  onClick={() => setTab("Change Profile Picture")}*/}
      {/*>*/}
      {/*  Change Profile Picture*/}
      {/*</MenuTab>*/}
      {(user.permissions || []).includes("fabricator_admin") && (
        <MenuTab
          selected={tab === "Manage Users"}
          onClick={() => setTab("Manage Users")}
        >
          Manage Users
        </MenuTab>
      )}
      {(user.permissions || []).includes("fabricator_admin") && (
        <MenuTab onClick={manageBilling}>Manage Billing</MenuTab>
      )}
      {/* <MenuTab
        hideChevron
        onClick={() => {
          app
            .logout()
            .then(dispatch(setUser(null)))
            .finally(() => history.push("/login"));
        }}
      >
        <div className="flex items-center">
          <Icon name="logout" className="mr-2" />
          <span className="mt-px">Logout</span>
        </div>
      </MenuTab> */}
    </div>
  );
};

const MenuTab = ({
  selected,
  onClick,
  children,
  hideChevron = false,
  className,
}) => (
  <div
    className={`MenuTab flex justify-between pl-6 py-4 pr-4 text-sm bg-${
      selected
        ? "orange-200 text-orange border-l-4 hover:text-orange-700"
        : "transparent text-gray-500 hover:bg-orange-100 hover:text-gray-900 border-l-0"
    } focus:outline-none focus:shadow-outline font-semibold cursor-pointer ${className}`}
    onClick={onClick}
  >
    {children}
    {!hideChevron && <Icon name="chevron-right" size="sm" />}
  </div>
);

const SettingsContent = ({
  title,
  fields = [],
  values = {},
  children,
  onSave,
  account
}) => {
  const [formVals, setFormVals] = useState(values);
  const { dispatchAlert } = React.useContext(AlertContext);
  useEffect(() => setFormVals(values), [values]);
  let storeLink = window.location.protocol + "//" + window.location.host + "/store/" + account.storeId;
  let serviceLink = window.location.protocol + "//" + window.location.host + "/service-agreement"

  return (
    <div className="SettingsContent">
      <h2 className="text-xl mb-6">{title}</h2>
      {title === "Preferences" && account.storeId && (
        <div>
          <div className="mr-4 text-lg font-semibold">Public Store Link:</div>
          <p className="my-3">{storeLink}</p>
          <div className="flex flex-row">
            <button
              onClick={() => {
                navigator.clipboard.writeText(`${storeLink}`)
                dispatchAlert({
                  type: "open",
                  position: "right",
                  message: "Store link copied.",
                });
              }}
              className="relative rounded border bg-white border-orange transition-color duration-100
          hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-4 hidden md:block"
            >
              Copy Link
            </button>
            <Link to={{ pathname: `${storeLink}` }} target="_blank" >
              <button
                className="ml-1 relative rounded border bg-white border-orange transition-color duration-100
          hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-4 hidden md:block"
              >
                Visit Link
              </button>
            </Link>
          </div>
        </div>
      )}

      {title === "Preferences" && account.storeId && (
        <>
        <div className="my-4 text-lg font-semibold">Service Agreement:</div>
          <Link to={{ pathname: `${serviceLink}` }} target="_blank" >
            <button
              className="ml-1 relative rounded border bg-white border-orange transition-color duration-100
            hover:bg-gray-100 text-orange font-bold py-2 px-4 cursor-pointer text-center mr-4 hidden md:block"
            >
              View Agreement
            </button>
          </Link>
        </>
      )}
      {title === "Login Email" && (
        <div className="text-md mb-6 font-semibold text-orange-400">
          {values.email}
        </div>
      )}
      {fields &&
        fields.map((f, i) => (
          <div className="mt-3" key={i}>
            <Input
              value={formVals[f.field] || ""}
              label={f.displayName || capitalize(f.field)}
              onChange={(e) => {
                const newValues = { ...formVals, [f.field]: e?.target?.value };
                setFormVals(newValues);
                title === "Change Profile Picture" && onSave(newValues);
              }}
              type={f.type || null}
            />
          </div>
        ))}
      {children}
      {!["Change Profile Picture", "Manage Users"].includes(title) && (
        <Button className="w-full mt-4" onClick={() => onSave(formVals)}>
          Save Changes
        </Button>
      )}
    </div>
  );
};

const FIELDS = {
  "Company Information": [
    { field: "name", displayName: "Company Name" },
    { field: "firstName" },
    { field: "lastName" },
    { field: "phoneNumber", displayName: "Phone Number" },
    { field: "street", displayName: "Street Address" },
    { field: "suiteNumber", displayName: "Suite Number" },
    { field: "postalCode", displayName: "Postal Code" },
    { field: "province", displayName: "Province" },
    { field: "country", displayName: "Country" },
  ],
  "Contact Information": [
    { field: "firstName" },
    { field: "lastName" },
    // { field: "phone", displayName: "Phone Number" },
  ],
  "Login Email": [
    { field: "newEmail", displayName: "New Login Email" },
    { field: "newEmailConfirm", displayName: "Confirm Login Email" },
  ],
  Password: [
    {
      field: "currentPassword",
      displayName: "Type Current Password",
      type: "password",
    },
    {
      field: "newPassword",
      displayName: "Type New Password",
      type: "password",
    },
    {
      field: "newPasswordConfirm",
      displayName: "Confirm New Password",
      type: "password",
    },
  ],
  Preferences: [
    {
      field: "preferredUnits",
      displayName: "Preferred Units",
      type: "units",
    },
    {
      field: "pushNotify",
      displayName: "Push Notifications",
      type: "switch"
    },
    {
      field: "emailNotify",
      displayName: "Email Notifications",
      type: "switch"
    }
  ],
  "Change Profile Picture": [{ field: "profilePicture", type: "image" }],
  "Manage Users": [],
};

const ManageUsers = ({ account }) => {
  const user = useSelector(selectUser) || {};
  const [users, setUsers] = useState([]);
  const [{formType, formData}, setFormType] = useState({
    formType: null,
    formData: {}
  });
  const [{ modal, modalData }, setModalData] = useState({
    modal: null,
    modalData: null,
  });
  const setModal = (modal, modalData) =>
    setModalData({ modal, modalData });
  const { dispatchAlert } = React.useContext(AlertContext);
  // const [userSlotsAvailable, setUserSlots] = useState(false);

  // useEffect(() => {
  //   setUserSlots(account.userLimit < users.length);
  // }, [users.length, account.userLimit]);
  const userLimitReached = users.length >= account.userLimit;

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await userService.find({
        query: { accountId: user.accountId },
      });
      setUsers(res.data);
    };
    fetchUsers();
  }, []);

  const deleteUser = (user) => {
    const filteredUsers = users.filter(({ _id }) => _id !== user._id);
    setUsers(filteredUsers);
    userService.remove(user._id);
  };

  const createUser = async (userToCreate) => {
    try {
      const res = await fabricatorUserService.create(userToCreate);
      setUsers([...users, res]);
      customEvent("add_user", {email: userToCreate.email, name: userToCreate.name})
      resetFormType()
    } catch (err) {
      console.log(err);
      if(err.code === 409)
        dispatchAlert({
          type: "open",
          position: "right",
          message: "Email already in use.",
        });
      else
        dispatchAlert({
          type: "open",
          position: "right",
          message: "Unknown error.",
        });
    }
  };

  const updateUser = async (userToUpdate) => {
    let {_id, ...userData} = userToUpdate;
    try {
      const res = await userService.patch(_id, userData);
      let newUsers = [...users];
      let userIndex = newUsers.findIndex((user) => {
        return user._id === res._id;
      })
      newUsers[userIndex] = res;
      setUsers(newUsers);
      resetFormType()
    } catch (err) {
      console.log(err);
      if(err.code === 409)
        dispatchAlert({
          type: "open",
          position: "right",
          message: "Email already in use.",
        });
      else
        dispatchAlert({
          type: "open",
          position: "right",
          message: "Unknown error.",
        });
    }
  }

  const resetFormType = () => {
    setFormType({type: null, data: {}});
  }

  return formType ? (
    <UserForm onSubmit={(user) => {
      if (formType === "new")
        createUser(user)
      if (formType === "edit")
        updateUser(user)
      }
    } onCancel={resetFormType} formType={formType} formData={formData} />
  ) : (
    <div className="ManageUsers">
      {userLimitReached && (
        <p className="text-sm text-red-300 mb-2">*User limit reached</p>
      )}
      {users && users.length ? (
        users.map((u) => {
          const isMe = u._id === user._id;
          return (
            <div
              key={u._id}
              className="flex justify-between p-4 border-b-2 border-gray-200"
            >
              <div className={isMe ? "text-orange-300" : ""}>
                <b>
                  {u.firstName} {u.lastName}
                </b>{" "}
                <i>{u.email}</i>
              </div>
              <div className="flex">
                <Icon
                  name="pencil"
                  className={`${
                    isMe
                      ? `text-gray-200`
                      : `cursor-pointer text-red-400 hover:text-red-500`
                  } mr-3`}
                  onClick={
                    isMe
                      ? null
                      : () => {
                        console.log(u)
                        setFormType({formType: "edit", formData: {firstName: u.firstName, lastName: u.lastName, email: u.email, _id: u._id}})
                      }
                  }
                />
                <Icon
                  name="trash"
                  className={`${
                    isMe
                      ? `text-gray-200`
                      : `cursor-pointer text-red-400 hover:text-red-500`
                  }`}
                  onClick={
                    isMe
                      ? null
                      : () => {
                        setModal("DELETEUSER", u)
                      }
                  }
                />
              </div>
            </div>
          );
        })
      ) : (
        <div>You have no other users.</div>
      )}
      <Button
        disabled={userLimitReached}
        className="mt-12 w-full"
        onClick={() => setFormType({formType: "new", formData: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }})}
      >
        Add User
      </Button>
      {modal === "DELETEUSER" && (
        <ConfirmModal
          title="Delete User"
          body="Are you sure you want to delete this user?"
          confirmText="Delete"
          onConfirm={() => {
            deleteUser(modalData)
            dispatchAlert({
              type: "open",
              position: "right",
              message: `${
                modalData.firstName +
                (modalData.lastName ? " " + modalData.lastName : "")
              } has been deleted.`,
            });
            customEvent("delete_user", modalData)
            setModal(null);
          }}
          onCancel={() => setModal(null)}
        />
      )}
    </div>
  );
};

const newUserSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
  email: yup.string().label("Email").email().required(),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8, "Password must be a minimum of 8 characters."),
});

const editUserSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
  email: yup.string().label("Email").email().required()
});


const UserForm = ({ onSubmit, onCancel, formType, formData }) => {

  return(

  <div>
    <h2 className="text-lg">{formType === "new" ? "New User:" : "Edit User:"}</h2>
    <Formik
      initialValues={formData}
      validationSchema={formType === "new" ? newUserSchema : editUserSchema}
      onSubmit={(values) => {
        console.log('submitting')
        onSubmit(values)
      }}
    >
      {(props) => (
        <>
          {Object.keys(formData).map((field) => {
            if(field !== "_id")
            return (
            <Input
              key={field}
              label={camelToTitle(field)}
              value={props.values[field]}
              name="email"
              type={field === "password" ? field : "string"}
              onChange={props.handleChange(field)}
              error={props.touched[field] && props.errors[field]}
            />
          )})}
          <div className="mt-5 flex justify-between gap-4">
            <Button className="w-1/2" onClick={() => {
              props.handleSubmit()
            }}>
              Save
            </Button>
            <Button className="w-1/2" type="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </>
      )}
    </Formik>
  </div>
)};
