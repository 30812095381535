import React from "react";

const SlabTypeSelector = ({ slabType, setSlabType, className = "" }) => (
  <div className={`pt-4 flex justify-between ${className}`}>
    <div className="mr-4 text-lg font-semibold">Slab Type:</div>
    <div className="">
      <label className="inline-flex items-center mr-4 cursor-pointer">
        <input
          type="radio"
          className="form-radio h-5 w-5 text-orange"
          checked={slabType === "Full"}
          onChange={() => setSlabType("Full")}
        />
        <span className="ml-2 text-gray-700">Full</span>
      </label>
      <label className="inline-flex items-center ">
        <input
          type="radio"
          className="form-radio h-5 w-5 text-orange cursor-pointer"
          checked={slabType === "Remnant"}
          onChange={() => setSlabType("Remnant")}
        />
        <span className="ml-2 text-gray-700">Remnant</span>
      </label>
    </div>
  </div>
);

export default SlabTypeSelector;
