import React, { useEffect, useState } from "react";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { accountService } from "../../services";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/reducers/userReducer";
import useCustomerReservations from "../../hooks/useCustomerReservations";
import { capitalize } from "../../utils/StringUtils";
import { convertProductUnits } from "../../utils/UnitUtils";
import { AlertContext } from "../../context/alert";
import { useHistory, Link } from "react-router-dom";
import imageRequest from "../../utils/ImageUtils";
import useDistance from "../../hooks/useDistance";
import { Button, ConfirmModal, FavoriteButton, Divider } from "..";
import { customEvent} from "../../utils/AnalyticsUtils";
import SocialButton from "../SocialButton/SocialButton";

const generateMapsLink = (address) => {
  if(address){
    let addressString = encodeURIComponent([(address.suiteNumber ? address.suiteNumber+" ":"")+address.street,address.city,address.province].join())
    return "https://www.google.com/maps/search/?api=1&query="+addressString;
  }
}

const SlabDetails = ({
  storeId = null,
  product,
  showPublishDate,
  setSelectedProduct,
  buttonLeft = "reserve",
  buttonRight = "message",
  children,
  noButtons = false
}) => {
  const history = useHistory();
  const user = useSelector(selectUser) || {};
  const isMyProduct = product.accountId === user.accountId;
  const LeftButton = BUTTONS[buttonLeft];
  const RightButton = BUTTONS[buttonRight];
  const { description } = convertProductUnits(product, user.preferredUnits);
  const { dispatchAlert } = React.useContext(AlertContext);
  const { height, width } = useWindowDimensions();
  const [{ modal, modalProduct }, setModalData] = useState({
    modal: null,
    modalProduct: null,
  });
  const setErr = (err) => {
    dispatchAlert({
      type: "open",
      position: "left",
      message: `${err}`,
      error: true
    });
  }
  const setModal = (modal, modalProduct) =>
    setModalData({ modal, modalProduct });
  const buttonProps = {
    product,
    setSelectedProduct,
    isMyProduct,
    setErr,
    setModal,
  };
  const [selectedItem, setSelectedItem] = useState(0);
  const [fabricator, setFabricator] = useState(null);
  const [expireHours, setExpireHours] = useState(null);
  const [isReserved, setIsReserved] = useState(false);
  const [isReservedByMe, setIsReservedByMe] = useState(false);
  useEffect(() => {
    async function fetchFabricator() {
      if (!product.accountId) return;
      const fab = await accountService.get(product.accountId);
      setFabricator(fab);
    }
    fetchFabricator();
    setSelectedItem(0);
    // eslint-disable-next-line
  }, [product]);
  let distance = useDistance({
    latitude: product?.location?.coordinates?.[1],
    longitude: product?.location?.coordinates?.[0],
  });

  useEffect(() => {
    if(product.reservationExpiresAt){
      let expires = moment.duration(moment(product.reservationExpiresAt).diff(moment())).asHours()
      setExpireHours(expires)
    }
    setIsReserved(product?.reservationId?._id)
    setIsReservedByMe(product?.reservationId?._id &&
      (user.reservations || []).find((r) => r.productId === product._id))
  }, [product])

  return (
    <div className="SlabDetails px-8 pt-3">
      <div className="safari-rounded-fix relative rounded-2xl overflow-hidden">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          selectedItem={selectedItem}
          onChange={(index) => setSelectedItem(index)}
          showIndicators={product.images.length > 1}
          emulateTouch
        >
          {product.images &&
            product.images.map(({ url }) => (
              <div>
                {product.images && (
                  <img
                    src={imageRequest(url, 800)}
                    alt="Slab"
                    className="RightPanel-large-image h-60 xl:h-96 mx-auto w-full"
                  />
                )}
              </div>
            ))}
        </Carousel>
        <div className="absolute top-0 right-0 pt-4 pr-6 z-50">
          <FavoriteButton
            product={product}
            onClickNoUser={() => setModal("SIGNUP")}
          />
        </div>
        {product.reservationId && expireHours > 0 && (
          <div className="Card-banner absolute top-0 left-0 mt-4 text-white pl-6 pr-2 w-60 py-2 ml-0 justify-center rounded-r-lg">
            <div>
              <p className="text-lg">Reserved</p>
              {isReservedByMe && expireHours > 0 && (
                <p className="text-md">Expires in {Math.floor(expireHours)} hours</p>
              )}
              {product.reservationId.reserverName && isMyProduct && (
                <p className="text-sm">
                  By {product.reservationId.reserverName}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="pl-1 pt-4">
        <div className="flex justify-between">
          <div className="flex-col">
            <h2 className="font-semibold text-2xl">
              {product.type + (product.brand ? " - " + product.brand : "")}
            </h2>
            <h4 className="text-lg text-gray-500">
              {product.manufacturer ? product.manufacturer : ""}
            </h4>
          </div>
          <div className="flex-col w-3/5">
            {/* can't do && with a value that might be 0, or else it just renders 0 */}
            {product.price ? (
              <h2 className="text-right text-orange-400 ml-3 font-semibold text-2xl">
                ${product.price}
              </h2>
            ) : null}
            {!isMyProduct && distance && (
              <h2 className="text-right text-gray-400 ml-3 text-xl">
                {distance}km away
              </h2>
            )}
          </div>
        </div>
        <h4 className="text-xl font-semibold mt-4">Slab Id</h4>
        {(product.slabType === "Full" ? "F" : "R")+"-"+(product.locationId ? product.locationId : 0)+"-"+product.slabId}
        {isMyProduct ? (
          <h4 className="text-xl font-semibold mt-4 text-orange-400">
            Your Item's Details
          </h4>
        ) : (
          <h4 className="text-xl font-semibold mt-4">Item Details</h4>
        )}
        <ul className="ul-orange">
          <li>{product.slabType === "full" ? "Full Slab" : "Remnant Slab"}</li>
          <li>{description}</li>
          <li>{capitalize(product.tone || product.colours[0])} Tone</li>
          {product.finish && (<li>{capitalize(product.finish)} Finish</li>)}
          {product.description && (
            <li>{product.description}</li>
          )}
        </ul>
      </div>
      {showPublishDate && (
        <h4 className="text-sm text-gray-400 text-center">
          Listed on {moment(product.publishedAt).format("MMMM Do YYYY")}
        </h4>
      )}
      {!isMyProduct && (
        <div className="flex w-full gap-6 px-4">
          <FabricatorDetails
            product={product}
            fabricator={fabricator}
            isReserved={isReserved && expireHours > 0}
            isReservedByMe={isReservedByMe && expireHours > 0}
            setModal={setModal}
            user={user}
          />
        </div>
      )}
      {buttonLeft && buttonRight && !noButtons && (
        <div className="flex w-full gap-6 px-4 py-8 flex-col xl:flex-row">
          <LeftButton
            isReserved={isReserved && expireHours > 0}
            isReservedByMe={isReservedByMe && expireHours > 0}
            setIsReserved={setIsReserved}
            setIsReservedByMe={setIsReservedByMe}
            {...buttonProps}
          />
          <RightButton {...buttonProps} isReservedByMe={isReservedByMe} />
        </div>
      )}
      {children}
      {modal === "SIGNUP" && (
        <ConfirmModal
          icon="user"
          color="green"
          title="Sign Up for Free!"
          standardModal
          customBody={
              <div className="flex flex-col justify-center items-center pt-8">
                  <p className="my-3">{width > 639 ? ("Join") : ("Download") } SlabXChange for free today to access this feature and save hundreds of dollars on high quality slabs!</p>
                  { width > 639 && (
                    <p className="my-3">Press Join Now to create an account or sign up using social media.</p>
                  )}
                <div className=" md:mr-12">
                  {width > 639 ? (
                    ["Google","Facebook", "Apple"].map(type => <SocialButton type={type} />)
                  ) : (
                    <div className="flex content-center flex-col md:flex-row justify-center items-center 2xl:flex-row">
                      <a className="w-60" target="_blank"
                         href='https://play.google.com/store/apps/details?id=com.slabxchange&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                         onClick={() => customEvent("cta_click", {text: "Get It On Google Play", location: "hero landing"})}
                      >
                        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                      </a>
                      <a className="w-60 h-24 p-4" target="_blank"
                         href="https://apps.apple.com/ca/app/slabxchange/id1580424664?itsct=apps_box_badge&amp;itscg=30200"
                         onClick={() => customEvent("cta_click", {text: "Download on the Apple Store", location: "hero landing"})}
                      >
                        <img className="w-full h-full" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1628899200&h=f9466739937513791eadf98e811ff0b5"
                             alt="Download on the App Store"/>
                      </a>
                    </div>
                  )}
                </div>
                <div className="w-full flex justify-center my-3 md:mr-12">
                  <Link to="/login">
                    <div className="text-blue-500 hover:text-blue-800 underline">I already have an account</div>
                  </Link>
                </div>
              </div>
          }
          body="Create an account now to unlock a host of amazing features and save money buying high quality slabs!"
          confirmText={width > 639 ? "Join Now" : "Dismiss"}
          onConfirm={() => {
            setModal(null);
            width > 639 && history.push("/signup/type");
          }}
          onCancel={
            width > 639 ?
              () => setModal(null)
              : null
          }
        />
      )}
    </div>
  );
};

export default SlabDetails;

const FabricatorDetails = ({ fabricator, product, setModal, user }) => {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    setShowDetails(false);
  }, [product]);

  const toggleSellerDetails = (bool) => {
    customEvent("fabricator_signup")
    if (Object.keys(user).length > 0)
      setShowDetails(bool);
    else
      setModal("SIGNUP");
    if(bool)
      customEvent("show_seller_details", product)
  }

  return fabricator ? (
    <div className="w-full">
      <div className="flex flex-row items-center justify-center gap-5 mt-4">
        <div className={`border-b-2 border-gray-200 flex flex-grow`} />
        <div
          className="cursor-pointer text-gray-500 flex"
          onClick={() => toggleSellerDetails(!showDetails)}
        >
          <div
            className={`${
              !showDetails ? "transform rotate-180" : ""
            } mr-1 mb-1`}
          >
            ^
          </div>
          <div className="hover:underline">
            {showDetails ? "Hide" : "Show"} Seller Details
          </div>
        </div>
        <div className={`border-b-2 border-gray-200 flex flex-grow`} />
      </div>
      {showDetails && (
        <div className="mt-2">
          <h4 className="text-xl mb-2 font-semibold">Seller Details</h4>
          <div className="flex-row mb-2">
            <span className="font-bold w-36 inline-block">Company Name:</span>
            <span className="pl-2">{fabricator.name}</span>
          </div>
          <div className="flex-row mb-2">
            <span className="font-bold w-36 inline-block">Phone Number:</span>
            <a
              href={"tel:" + fabricator.mainContact.phoneNumber}
              className="pl-2 text-blue-500 hover:text-blue-900"
            >
              {fabricator.mainContact.phoneNumber}
            </a>
          </div>
          <div className="flex flex-row mb-2 items-start justify-start">
            <p className="flex font-bold w-36">Address:</p>
            <a target="_blank" href={generateMapsLink(fabricator.address)} className="flex pl-2 flex-col text-blue-500 hover:text-blue-900">
              <p>
                {fabricator?.address?.suiteNumber} {fabricator?.address?.street}
              </p>
              <p>
                {fabricator?.address?.city} {fabricator?.address?.province}{" "}
                {fabricator?.address?.postalCode}
              </p>
            </a>
          </div>
        </div>
      )}
    </div>
  ) : (
    <span />
  );
};

const BUTTONS = {
  reserve: (props) => <ReserveButton {...props} />,
  message: (props) => <MessageButton {...props} />,
};

const ReserveButton = ({
  product,
  isMyProduct,
  setSelectedProduct,
  setErr,
  setModal,
  isReserved,
  isReservedByMe,
  setIsReserved,
  setIsReservedByMe
}) => {
  const user = useSelector(selectUser) || {};
  const [reserveProduct, unReserveProduct] = useCustomerReservations();
  const [submitting, setSubmitting] = useState(false);
  const { dispatchAlert } = React.useContext(AlertContext);

  return isMyProduct ? (
    <span />
  ) : (
    <Button
      type="secondary"
      loading={submitting}
      className="w-full"
      disabled={isMyProduct || (isReserved && !isReservedByMe) || submitting}
      onClick={async () => {
        if (Object.keys(user).length > 0) {
          if (!isReserved && !isReservedByMe) {
            // catch reservation limit error
            try {
              setSubmitting(true)
              const res = await reserveProduct(product);
              setSelectedProduct({
                ...product,
                reservationId: res,
                reservationExpiresAt: moment().add(2, "d").format()
              });
              setSubmitting(false)
              dispatchAlert({
                type: "open",
                position: "left",
                message: `${
                  product.type + (product.brand ? " - " + product.brand : "")
                } reserved. This reservation expires in 48 hours.`,
              });
              setIsReserved(res)
              setIsReservedByMe(true)
              customEvent("reserve_product", product)
            } catch (e) {
              setSubmitting(false)
              setErr(e.message);
            }
          } else if (isReservedByMe) {
            unReserveProduct(product);
            setSelectedProduct({
              ...product,
              reservationId: null,
            });
            dispatchAlert({
              type: "open",
              position: "left",
              message: `${
                product.type + (product.brand ? " - " + product.brand : "")
              } unreserved.`,
            });
            customEvent("unreserve_product", product)
          }
        } else {
          setModal("SIGNUP");
        }
      }}
    >
      {isReservedByMe ? "Unreserve" : "Reserve"} Item
    </Button>
  );
};

const MessageButton = ({ product, isMyProduct, isReservedByMe, setModal }) => {
  const history = useHistory();
  const user = useSelector(selectUser) || {};

  return isMyProduct ? (
    <span />
  ) : (
    <Button
      type="primary"
      className="w-full"
      onClick={() => {
        if (Object.keys(user).length > 0) {
          history.push(
            isReservedByMe
              ? `/messages/${product.reservationId.conversationId}`
              : `/messages/new?productId=${product._id}`
          );
          customEvent("message_seller_button", product)
        }
        else setModal("SIGNUP");
      }}
    >
      Message Seller
    </Button>
  )
};
