import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";


import { productService, reservationService, accountService } from "../../services";

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    homeFeed: [],
    // improve this later, should be something like this for each
    // homeFeed: {
    //   data:[],
    //   total:25,
    //   skip:0
    // }
    homeFeedTotal: 0,
    favorites: [],
    reserved: [],
    listings: [],
  },
  reducers: {
    setHomeFeed: (state, action) => {
      state.homeFeed = [...state.homeFeed, ...action.payload].reduce(
        (res, data, index, arr) => {
          if (res.findIndex((slab) => slab._id === data._id) < 0) {
            res.push(data);
          }
          return res;
        },
        []
      );
    },
    setHomeFeedTotal: (state, action) => {
      state.homeFeedTotal = action.payload;
    },
    setFavorites: (state, action) => {
      state.favorites = action.payload;
    },
    setReserved: (state, action) => {
      state.reserved = action.payload;
    },
    setListings: (state, action) => {
      state.listings = action.payload;
    },
    setProductAsFavorite: (state, action) => {
      const product = action.payload;
      const alreadyFavorited = state.favorites.find(
        (p) => p._id === product._id
      );
      if (alreadyFavorited) return; // already favorited
      state.favorites.push(product);
    },
    removeProductFromFavorites: (state, action) => {
      const product = action.payload;
      const existingIndex = state.favorites.findIndex(
        (p) => p._id === product._id
      );
      if (existingIndex < 0) return; // not favorited
      state.favorites.splice(existingIndex, 1);
    },
    markProductAsReserved: (state, action) => {
      const { product, reservation } = action.payload;
      state.reserved.push(product);
      ["homeFeed", "favorites", "reserved", "listings"].forEach((set) => {
        const itemIndex = state[set].findIndex((p) => p._id === product._id);
        if (itemIndex < 0) return;
        state[set][itemIndex] = {
          ...state[set][itemIndex],
          reservationId: reservation,
          reservationExpiresAt: moment().add(2, "d").format()
        };
      });
    },
    unReserveProduct: (state, action) => {
      const product = action.payload;
      reservationService.remove(product.reservationId._id);
      ["homeFeed", "reserved", "listings"].forEach((arr) => {
        const existingIndex = state[arr].findIndex(
          (p) => p._id === product._id
        );
        if (existingIndex < 0) return;
        if (arr === "reserved") {
          state.reserved.splice(existingIndex, 1);
        } else {
          state[arr][existingIndex] = {
            ...product,
            reservationId: null,
          };
        }
      });
    },
    adminDeleteProducts: (state, action) => {
      let productIds = action.payload;
      productIds = Array.isArray(productIds) ? productIds : [productIds];
      // for some reason .remove querying $in: productIds is broken
      productIds.forEach((id) => productService.remove(id));
      ["homeFeed", "favorites", "reserved", "listings"].forEach((arr) => {
        state[arr] = state[arr].filter((p) => !productIds.includes(p._id));
      });
    },
    adminMarkProductSold: (state, action) => {
      const product = action.payload;
      if (!product)
        return console.error("ERR: markProductSold(), no product passed");
      productService.patch(action.payload._id, {
        isPublished: false,
        isSold: true,
        soldPrice: product.soldPrice,
        soldAt: Date.now()
      });
      ["homeFeed", "favorites", "reserved"].forEach((arr) => {
        state[arr] = state[arr].filter((p) => p._id !== product._id);
      });
      state.listings = state.listings.map((l) =>
        l._id !== product._id
          ? l
          : { ...product, isPublished: false, isSold: true }
      );
    },
    adminMarkProductUnsold: (state, action) => {
      const product = action.payload;
      if (!product)
        return console.error("ERR: markProductUnSold(), no product passed");
      productService.patch(action.payload._id, {
        isPublished: true,
        isSold: false,
      });
      ["homeFeed", "favorites", "reserved"].forEach((arr) => {
        state[arr].push(product);
      });
      state.listings = state.listings.map((l) =>
        l._id !== product._id
          ? l
          : { ...product, isPublished: true, isSold: false }
      );
    },
    adminPublishProduct: (state, action) => {
      const product = action.payload;

      if (!product)
        return console.error("ERR: markProductUnSold(), no product passed");
      productService.patch(action.payload._id, {
        isPublished: true,
        isSold: false,
      });
      ["homeFeed"].forEach((arr) => {
        state[arr].push(product);
      });
      state.listings = state.listings.map((l) =>
        l._id !== product._id ? l : { ...product, isPublished: true }
      );
    },
    adminEditProduct: (state, action) => {
      const product = action.payload;
      productService.patch(product._id, product);
      ["homeFeed", "favorites", "reserved", "listings"].forEach((arr) => {
        state[arr] = state[arr].map((p) =>
          p._id === product._id ? product : p
        );
      });

      state["listings"].sort((a, b) =>
        a.isPublished === b.isPublished
          ? a.slabId > b.slabId
          : a.isPublished - b.isPublished
      );
    },
    setStoreOwner: (state, action) => {
      state.storeOwner = action.payload;
    }
  },
});

export const {
  setHomeFeed,
  setHomeFeedTotal,
  setFavorites,
  setReserved,
  setListings,
  setProductAsFavorite,
  removeProductFromFavorites,
  markProductAsReserved,
  unReserveProduct,
  adminDeleteProducts,
  adminMarkProductSold,
  adminMarkProductUnsold,
  adminPublishProduct,
  adminEditProduct,
  setStoreOwner,
} = productsSlice.actions;

export const fetchHomeFeed =
  (page = 1, storeId = null, items = 20) =>
  async (dispatch) => {

    let accountId;
    if(storeId){
      let account;
      try {
        account = await accountService.find({
          query: {
            storeId
          }
        })
      } catch (err) {
        console.log(err)
      }

      accountId = account?.data?.[0]?._id || null;
      dispatch(setStoreOwner(account?.data?.[0]));
    }

    try {
      let query = {
        isPublished: true,
        $or: [
          { reservationId: { $exists: false } },
          {
            $and: [
              { reservationExpiresAt: { $lt: Date.now() } },
              { isSold: false },
            ],
          },
        ],
        $limit: items,
        $skip: (page - 1) * items,
         isSold: false,
         $sort: {
          createdAt: -1
        }
      }

      if(accountId)
        query.accountId = accountId;

      let p = await productService.find({query});
      dispatch(setHomeFeed(p.data));
      dispatch(setHomeFeedTotal(p.total));
    } catch (err) {
      console.log(err);
    }
  };

export const adminCreateProduct = (product) => async (dispatch) => {
  const res = await productService.create(product);
  dispatch(fetchListings(product.accountId));
  return res;
};

export const fetchListings = (accountId, page) => async (dispatch) => {
  if (!accountId) return;
  try {
    let p = await productService.find({
      query: {
        accountId,
        $sort: {
          isPublished: 1,
          publishedAt: -1
        },
        $limit: 1000
      },
    });
    dispatch(setListings(p.data));
  } catch (err) {
    console.log(err);
  }
};

export const postReservation = (product, uid) => {
  const reservation = {
    accountId: product.accountId,
    productId: product._id,
    reserverId: uid,
    status: "RESERVED",
  };
  return reservationService.create(reservation);
};

// fetches either favorited or reserved products
export const fetchSavedProducts =
  (savedProductIds = [], isReserved = false) =>
  async (dispatch, getState) => {
    // step 1: see if we already have all products locally
    const currProducts = getState().products.homeFeed || [];
    let localProducts =
      currProducts &&
      currProducts.filter((p) => savedProductIds.includes(p._id));
    if (localProducts.length === savedProductIds.length) {
      return dispatch((isReserved ? setReserved : setFavorites)(localProducts));
    }

    // step 2: fetch any remaining missing products from api
    let missingProductIds = savedProductIds.filter(
      (fid) => !localProducts.find((p) => p._id === fid)
    );

    // missingProductIds is stripped out of query if array length is 0; setting null item solves it;
    missingProductIds =
      missingProductIds.length === 0 ? [null] : missingProductIds;

    console.log(
      `missing ${
        isReserved ? "reserved" : "favorited"
      } products, fetching from api`
    );
    try {
      const fetchedProducts = await productService.find({
        query: {
          _id: {
            $in: missingProductIds,
          },
          isPublished: true,
        },
      });
      let savedProducts = [...localProducts, ...fetchedProducts.data];
      // Need this bc user.reserved might be out of sync with product.reservation
      // TODO: fix the bug in hook, caused when the product owner cancels the reservation
      savedProducts = isReserved
        ? savedProducts.filter((p) => p.reservationId)
        : savedProducts;

      dispatch((isReserved ? setReserved : setFavorites)(savedProducts));
    } catch (err) {
      console.log(err);
    }
  };

export const selectStoreOwner = (state) => ({...state.products.storeOwner})
export const selectHomeFeed = (state) => ({
  data: state.products.homeFeed,
  total: state.products.homeFeedTotal,
});
export const selectFavorites = (state) => state.products.favorites;
export const selectReserved = (state) => state.products.reserved;
export const selectListings = (state) => state.products.listings;
export const selectMaxSlabId = (state) => Math.max.apply(Math, state.products.listings.map(function(listing) { return listing.slabId } ));
export const selectAllSlabIds = (state) => state.products.listings.map(function(listing) { return listing.slabId } );

export default productsSlice.reducer;
