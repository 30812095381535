import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAlwEt5MvKKL_WhKxNONOv5nLa4eFcvg_A");

const geocodeAddress = async (address) => {
  const response = await Geocode.fromAddress(address);
  let coords = {};
  if (response.status === "OK") {
    coords.latitude = response.results[0].geometry.location.lat;
    coords.longitude = response.results[0].geometry.location.lng;
    return coords;
  } else {
    alert(response.status);
    return null;
  }
}

const coordsDistance = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2-lat1);  // deg2rad below
  let dLon = deg2rad(lon2-lon1);
  let a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  let d = R * c; // Distance in km
  return Math.round(d);
}

const deg2rad = (deg) => {
  return deg * (Math.PI/180)
}


export {geocodeAddress, coordsDistance};
