import React, { createContext, useState, useEffect } from "react";
import {capitalize} from "../utils/StringUtils";
import {usePosition} from "../hooks/usePosition";
import {selectAccount, selectUser} from "../redux/reducers/userReducer";
import {useSelector} from "react-redux";

export const SearchContext = createContext({});

// This context provider is passed to any component requiring the context
export const SearchProvider = ({ children }) => {
  const position = usePosition();
  const dimensionUnits = (useSelector(selectUser) || {}).preferredUnits;
  const account = useSelector(selectAccount) || {};
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    ...INITIAL_VALUES,
    dimensionUnits,
  });
  const [searchUri, setSearchUri] = useState("");

  const resetFilters = () => {
    setFilters(INITIAL_VALUES)
  }

  useEffect(() =>{
    setSearchUri(buildSearchUri(query, filters, account, position))
  }, [filters, query])

  return (
    <SearchContext.Provider
      value={{searchUri, query, setQuery, filters, setFilters, resetFilters}}
    >
      {children}
    </SearchContext.Provider>
  );
};

const buildSearchUri = (query, filters, account, position) => {
  const boolObjToArr = (boolObj) =>
    (boolObj ? Object.keys(boolObj).filter((c) => boolObj[c]) : []).map((i) =>
      capitalize(i)
    );
  const tones = boolObjToArr(filters.tones);
  const slabTypes = boolObjToArr(filters.slabTypes);
  const types = boolObjToArr(filters.types);
  const finishes = boolObjToArr(filters.finishes);
  const thickness = boolObjToArr(filters.thickness);
  const { manufacturer, brand } = filters;

  //Get coords
  let coords = null;
  if(!position.error)
    coords = {latitude: position.latitude, longitude: position.longitude}
  else if(account.latitude && account.longitude)
    coords = {latitude: account.latitude, longitude: account.longitude}

  return `?query=${query}${
    types.length ? `&types=${types.toString()}` : ""
  }${manufacturer ? `&manufacturer=${manufacturer}` : ""}${
    brand ? `&brand=${brand}` : ""
  }${finishes.length ? `&finishes=${finishes.toString()}` : ""}${
    slabTypes.length ? `&slabTypes=${slabTypes.toString()}` : ""
  }${
    tones.length ? `&tones=${tones.toString()}` : ""
  }${
    thickness.length
      ? `&thickness=${thickness.map((t) => t.replace("cm", "")).toString()}`
      : ""
  }${
    filters.sortDistance && coords
      ? `&maxDistance=${filters.maxDistance}&latitude=${coords.latitude}&longitude=${coords.longitude}`
      : ""
  }${
    filters.dimensionUnits === "inches" ? "&dimensions=inches" : ""
  }${["minWidth", "minLength", "minPrice", "maxPrice"]
    .map((prop) =>
      filters[prop] ? `&${prop}=${filters[prop]}` : ""
    )
    .filter((v) => !!v)
    .join("")}`;
};

const INITIAL_VALUES = {
  slabTypes: {
    full: false,
    remnant: false
  },
  colors: {
    white: false,
    gray: false,
    blue: false,
    brown: false,
    black: false,
  },
  tones: {
    white: false,
    neutral: false,
    dark: false,
    black: false,
  },
  types: {
    granite: false,
    marble: false,
    onyx: false,
    porcelain: false,
    quartz: false,
    quartzite: false,
    soapstone: false,
    travertine: false,
  },
  manufacturer: null,
  brand: null,
  finishes: {
    natural: false,
    polished: false,
    honed: false,
    rough: false,
    leather: false,
    brushed: false,
    matte: false,
    "matte-textured": false
  },
  dimensionUnits: "inches",
  minWidth: "",
  minLength: "",
  thickness: { "2cm": false, "3cm": false },
  maxPrice: "",
  minPrice: "",
  sortDistance: false,
  maxDistance: 200
};
