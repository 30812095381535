import { useDispatch, useSelector } from "react-redux";
import {
  postReservation,
  markProductAsReserved,
  unReserveProduct,
} from "../redux/reducers/productsReducer";
import { selectUser, updateUserState } from "../redux/reducers/userReducer";

const useCustomerReservations = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const reserve = async (product) => {
    const res = await postReservation(product, user._id);

    // used updateUserState instead of updateUser which hits DB
    dispatch(
      updateUserState({
        reservations: [...user.reservations, res],
      })
    );
    dispatch(markProductAsReserved({ product, reservation: res }));
    return res;
  };

  const unreserve = (product) => {
    dispatch(unReserveProduct(product));
    const trimmedRes = [...user.reservations];
    trimmedRes.splice(user.reservations.map((res) => {return res.productId}).indexOf(product._id), 1);

    // used updateUserState instead of updateUser which hits DB
    dispatch(updateUserState({ reservations: trimmedRes }));
  };

  return [reserve, unreserve];
};

export default useCustomerReservations;
