import React, { useEffect, useState } from "react";
import { capitalize } from "../../utils/StringUtils";
import Icon from "../Icon/Icon";

const SearchInput = ({
  value,
  label,
  options = [],
  onSearch,
  onChange,
  className,
  name,
  error
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(value);
  }, [value]);

  return (
    <div className={className}>
      <label
        id="listbox-label"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <div className="my-2">
          <label className="block text-sm font-medium text-gray-700">
            Model
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div
              className={`absolute top-4 left-0 pl-3 flex items-center pointer-events-none`}
            >
              <span className="text-gray-500 sm:text-sm">
                <Icon name="search" size="sm" />
              </span>
            </div>
            <input
              type="text"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                onSearch(e.target.value);
              }}
              onClick={() => setIsOpen(!isOpen)}
              onBlur={() => !isHover && setIsOpen(false)}
              className={`focus:ring-orange-300 focus:border-orange-300 block w-full 
          sm:text-sm border-gray-300 rounded-md pl-8
            ${error ? `border-red-500` : ""}`}
              autocomplete="off"
            />
            {error && (
              <p className="h-2 text-orange text-sm mt-1 ml-1">{error}</p>
            )}
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"></div>
          </div>
        </div>
        <ul
          className={`absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
            isOpen ? "z-10" : "invisible"
          }`}
          tabIndex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {options.length > 0 ?
            options.map((item, i) => {
              const { value: v } = item;
              return (
                <ListItem value={value} v={v} i={i} handleClick={() => {
                  onChange(item);
                  setIsOpen(false);
                }}/>

              );
            }) : (
              <ListItem custom value={value} v={query} i={1} handleClick={() => {
                onChange({name: query, value: query});
                setIsOpen(false);
              }}/>
            )}
        </ul>
      </div>
    </div>
  );
};

export default SearchInput;

const ListItem = ({value, v, i, custom, handleClick}) => (
  <li
    onClick={handleClick}
    className={`text-gray-900 cursor-default SearchInput-none relative 
                      py-2 pl-3 pr-9 cursor-pointer hover:bg-gray-200 ${
      value === v ? "bg-blue-100" : ""
    }`}
    id="listbox-option-0"
    key={i}
  >
    <div className="flex items-center">
      <span
        className={`font-normal block truncate ${custom && "italic pr-2"}`}
      >
        {capitalize(v)}
      </span>
    </div>
    {value === v && (
      <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    )}
  </li>
)

