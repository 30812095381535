import React from "react";

const Filler = ({ type, text }) => {
  return (
    <div className="h-full flex flex-col justify-center items-center">
      { type === "product" && (
        <>
          <div className="border border-light-blue-300 shadow rounded-md p-4 w-80 h-56 mx-auto">
            <div className="rounded bg-gray-200 w-full h-2/5" />
            <div className="flex-1 space-y-4 h-3/5 pt-2">
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-2/4" />
                <div className="h-4 bg-gray-200 rounded" />
                <div className="h-4 bg-gray-200 rounded w-5/6" />
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="h-4 bg-gray-200 rounded w-2/5" />
                <div className="h-4 bg-gray-200 rounded w-2/5" />
              </div>
            </div>
          </div>
          <p className="z-10 text-center text-xl pt-4 font-semibold pb-4 w-60">Select a product on the left to see more details.</p>
        </>
      ) }
      { type === "message" && (
        <>
          <div className="border border-light-blue-300 shadow rounded-md p-4 w-96 h-20 mx-auto flex flex-row items-center">
            <div className="rounded-full bg-gray-200 h-12 w-12" />
            <div className="space-y-2 flex flex-col ml-3 w-4/5 justify-center">
              <div className="h-4 bg-gray-200 rounded w-2/4" />
              <div className="h-4 bg-gray-200 rounded w-full" />
            </div>
          </div>
          <p className="z-10 text-center text-xl pt-4 font-semibold pb-4 w-80">Select a chat on the left to see your latest messages.</p>
        </>
      ) }
    </div>
  );
};

export default Filler;
