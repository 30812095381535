import React from 'react'
import { FaFacebookSquare, FaLinkedin, FaGoogle, FaApple } from "react-icons/fa";
import {customEvent} from "../../utils/AnalyticsUtils";

const SocialButton = ({type, style}) => {
  const socialTypes = {
    "Google": {
      color: "#de5246",
      icon: () => <FaGoogle style={{color: "#de5246", fontSize: 40}} />,
      url: "/oauth/google"
    },
    "LinkedIn": {
      color: "#0072B1",
      icon: () => <FaLinkedin style={{color: "#0072B1", fontSize: 40}} />,
      url: "/oauth/linkedin2"
    },
    "Facebook": {
      color: "#4267B2",
      icon: () => <FaFacebookSquare style={{color: "#4267B2", fontSize: 40}} />,
      url: "/oauth/facebook"
    },
    "Apple": {
      color: "#000",
      icon: () => <FaApple style={{color: "#000", fontSize: 40}} />,
      url: "/oauth/apple"
    }
  }

  return (
    <a style={style} href={socialTypes[type].url} onClick={() => {customEvent("social_signup", {type})}}>
      <div className="max-w-7xl w-72 h-16 px-3 my-3 rounded shadow flex flex-row items-center justify-center hover:bg-gray-50">
        {socialTypes[type].icon()}
        <p className="pl-4 text-lg">Continue with <span className="font-bold" style={{color: socialTypes[type].color}}>{type}</span></p>
      </div>
    </a>
  )
}

export default SocialButton
