import React, { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {SearchContext} from "../../context/search";
import { matchPath } from 'react-router';

import logo from "../../assets/images/logo.png";
import logoSmall from "../../assets/images/logo-small.png"
import Icon from "../Icon/Icon";
import "./Navbar.css";
import {customEvent} from "../../utils/AnalyticsUtils";

const Navbar = () => {

  //Dynamically check if storeId is present to use for navigation and search
  const { pathname } = useLocation();
  let rootPath =  matchPath(pathname, { path:"/:location" })
  let match = matchPath(pathname, {path: "/"+rootPath.params.location+"/:storeId"});

  return (
    <nav className="Navbar z-10 w-full">
      <div className="mx-auto border-b-2">
        <div className="flex items-center justify-between h-16 w-full">
          <div className="flex items-center w-full xl:w-3/4">
            <div className="sm:flex hidden w-64 pl-6">
              <Logo storeId={match?.params?.storeId} />
            </div>
            <div className="sm:hidden flex pr-4 pl-6">
              <Logo small storeId={match?.params?.storeId} />
            </div>
            <Searchbar storeId={match?.params?.storeId} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

const Logo = (props) => (
  <Link to={props.storeId ? "/store/"+props.storeId : "/dashboard"}>
    {props.small ? (
      <img style={{width: 40, height: 40}} src={logoSmall} alt="SlabXchange" />
    ) : (
      <img style={{width: 112, height: 56}} src={logo} alt="SlabXchange" />
    )}
  </Link>
);

const Searchbar = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { query, setQuery, searchUri } = useContext(SearchContext);

  return (
    <div className="relative rounded-md shadow-sm flex flex-grow mr-6">
      {query ? (
        <div className="absolute z-50 inset-y-0 left-0 pl-3 flex items-center">
          <Icon onClick={() => {
            setQuery("")
            history.push(props.storeId ? "/store/"+props.storeId : "/dashboard")
          }} name="x" color="#777777" />
        </div>
      ) : (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon name="search" color="#777777" />
        </div>
      )}
      <input
        onFocus={(e) => {
          if (pathname !== "/search" && pathname !== "/search-results") {
            localStorage.setItem("searchFilters", null);
          }
          history.push("/search"+(props.storeId ? "/"+props.storeId : ""));
        }}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value)
        }}
        onKeyDown={(e) => {
          if(e.key === "Enter" && query) {
            history.push("/search-results" + (props.storeId ? "/"+props.storeId : "") + searchUri);
            e.currentTarget.blur()
          }
          customEvent("search_products", {searchUri})
        }}
        placeholder="Search Marketplace"
        className="Navbar-search pl-12 inline focus:shadow-lg w-full"
      />
    </div>
  );
};

// const UserDropdown = () => (
//   <div className="UserDropdown">
//     <Icon name="user" color="#777777" />
//   </div>
// );
