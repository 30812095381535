import React from 'react';
import moment from "moment";
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

const contractor = {
  type: 'Contractor',
  name: "Slabxchange Inc.",
  address: "220-3689 E 1st Ave, Vancouver, BC, V5M 1C2",
  email: "info@slabxchange.com"
}

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
});

const ServiceAgreementPDF = ({client}) => {

  const renderParticipant = (participant) => {
    return (
      <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 240, marginHorizontal: 8}}>
        <Text style={{fontFamily: 'OpenSans', fontWeight: 'bold', marginBottom: 2, textTransform: 'uppercase'}}>{participant.type}</Text>
        <Text>{participant.name}</Text>
        <Text style={{textAlign: 'center'}}>{participant.address}</Text>
        <Text style={{marginTop: 2}}>(The "{participant.type}")</Text>
      </View>
    )
  }

  const renderAgreement = () => {
    return (
      <>
        {
          agreementText.map(line => {
            if(line.type === "title")
              return <Text style={{fontFamily: 'OpenSans', fonWeight: 'bold', fontSize: 20, textDecorationLine: 'underline', marginTop: 24}}>{line.text}</Text>
            if(line.type === "text")
              return <Text style={{marginTop: 12}}>{line.prefix && (<Text style={{fontFamily: 'OpenSans', fontWeight: 'bold', marginTop: 8, marginRight: 12}}>{line.prefix}</Text>)}{line.text}</Text>
            if(line.type === "bullet")
              return <Text style={{marginTop: 12, marginLeft: 4}}><Text style={{fontFamily: 'OpenSans', fontWeight: 'bold', marginTop: 8, marginRight: 12}}>•</Text>{line.text}</Text>
            if(line.type === "addresses")
              return (
                <>
                  <Text style={{fontFamily: 'OpenSans', fontWeight: 'bold', marginTop: 2}}>a.</Text>
                  <Text>{client.name}</Text>
                  <Text>{(client.suiteNumber ?
                    client.suiteNumber+", " : "") +
                  client.address+", "+
                  client.city+","+
                  client.province+", "+
                  client.postalCode}</Text>
                  <Text>{client.email}</Text>
                  <Text style={{fontFamily: 'OpenSans', fontWeight: 'bold', marginTop: 2}}>b.</Text>
                  <Text>{contractor.name}</Text>
                  <Text>{contractor.address}</Text>
                  <Text>{contractor.email}</Text>
                </>
              )
          })
        }
      </>
    )
  }

  return (
    <Document>
      <Page style={{width: '100%', padding: 24}} size="A4">
        <Text style={{fontFamily: 'OpenSans', fontWeight: "bold", fontSize: 30, textAlign: 'center', marginVertical: 24}}>GENERAL SERVICE AGREEMENT</Text>
        <Text>This
          <Text style={{fontFamily: 'OpenSans', fontWeight: "bold"}}> GENERAL SERVICE AGREEMENT </Text>
          is dated this
          <Text style={{fontFamily: 'OpenSans', fontWeight: "bold"}}> {moment().format('Do')} </Text>
          day of
          <Text style={{fontFamily: 'OpenSans', fontWeight: "bold"}}> {moment().format("MMMM")} </Text>
          ,
          <Text style={{fontFamily: 'OpenSans', fontWeight: "bold"}}> {moment().format("YYYY")}</Text>.
        </Text>
        <View style={{display: 'flex', flexDirection: 'row', marginVertical: 4, justifyContent: 'center', alignItems: 'center'}}>
          {
            [{
              type: 'Client',
              name: client.name,
              address: (client.suiteNumber ?
                client.suiteNumber+", " : "") +
                client.address+", "+
                client.city+","+
                client.province+", "+
                client.postalCode
            }, contractor].map(participant => renderParticipant(participant))
          }
        </View>
        {renderAgreement()}
      </Page>
    </Document>
  )
}

export default ServiceAgreementPDF;

const agreementText = [
  { type: 'title', text: 'BACKGROUND'},
  { type: 'text', prefix: 'A.', text: "The Client is of the opinion that the Contractor has the necessary qualifications, experience and abilities to provide services to the Client."},
  { type: 'text', prefix: 'B.', text: "The Contractor is agreeable to providing such services to the Client on the terms and conditions set out in this Agreement."},
  { type: 'text', text: "IN CONSIDERATION OF the matters described above and of the mutual benefits and obligations set " +
      "forth in this Agreement, the receipt and sufficiency of which consideration is hereby acknowledged, the " +
      "Client and the Contractor (individually the “Party” and collectively the “Parties” to this Agreement) agree " +
      "as follows:"},
  { type: 'title', text: 'SERVICES PROVIDED'},
  { type: 'text', prefix: '1.', text: 'The Client hereby agrees to engage the Contractor to provide the Client with the following services (the “Services”):'},
  { type: 'bullet', text: 'Access to SXC online marketplace.'},
  { type: 'text', prefix: '2.', text: 'The Services will also include any other tasks which the Parties may agree on. The Contractor hereby ' +
      'agrees to provide such Services to the Client.'},
  { type: 'title', text: 'TERM OF AGREEMENT'},
  { type: 'text', prefix: '3.', text: 'The term of this Agreement (the “Term”) will begin on the date of this Agreement and will remain in full ' +
      'force and effect indefinitely until terminated as provided in this Agreement.'},
  { type: 'text', prefix: '4.', text: 'In the event that either Party wishes to terminate this Agreement, that Party will be required to provide 30 ' +
      'days’ written notice to the other Party.'},
  { type: 'text', prefix: '5.', text: 'In the event that either Party breaches a material provision under this Agreement, the non defaulting ' +
      'Party may terminate this Agreement immediately and require the defaulting Party to indemnify the non-defaulting Party against all reasonable damages.'},
  { type: 'text', prefix: '6.', text: 'This Agreement may be terminated at any time by mutual agreement of the Parties.'},
  { type: 'text', prefix: '7.', text: 'Except as otherwise provided in this Agreement, the obligations of the Contractor will end upon the ' +
      'termination of this Agreement.'},
  { type: 'title', text: 'PERFORMANCE'},
  { type: 'text', prefix: '8.', text: 'Except as otherwise provided in this Agreement, the obligations of the Contractor will end upon the ' +
      'termination of this Agreement.'},
  { type: 'title', text: 'CURRENCY'},
  { type: 'text', prefix: '9.', text: 'Except as otherwise provided in this Agreement, all monetary amounts referred to in this Agreement are in CAD (Canadian Dollars).'},
  { type: 'title', text: 'COMPENSATION'},
  { type: 'text', prefix: '10.', text: 'The Contractor will charge the Client for the Services at the rate of $99.00 per month (the “Compensation”).'},
  { type: 'text', prefix: '11.', text: 'A deposit of $0.00 (the “Deposit”) is payable by the Client upon execution of this Agreement.'},
  { type: 'text', prefix: '12.', text: 'For the remaining amount, the Client will be invoiced every month.'},
  { type: 'text', prefix: '13.', text: 'Invoices submitted by the Contractor to the Client are due within 30 days of receipt.'},
  { type: 'text', prefix: '14.', text: 'The Compensation as stated in this Agreement does not include sales tax, or other applicable duties as ' +
      'may be required by law. Any sales tax and duties required by law will be charged to the Client in addition ' +
      'to the Compensation.'},
  { type: 'text', prefix: '15.', text: 'The Contractor will not be reimbursed for any expenses incurred in connection with providing the Services of this Agreement.'},
  { type: 'text', prefix: '16.', text: 'The client will receive 3 months free service as part of the agreement.'},
  { type: 'title', text: 'INTEREST ON LATE PAYMENTS'},
  { type: 'text', prefix: '17.', text: 'Interest payable on any overdue amounts under this Agreement is charged at a rate of 24.99% per annum ' +
      'or at the maximum rate enforceable under applicable legislation, whichever is lower.'},
  { type: 'title', text: 'CONFIDENTIALITY'},
  { type: 'text', prefix: '18.', text: 'Confidential information (the “Confidential Information”) refers to any data or information relating to the ' +
      'business of the Client which would reasonably be considered to be proprietary to the Client including, but ' +
      'not limited to, accounting records, business processes, and client records and that is not generally known ' +
      'in the industry of the Client and where the release of that Confidential Information could reasonably be ' +
      'expected to cause harm to the Client.'},
  { type: 'text', prefix: '19.', text: 'The Contractor agrees that they will not disclose, divulge, reveal, report or use, for any purpose, any ' +
      'Confidential Information which the Contractor has obtained, except as authorized by the Client or as ' +
      'required by law. The obligations of confidentiality will apply during the Term and will survive indefinitely ' +
      'upon termination of this Agreement.'},
  { type: 'text', prefix: '20.', text: 'All written and oral information and material disclosed or provided by the Client to the Contractor under ' +
      'this Agreement is Confidential Information regardless of whether it was provided before or after the date ' +
      'of this Agreement or how it was provided to the Contractor.'},
  { type: 'title', text: 'OWNERSHIP OF INTELLECTUAL PROPERTY'},
  { type: 'text', prefix: '21.', text: 'All intellectual property and related material (the “Intellectual Property”) that is developed or produced ' +
      'under this Agreement, will be the property of the Contractor. The Client is granted a non-exclusive limited-' +
      'use licence of this Intellectual Property.'},
  { type: 'text', prefix: '22.', text: 'Title, copyright, intellectual property rights and distribution rights of the Intellectual Property remain ' +
      'exclusively with the Contractor.'},
  { type: 'title', text: 'RETURN OF PROPERTY'},
  { type: 'text', prefix: '23.', text: 'Upon the expiry or termination of this Agreement, the Contractor will return to the Client any property, ' +
      'documentation, records, or Confidential Information which is the property of the Client.'},
  { type: 'title', text: 'CAPACITY/INDEPENDENT CONTRACTOR'},
  { type: 'text', prefix: '24.', text: 'In providing the Services under this Agreement it is expressly agreed that the Contractor is acting as an ' +
      'independent contractor and not as an employee. The Contractor and the Client acknowledge that this ' +
      'Agreement does not create a partnership or joint venture between them, and is exclusively a contract for service.'},
  { type: 'title', text: 'RIGHT OF SUBSTITUTION'},
  { type: 'text', prefix: '25.', text: 'Except as otherwise provided in this Agreement, the Contractor may, at the Contractor’s absolute ' +
      'discretion, engage a third party sub-contractor to perform some or all of the obligations of the Contractor ' +
      'under this Agreement and the Client will not hire or engage any third parties to assist with the provision of the Services.'},
  { type: 'text', prefix: '26.', text: 'In the event that the Contractor hires a sub-contractor:'},
  { type: 'bullet', text: 'the Contractor will pay the sub-contractor for its services and the Compensation will remain payable ' +
      'by the Client to the Contractor.'},
  { type: 'bullet', text: 'for the purposes of the indemnification clause of this Agreement, the sub-contractor is an agent of the Contractor.'},
  { type: 'title', text: 'AUTONOMY'},
  { type: 'text', prefix: '27.', text: 'Except as otherwise provided in this Agreement, the Contractor will have full control over working time, ' +
      'methods, and decision making in relation to provision of the Services in accordance with the Agreement. ' +
      'The Contractor will work autonomously and not at the direction of the Client. However, the Contractor will ' +
      'be responsive to the reasonable needs and concerns of the Client.'},
  { type: 'title', text: 'EQUIPMENT'},
  { type: 'text', prefix: '28.', text: 'Except as otherwise provided in this Agreement, the Contractor will provide at the Contractor’s own ' +
      'expense, any and all tools, machinery, equipment, raw materials, supplies, workwear and any other items ' +
      'or parts necessary to deliver the Services in accordance with the Agreement.'},
  { type: 'title', text: 'NO EXCLUSIVITY'},
  { type: 'text', prefix: '29.', text: 'The Parties acknowledge that this Agreement is non-exclusive and that either Party will be free, during ' +
      'and after the Term, to engage or contract with third parties for the provision of services similar to the ' +
      'Services.'},
  { type: 'title', text: 'NOTICE'},
  { type: 'text', prefix: '30.', text: 'All notices, requests, demands or other communications required or permitted by the terms of this ' +
      'Agreement will be given in writing and delivered to the Parties at the following addresses or email:'},
  { type: "addresses"},
  { type: 'text', text: 'or to such other address as either Party may from time to time notify the other.'},
  { type: 'title', text: 'INDEMNIFICATION'},
  { type: 'text', prefix: '31.', text: 'Except to the extent paid in settlement from any applicable insurance policies, and to the extent permitted ' +
      'by applicable law, each Party agrees to indemnify and hold harmless the other Party, and its respective ' +
      'directors, shareholders, affiliates, officers, agents, employees, and permitted ' +
      'successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive ' +
      'damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result ' +
      'from or arise out of any act or omission of the indemnifying party, its respective directors, shareholders, ' +
      'affiliates, officers, agents, employees, and permitted successors and assigns that occurs in connection ' +
      'with this Agreement. This indemnification will survive the termination of this Agreement.'},
  { type: 'title', text: 'MODIFICATION OF AGREEMENT'},
  { type: 'text', prefix: '32.', text: 'Any amendment or modification of this Agreement or additional obligation assumed by either Party in ' +
      'connection with this Agreement will only be binding if evidenced in writing signed by each Party or an ' +
      'authorized representative of each Party.'},
  { type: 'title', text: 'TIME OF THE ESSENCE'},
  { type: 'text', prefix: '33.', text: 'Time is of the essence in this Agreement. No extension or variation of this Agreement will operate as a waiver of this provision.'},
  { type: 'title', text: 'ASSIGNMENT'},
  { type: 'text', prefix: '34.', text: 'The Contractor will not voluntarily, or by operation of law, assign or otherwise transfer its obligations under ' +
      'this Agreement without the prior written consent of the Client.'},
  { type: 'title', text: 'ENTIRE AGREEMENT'},
  { type: 'text', prefix: '35.', text: 'It is agreed that there is no representation, warranty, collateral agreement or condition affecting this ' +
      'Agreement except as expressly provided in this Agreement.'},
  { type: 'title', text: 'ENUREMENT'},
  { type: 'text', prefix: '36.', text: 'This Agreement will enure to the benefit of and be binding on the Parties and their respective heirs, ' +
      'executors, administrators and permitted successors and assigns.'},
  { type: 'title', text: 'TITLES/HEADINGS'},
  { type: 'text', prefix: '37.', text: 'Headings are inserted for the convenience of the Parties only and are not to be considered when ' +
      'interpreting this Agreement.'},
  { type: 'title', text: 'GENDER'},
  { type: 'text', prefix: '38.', text: 'Words in the singular mean and include the plural and vice versa. Words in the masculine mean and ' +
      'include the feminine and vice versa.'},
  { type: 'title', text: 'GOVERNING LAW'},
  { type: 'text', prefix: '39.', text: 'This Agreement will be governed by and construed in accordance with the laws of the Province of British Columbia.'},
  { type: 'title', text: 'SEVERABILITY'},
  { type: 'text', prefix: '40.', text: 'In the event that any of the provisions of this Agreement are held to be invalid or unenforceable in whole ' +
      'or in part, all other provisions will nevertheless continue to be valid and enforceable with the invalid or ' +
      'unenforceable parts severed from the remainder of this Agreement.'},
  { type: 'title', text: 'WAIVER'},
  { type: 'text', prefix: '41.', text: 'The waiver by either Party of a breach, default, delay or omission of any of the provisions of this ' +
      'Agreement by the other Party will not be construed as a waiver of any subsequent breach of the same or other provisions.'},
]
